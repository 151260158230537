export default {


    getAgentAccounts(filters, options) {
        this.cancelQRequest("QGetAgentAccounts");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/accounts', params, "QGetAgentAccounts").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getAgentAccountBalance(agentcode) {
        this.cancelQRequest("QGetAgentAccountBalance");
        return this.getRequest('/api/private/agents/' + agentcode + "/account_balance", null, "QGetAgentAccountBalance").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    topoffAgentAccountBalance(agentcode, amount) {
        return this.postRequest('/api/private/agents/' + agentcode + "/topoff", { amount: amount }).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getAgentAccountLedger(agentcode, filters, options) {
        this.cancelQRequest("QGetAgentAccountLedger");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/agents/' + agentcode + '/account_ledger', params, "QGetAgentAccountLedger").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getLedgerTransactions(filters, options) {
        this.cancelQRequest("QGetLedgerTransactions");
        var params = {
            ...options,
            ...filters,
            attr : 'AgentName,AgentCode'
        }
        return this.getRequest('/api/private/account_transactions', params, "QGetLedgerTransactions").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    addAgentAccountLedger(agentcode, entry) {
        return this.postRequest('/api/private/agents/' + agentcode + '/account_ledger', entry).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    refundAgentAccountLedger(agentcode, refund) {
        return this.postRequest('/api/private/agents/' + agentcode + '/account_ledger_refund', refund).then(function (json) {
            return json
        },
            function (error) {
                return error
            })
    },

    addAccountPaymentMethod(agentcode, payment_info) {
        return this.postRequest('/api/private/agents/' + agentcode + '/agent_account', payment_info).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getAgentPaymentProfiles(agentcode) {
        return this.getRequest('/api/private/agents/' + agentcode + '/payment_methods').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    setDefaultPaymentMethod(agentcode, id) {
        return this.getRequest('/api/private/agents/' + agentcode + '/payment_methods/' + id + '/current').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    deletePaymentMethod(agentcode, id) {
        return this.deleteRequest('/api/private/agents/' + agentcode + '/payment_methods/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

}