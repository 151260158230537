<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-product-library-agent-submenu></q-product-library-agent-submenu>
        <v-container fluid class="px-5 grey lighten-5 site-width">
            <slot></slot>
        </v-container>
    </v-container>
</template>
<script>
import QProductLibraryAgentSubmenu from '@/components/navigation/Submenus/QProductLibraryAgentSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QProductLibraryAgentSubmenu
    },
}
</script>