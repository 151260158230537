<!--Three column block-->
<template>
    <v-row class="mx-1 my-7" cols="12" width="100%">
        <slot name="meta">
            <div></div>
        </slot>
        <v-row cols="12" style="min-width:100% !important;">
            <!--fix this style= later -->
            <v-col cols="12" width="100%">
                <h3 v-if="contentData.Title" class="q-title text-center">{{contentData.Title}}</h3>
            </v-col>
        </v-row>
        <v-row wrap justify="center" align="stretch" style="min-width:100% !important;">
            <slot name="Iframes">
                <v-col v-for="(embed, key) in contentData.Embeds" :key="key" cols="12" :sm="getSmallColumns" :md="getMediumColumns">
                    <div v-html="embed.pretext"></div>
                    <div v-html="embed.code"></div>
                    <div v-html="embed.text"></div>
                </v-col>
            </slot>
        </v-row>
    </v-row>
</template>
<script>
export default {
    name: "ScriptEmbedGroup",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    Title: null,
                    Embeds: [
                        {}
                    ],
                    NumColumns: 2
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.contentData.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.contentData.NumColumns);
        }
    },
    methods: {

    },
    components: {

    }
}

</script>
