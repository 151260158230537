<template>
    <summit-calculator :content-data="{CalculatorType: value.CalculatorType}">
        <template v-slot:meta>
            <v-col cols="12" sm="4" class="mt-10 pl-5">
                <v-select v-model="value.CalculatorType" :items="types"  label="Calculator Type"></v-select>
            </v-col>
        </template>
    </summit-calculator>
</template>
<script>
import SummitCalculator from "@/CMS/views/ContentBlocks/SummitCalculator";

export default {
    props: ['value'],

    data() {
        return {
            types: [
                'Income',
                'Recruiting',
                'Accelerate',
                'Leads',
            ]
        }
    } ,

    created: function() {

    },
    components: {
        SummitCalculator,
    },
    methods: {

    },
}

</script>
