<!--Full width text column -->
<template>
    <div>
        <slot name="meta">
            <div></div>
        </slot>
        <v-alert elevation="5" type="info" :icon="false">
            <div class="text-center">
                <v-checkbox v-model="acknowledged" :label="contentData.Text"></v-checkbox>
            </div>
        </v-alert>
    </div>
</template>
<script>
//use this component like so...
//<text pulls html from the editor">

export default {
    name: "AcknowledgeCheckBox",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    Text: "",
                    Ref: "",
                }
            }
        }
    },
    data: () => ({
        acknowledged: false
    }),
    computed: {

    },
    methods: {

    }
}

</script>
