import { Model } from '@vuex-orm/core'

export default class SummitNav extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'summit_nav'

    static primaryKey = 'ID'

    static state() {
        return {
            loading: false,
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            ID: this.uid(),
            Completed: this.attr(false),
            CurrentStepInd: this.attr(false),
            OptionalInd: this.attr(false),
            Slug: this.attr(''),
            Url: this.attr(''),
            StepCategory: this.attr(''),
            StepCategoryOrder: this.attr(0),
            StepIcon: this.attr(''),
            StepOrder: this.attr(''),
            StepTitle: this.attr(''),
            StepType: this.attr(''),
            StepsInCategory: this.attr(0),
        }
    }
}
