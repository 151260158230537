<template>
    <v-container fluid class="my-0 pa-0">
        <q-contests-submenu></q-contests-submenu>
        <v-container fluid class="px-5 site-width">
            <slot></slot>
        </v-container>
    </v-container>
</template>

<script>
import QContestsSubmenu from '@/components/navigation/Submenus/QContestsSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QContestsSubmenu
    },
}
</script>
