import { Model } from '@vuex-orm/core'
import LeadQuantity from './LeadQuantity'
import lead_level_qty_multiplier from '@/store/LeadOrderQtyMultiplier'

export default class LeadAllocation extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'lead_allocation'
    static primaryKey = 'ID'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/lead_allocation',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            currentLeadOrder: {}
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            ID: this.uid(0),
            CreateDate: this.attr(''),
            LastChangeDate: this.attr(''),
            LastChangeBy: this.attr(''),
            AllocationStatus: this.attr(''),
            AllocationDate: this.attr(''),
            AllocationType: this.attr(''),
            LeadOrderLineID: this.number(0),
            ApprovedDate: this.attr(''),
            ApprovedBy: this.attr(''),
            AgentID: this.number(0),
            lead_quantities: this.hasMany(LeadQuantity, 'LeadAllocationID', 'ID'),

        }
    }

}
