<template>
    <div class="q-glossary mx-5 my-0" cols="12" width="100%">
        <GlossaryLetters :value="sortedGlossary" />

        <v-list-item class="q-glossary-list" v-for="({ item, letter }, index) in sortedGlossary" :key="index" two-line>
            <v-list-item-content>
                <div v-if="letter" :id="`q-glossary-letter-${letter}`" class="q-glossary-list__letter-block">
                    <span class="q-glossary-list__letter">
                        {{ letter }}
                    </span>
                </div>

                <v-list-item-title class="q-glossary-list__title" :id="item.slug">
                    <slot name="header" v-bind="{ item, index }">
                        {{ item.word }}
                    </slot>
                </v-list-item-title>

                <v-list-item-subtitle>
                    <slot name="text" v-bind="{ item, index }">
                        <div v-html="item.text" />
                    </slot>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <div v-if="$slots.footer" class="mt-2">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import GlossaryLetters from './GlossaryLetters.vue'
const GlossaryKey = 'Glossary'

const getFirstLetter = (word) => {
    return word.charAt(0).toUpperCase()
}

export default {
    components: { GlossaryLetters },

    props: {
        contentData: { type: Object, required: true },
        sortWords: { type: Boolean, default: true },
		filterWords: { type: Boolean, default: true }
    },

    computed: {
        glossary() {
            return this.contentData[GlossaryKey]
                .map(item => {
                    item.slug = this.slugify(item.word || '')

                    return {
                        item,
                        slug: this.slugify(item.word || '')
                    }
                })
                
        },
        sortedGlossary() {
            const foundedLetters = []

            let arr =  this.glossary

			if (this.filterWords) {
				arr = arr.filter(({ item }) => item.slug)
			}

                
			arr = arr.map(({ item, slug }) => {        
				const letter = getFirstLetter(item.slug)

				if (foundedLetters.includes(letter)) {
					return { item, slug }
				}

				foundedLetters.push(letter)

				return { item, slug, letter }           
			})

			if (this.sortWords) {
				arr.sort((a, b) => a.slug.localeCompare(b.slug))
			}

			return arr
        }
    },
}
</script>

<style lang="scss" scoped>
  .q-glossary-list {
        &__title {
            font-size: 1.2rem;
            font-weight: bold;
            color: #000;
            padding: 2rem 0 0 0;
        }

        &__letter {
            $letters-size: 56px;

            display: inline-block;
            font-family: "freight-display-pro", serif;
            font-size: 32px;
            font-weight: 900;
            color: #fff;
            border-radius: 50%;
            background-color: #005851;
            width: $letters-size;
            height: $letters-size;
            line-height: $letters-size;
            text-align: center;
            user-select: none;

            &-block {
                border-bottom: 1px solid #eee;
                padding: 8px 0;
            }
        }
  }
</style>