export default {
	getAppMenus () {
        return this.getRequest('/api/private/app-menu')
            .then((json) => json)
			.catch((err) => ({
				error: err,
				message: err.status === 404 ? 'Page not found.' : err.message,
            }))
	}
}
