<script>
import { defineComponent } from 'vue';
import { EditorMenuBar } from 'tiptap'
import Icon from './Icon.vue'
import Styles from './Styles'
import MediaLibrary from '../mediaLibrary.vue'
import DocumentLibrary from '../documentLibrary.vue'

export default defineComponent({
	components: { EditorMenuBar, Icon, MediaLibrary, DocumentLibrary },
	props: {
		editor: {
			type: Object,
			default: null
		},
		hideMenuBar: {
			type: Boolean,
			default: false
		},
		alwaysShowMenu: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			linkMenuIsActive: false,
			linkUrl: '',
			linkTarget: '_self',
			linkFormat: 'link',
			styles: Styles.WysiwygStyles,
			selected_styles: [],

			show_media: false,
			show_documents: false,
			media_command: null,
			document_command: null,
		}
	},
	methods: {
		formatLabel (key) {
			var str = key.replace("_", " ");
			str = str.replace("-", " ");
			return this.titleCase(str);
		},
		setLinkUrl(command, url) {
            if (typeof url == 'undefined' || url == null) {
                this.hideLinkMenu()
                return
            }
            if (url.indexOf('quility.com') > 0) {
                command({ href: url, class: this.linkFormat, target: this.linkTarget })
            } else {
                command({ href: url, class: this.linkFormat, target: "_blank" })
            }
            this.hideLinkMenu()
        },
		showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
		hideLinkMenu() {
            this.linkTarget = "_self";
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
		showImageGallery(command) {
            this.show_media = true;
            this.media_command = command;
        },
		showDocumentLibrary(command) {
			this.show_documents = true;
			this.document_command = command;
		},
		onImageGalleryFile(src) {
            if (src !== null) {
                this.media_command({ src });
            }
            this.show_media = false;
        },
		onDocumentFile(destination) {
            this.document_command({ href: destination.url, class: destination.style, target: "_blank" })
            this.show_documents = false;
        },
	}
})
</script>

<template>
	<editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
		<div class="menubar">
			<v-btn x-small  @click="commands.bold">
				<v-icon small :color="isActive.bold() ? 'black' : 'grey'">fas fa-bold</v-icon>
			</v-btn>
			<v-btn x-small  class="menubar__button" @click="commands.italic">
				<v-icon small :color="isActive.italic() ? 'black' : 'grey'">fas fa-italic</v-icon>
			</v-btn>
			<v-btn x-small  @click="commands.strike">
				<v-icon small :color="isActive.strike() ? 'black' : 'grey'">fas fa-strikethrough</v-icon>
			</v-btn>
			<v-btn x-small  @click="commands.underline">
				<v-icon small :color="isActive.underline() ? 'black' : 'grey'">fas fa-underline</v-icon>
			</v-btn>
			<v-btn x-small  @click="showLinkMenu(getMarkAttrs('link'))">
				<v-icon small :color="isActive.link() ? 'black' : 'grey'">fas fa-link</v-icon>
			</v-btn>
			<v-btn x-small  @click="showImageGallery(commands.image)">
				<v-icon small :color="isActive.image() ? 'black' : 'grey'">fas fa-image</v-icon>
			</v-btn>
			<v-btn x-small  @click="showDocumentLibrary(commands.document)">
				<v-icon small :color="isActive.image() ? 'black' : 'grey'">fas fa-file-alt</v-icon>
			</v-btn>
			<v-menu offset-y>
				<template v-slot:activator="{ on }">
					<v-btn x-small v-on="on" >
						<v-icon small :color="isActive.paragraph2() ? 'black' : 'grey'">fas fa-paragraph</v-icon>
					</v-btn>
				</template>
				<v-card>
					<div style="display:inline-block">
						<v-btn x-small  @click="commands.paragraph2({ classes: 'text-left' })">
							<v-icon small
								:color="isActive.paragraph2({ classes: 'text-left' }) ? 'black' : 'grey'">fas
								fa-align-left</v-icon>
						</v-btn><br>
						<v-btn x-small 
							@click="commands.paragraph2({ classes: 'text-center' })">
							<v-icon small
								:color="isActive.paragraph2({ classes: 'text-center' }) ? 'black' : 'grey'">fas
								fa-align-center</v-icon>
						</v-btn><br>
						<v-btn x-small  @click="commands.paragraph2({ classes: 'text-right' })">
							<v-icon small
								:color="isActive.paragraph2({ classes: 'text-right' }) ? 'black' : 'grey'">fas
								fa-align-right</v-icon>
						</v-btn>
					</div>
				</v-card>
			</v-menu>
			<v-menu offset-y>
				<template v-slot:activator="{ on }">
					<v-btn x-small v-on="on" >H</v-btn>
				</template>
				<v-card>
					<v-hover v-for="level in [1, 2, 3, 4, 5, 6]" :key="level" v-slot:default="{ hover }">
						<div>
							<v-btn x-small 
								@click="commands.heading({ level: level, classes: 'text-left' })">
								<span
									:class="isActive.heading({ level: 1 }) ? 'black--text' : 'grey--text'"><strong>H{{ level }}</strong></span>
							</v-btn>
							<div style="display:inline-block" v-if="hover">
								<v-btn x-small 
									@click="commands.heading({ level: level, classes: 'text-left' })">
									<v-icon small
										:color="isActive.heading({ level: level, classes: 'text-left' }) ? 'black' : 'grey'">fas
										fa-align-left</v-icon>
								</v-btn>
								<v-btn x-small 
									@click="commands.heading({ level: level, classes: 'text-center' })">
									<v-icon small
										:color="isActive.heading({ level: level, classes: 'text-center' }) ? 'black' : 'grey'">fas
										fa-align-center</v-icon>
								</v-btn>
								<v-btn x-small 
									@click="commands.heading({ level: level, classes: 'text-right' })">
									<v-icon small
										:color="isActive.heading({ level: level, classes: 'text-right' }) ? 'black' : 'grey'">fas
										fa-align-right</v-icon>
								</v-btn>
							</div>
						</div>
					</v-hover>
				</v-card>
			</v-menu>
			<v-btn x-small  @click="commands.bullet_list">
				<v-icon small :color="isActive.bullet_list() ? 'black' : 'grey'">fas fa-list-ul</v-icon>
			</v-btn>
			<v-btn x-small  @click="commands.ordered_list">
				<v-icon small :color="isActive.ordered_list() ? 'black' : 'grey'">fas fa-list-ol</v-icon>
			</v-btn>
			<v-btn x-small  @click="commands.horizontal_rule">
				_
			</v-btn>
			<v-dialog v-model="linkMenuIsActive" width="500">
				<v-card>
					<v-card-text>
						<form class="menububble__form" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
							<v-text-field v-model="linkUrl" placeholder="https://" ref="linkInput"
								@keydown.esc="hideLinkMenu" />
							<v-checkbox class="mt-0" v-model="linkTarget" label="Open in new tab"
								false-value="_self" true-value="_blank"></v-checkbox>
							<v-radio-group :column="true" v-model="linkFormat">
								<v-radio label="Link" value="link"></v-radio>
								<v-radio label="Button 1" value="button-1" class="mr-4"></v-radio>
								<v-radio label="Button 2" value="button-2" class="mr-4"></v-radio>
								<v-radio label="Button 3" value="button-3" class="mr-4"></v-radio>
								<v-radio label="Inverted" value="button-invert" class="mr-4"></v-radio>
							</v-radio-group>
							<button class="menububble__button" @click="setLinkUrl(commands.link, null)"
								type="button">
								<icon name="remove" />
							</button>
						</form>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" @click="setLinkUrl(commands.link, linkUrl)">
							Save
						</v-btn>
						<v-btn text @click="setLinkUrl(commands.link, null)">
							Remove
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="show_media" width="75%">
				<media-library v-on:usefile="onImageGalleryFile"></media-library>
			</v-dialog>
			<v-dialog v-model="show_documents" width="75%">
				<document-library :is-popup="true" v-on:usefile="onDocumentFile"></document-library>
			</v-dialog>
		</div>
	</editor-menu-bar>
</template>