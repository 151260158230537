import { Node } from 'tiptap'
import { setBlockType, textblockTypeInputRule, toggleBlockType } from 'tiptap-commands'

export default class Paragraph2 extends Node {

    get name() {
        return 'paragraph2'
    }

    get defaultOptions() {
        return {
            classes: 'text-left'
        }
    }

    get schema() {
        return {
            attrs: {
                classes: 'text-left'
            },
            content: 'inline*',
            group: 'block',
            draggable: false,
            parseDOM: [{
                tag: 'p2',
                getAttrs: (node) => ({
                    classes: node.classList
                })
            }, {
                tag: 'p2.text-left',
                getAttrs: (node) => ({
                    classes: node.classList
                })
            }, {
                tag: 'p2.text-center',
                getAttrs: (node) => ({
                    classes: node.classList
                })
            }, {
                tag: 'p2.text-right',
                getAttrs: (node) => ({
                    classes: node.classList
                })
            }],
            toDOM: (node) => ['p2', { class: node.attrs.classes }, 0]
        }
    }

    commands({ type, schema }) {
        return attrs => toggleBlockType(type, schema.nodes.paragraph2, attrs)
    }

}
