<template>
  <v-text-field v-bind="$attrs" v-model="percented" />
</template>

<script>
import { debounce } from 'vue-debounce'

const debounceSet = debounce(($emit, value) => {
    $emit('input', Number(value.replace('%', '')) / 100)
}, 1000)

export default {
    props: {
        value: { type: Number, default: 0 },
    },

    computed: {
        percented: {
            get() { return this.value * 100 + '%' },
            set(value) {
                debounceSet(this.$emit.bind(this), value)
            },
        }
    }
}
</script>
