<!--Call To Action Style 1 -->
<template>
    <div>
        <v-row>
            <slot name="meta">
                <div></div>
            </slot>
            <v-col cols="12" :class="contentData.style + ' pa-10'">
                <slot name="headline">
                    <p v-if="contentData.headline" :class="headline_class">{{contentData.headline}}</p>
                </slot>
                <slot name="text">
                    <p v-if="contentData.text" class="text-center" v-html="contentData.text">{{contentData.text}}</p>
                </slot>
                <div v-if="contentData.buttonText" class="text-center">
                    <v-btn :color="contentData.buttonColor" @click="buttonClick">{{ contentData.buttonText }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//use this component like so...
//<call-to-action headline="Your Headline" text="your copy" button-text="Click Here" button-href="http://www.google.com">

export default {
    name: "CallToAction",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    buttonText: null,
                    buttonHref: null,
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {
        headline_class: function() {
            if (this.contentData.headlineColor) {
                return "q-display-3 text-center " + this.contentData.headlineColor + "--text"
            }
            return "q-display-3 text-center";
        }
    },
    methods: {
        buttonClick: function() {
            //maybe we want to trigger a ga event? Or doe some other work before linking to another page?
            if (this.contentData.buttonHref.indexOf('http') > -1) {
                window.location.href = this.contentData.buttonHref;
            } else {
                this.$router.push(this.contentData.buttonHref)
            }
        }
    }
}

</script>
