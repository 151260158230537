<template>
    <v-card cols="12" width="100%" class="pa-9 mx-auto">
        <v-row>
            <h3 class=" q-title text-center">Summit Accelerate Income Calculator</h3>
        </v-row>
        <QExpandableDataTable class="summit-calculator__table" fixed-header :headers="columns" :items="rows" hide-default-footer :items-per-page="9999">
            <!-- Headers -->
            <template #[`header.submitApv`]="{ header }">
                {{ header.text }}
                <QQuestionTooltip>Submit APV-Annual premium volume submitted to the carrier</QQuestionTooltip>
            </template>
            <template #[`header.netPlacedRatio`]="{ header }">
                {{ header.text }}
                <QQuestionTooltip>Net Placed %-Percentage of applications approved and in force</QQuestionTooltip>
            </template>
            <template #[`header.avgContractLevel`]="{ header }">
                {{ header.text }}
                <QQuestionTooltip>Contract Level</QQuestionTooltip>
            </template>
            <template #[`header.uniqueWriters`]="{ header }">
                {{ header.text }}
                <QQuestionTooltip>Number of individual writing agents submitting business </QQuestionTooltip>
            </template>
            <template #[`header.override`]="{ header }">
                {{ header.text }}
                <QQuestionTooltip>Commission received on spread between agent level commission and your commission. This calculator is utilizing a 10% spread.</QQuestionTooltip>
            </template>
            <!-- Columns -->
            <template #[`item.submitApv`]="{ item }">
                <v-combobox v-model="item.submitApv" :items="selectSubmitApv" />
                <p>
                    Yearly Submit: <strong>{{ formatCurrency(item.yearlySubmit) }}</strong>
                    <QQuestionTooltip>48 week total</QQuestionTooltip>
                </p>
            </template>
            <template #[`item.netPlacedRatio`]="{ item }">
                <QPercentInput v-model="item.netPlacedRatio" />
                <p>
                    NPP: <strong> {{ formatCurrency(item.netPlacedTotal) }}</strong>
                    <QQuestionTooltip>NPP-Net placed annual premium of policies approved and in force</QQuestionTooltip>
                </p>
            </template>
            <template #[`item.avgContractLevel`]="{ item }">
                <v-combobox v-model="item.avgContractLevel" :items="selectContractLevel" />
                <p>
                    Gross Comm: <strong> {{ formatCurrency(item.netPlacedTotal) }}</strong>
                    <QQuestionTooltip>Gross Commission-annual commission from approved policies based on contract level </QQuestionTooltip>
                </p>
            </template>
            <template #[`item.uniqueWriters`]="{ item }">
                <v-text-field type="number" v-model="item.uniqueWriters" />
                <p></p>
            </template>
            <template #[`item.override`]="{ item }">
                <p class="text-right"><strong>{{ formatCurrency(item.override) }}</strong></p>
            </template>
            <template #[`item.remove`]="{ item }">
                <v-icon color="#q-med-grey" @click="removeRow(item)">fa-solid fa-trash</v-icon>
            </template>
        </QExpandableDataTable>
        <div class="d-flex justify-end mb-6">
            <v-btn text small color="primary" @click="addNewRow">
                Add a row
                <v-icon right class="mr-1">fas fa-plus-square</v-icon>
            </v-btn>
        </div>
        <p class="pr-8 mb-0 text-right" style="min-width: 150px;">
            Total Override:
            <strong>{{ formatCurrency(totalOverride) }}</strong>
        </p>
    </v-card>
</template>
<script>
import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'
import QQuestionTooltip from './tooltip/QQuestionTooltip.vue'
import QPercentInput from '../form/QPercentInput.vue'

export default {
    name: "QSummitAccelerateCalculator",

    components: {
        QExpandableDataTable,
        QQuestionTooltip,
        QPercentInput,
    },

    mounted() {
        this.addNewRow();
    },

    curr: 12,

    data() {
        return {
            rows: [],

            selectSubmitApv: Array(14).fill(2000).map((x, i) => x + i * 1000),
            selectContractLevel: Array(6).fill(80).map((x, i) => x + i * 10),
        }
    },

    computed: {
        totalOverride() {
            return this.rows.reduce((prev, cur) => prev + cur.override, 0);
        },

        columns() {
            return [{
                    text: 'Submit APV',
                    value: 'submitApv',
                },
                {
                    text: 'Net Placed %',
                    value: 'netPlacedRatio',
                },
                {
                    text: 'Avg Contract Level',
                    value: 'avgContractLevel',
                },
                {
                    text: 'Unique Writers',
                    value: 'uniqueWriters',
                },
                {
                    text: 'Override',
                    value: 'override',
                },
                {
                    text: '',
                    value: 'remove',
                },
            ].map((c) => ({ ...c, sortable: false }))
        }
    },

    watch: {
        rows: {
            handler(newVal, oldVal) {
                newVal.forEach((row) => {
                    row = this.calculateRow(row);
                })
            },
            deep: true
        }
    },

    methods: {
        calculateRow(row) {
            row.yearlySubmit = row.weeks * row.submitApv;
            row.netPlacedTotal = row.netPlacedRatio * row.yearlySubmit;
            row.grossCommission = row.netPlacedTotal * (row.avgContractLevel / 100);
            row.override = row.netPlacedTotal * 0.1 * row.uniqueWriters;

            return row;
        },

        addNewRow() {
            this.rows.push(this.calculateRow({
                // editable
                submitApv: 2000,
                netPlacedRatio: 0.75,
                avgContractLevel: 100,
                uniqueWriters: 1,

                // display or calc
                weeks: 48,
                yearlySubmit: null,
                netPlacedTotal: null,
                grossCommission: null,
                override: null,
            }));
        },

        removeRow(row) {
            this.rows.splice(this.rows.indexOf(row), 1);
        },
    },
}

</script>
<style lang="scss">
.summit-calculator {
    &__table {
        .v-data-table__wrapper {
            max-height: 500px;
        }

        td {
            vertical-align: top;

            p,
            button {
                white-space: nowrap;

                &:first-child {
                    margin-top: 18px;
                }
            }
        }

        th {
            white-space: nowrap;
        }

        tr:nth-child(even) {
            background-color: #F8F8F8;
        }
    }
}

</style>
