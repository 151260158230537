<template>
    <div class="question_field mx-2">
        <slot name="meta">
            <span></span>
        </slot>
        <strong v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</strong>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>
        <q-agent-lookup v-model="value.AnswerContent" :return-object="false" :label="field_label" :rules="question_rules" :filters="the_filters" :division="question.FormQuestionConfig.division"></q-agent-lookup>
        <slot></slot>
    </div>
</template>
<script>
/*
This simple text field is used asd a mix in for other form fields... add features to this with caution as it may carry down stream.
 */
import QAgentLookup from '@/components/utils/QAgentLookup.vue';
import FmlFieldMixins from './FmlFieldMixins.vue'
export default {
    name: "FmlAgentLookup",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: "AgentCode"
        }
    },
    mounted: function() {
        this.$set(this.value, "AnswerDataType", "AgentCode")
        this.$set(this.question.FormQuestionConfig, "AnswerDataType", "AgentCode")
    },
    props: {

    },
    computed: {
        'the_filters': function() {
            var filters = {};
            if (this.question.FormQuestionConfig.filters.Status) {
                switch (this.question.FormQuestionConfig.filters.Status) {
                    case 'Active & Available':
                        filters.AvailableInd = 1
                        break;
                    case 'Active':
                        filters.Status = "Active"
                        break;
                }
            }
            return filters
        }
    },
    methods: {
        updateFieldDefaults: function() {
            this.question.FormQuestionConfig.AnswerDataType = this.AnswerDataType
            this.$set(this.value, "AnswerDataType", this.AnswerDataType)
            this.$set(this.question.FormQuestionConfig, "useQuestionAsLabel", false)
            this.$set(this.question.FormQuestionConfig, "filters", {})
            this.$set(this.question.FormQuestionConfig.filters, "Status", 'Active')
            this.$set(this.question.FormQuestionConfig, "label", "Agent Lookup")
            this.$set(this.question, "FormQuestionTitle", "Choose an agent.")
            this.$set(this.question.FormQuestionConfig, "division", null)
            this.$set(this.question.FormQuestionConfig, "primaryAgentField", false)
        },

    },

    components: {
        QAgentLookup
    },
    watch: {
        'value.AnswerContent': function(newV) {
            if (this.question.FormQuestionConfig.primaryAgentField) {
                this.$emit('update:agentCode', newV)
            }
        }
    }
}

</script>
