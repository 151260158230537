<template>
    <wistia-video-group>
        <template v-slot:meta class="">
            <v-col cols="3">
                <v-text-field v-model="value.NumColumns" label="Number of Columns"></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-btn large color="primary" @click="addVideo">Add Video</v-btn>
            </v-col>
        </template>
        <template v-slot:Videos>
            <draggable tag="v-row" wrap class="px-4" v-model="value.Videos" handle=".handle">
                <v-col v-for="(video, key) in value.Videos" :key="video.id" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="text-left">
                    <v-hover v-slot:default="{ hover }">
                        <v-card>
                            <v-card-title>
                                <h5>Wistia Video</h5>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field class="mt-5" label="Title" v-model="video.title"></v-text-field>
                                <v-text-field class="mt-5" label="Wistia Media ID" v-model="video.video_id"></v-text-field>
                                <text-editor class="cms" label="Text" v-model="video.text"></text-editor>
                                <v-text-field class="mt-7" label="Button Text" v-model="video.buttonText"></v-text-field>
                                <url-selector :key="'download_selector' + video.id" v-model="video.buttonHref" label="Button Link"></url-selector>
                                <v-checkbox class="mt-0" v-model="video.newWindow" label="open in new window" false-value="_self" true-value="_blank"></v-checkbox>
                            </v-card-text>
                            <v-btn class="handle" v-if="hover" dark color="gray" small fab absolute top left>
                                <v-icon small>fas fa-arrows-alt</v-icon>
                            </v-btn>
                            <v-btn @click="deleteVideo(key)" v-if="hover" fab small absolute top right color="red">
                                <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                        </v-card>
                    </v-hover>
                </v-col>
            </draggable>
        </template>
    </wistia-video-group>
</template>
<script>
import draggable from "vuedraggable";
import TextEditor from '../TextEditor.vue'
import ImageSelector from '../ImageSelector.vue'
import UrlSelector from '../UrlSelector.vue'
import WistiaVideoGroup from '@/CMS/views/ContentBlocks/WistiaVideoGroup.vue'
import Styles from '../Styles'
export default {
    props: ['value'],
    data: function() {
        return {
            available_colors: Styles.Backgrounds,
            counter: Math.floor(Math.random() * 1000000000),
            button_alignments: [{
                    text: "Left",
                    value: "left"
                },
                {
                    text: "Center",
                    value: "center"
                },
                {
                    text: "Right",
                    value: "right"
                }


            ]
        }
    },
    mounted: function() {
        this.reset();
    },
    components: {
        draggable,
        TextEditor,
        WistiaVideoGroup,
        UrlSelector,
        ImageSelector
    },
    methods: {
        reset: function() {
            if (typeof this.value.Videos == 'undefined') {
                this.counter = this.counter + 1
                this.$set(this.value, 'Videos', [{
                    id: this.counter,
                    title: "",
                    video_id: "",
                    text: "",
                    buttonText: "",
                    buttonHref: "",
                    newWindow: true,
                }])
            }
            if (typeof this.value.NumColumns == 'undefined') {
                this.$set(this.value, 'NumColumns', 1)
            }
            this.$emit('input', this.value)
        },
        addVideo: function() {
            this.counter = this.counter + 1
            this.value.Videos.push({
                id: this.counter,
                title: "",
                video_id: "",
                text: "",
                buttonText: "",
                buttonHref: "",
                newWindow: true,
            })
        },
        deleteVideo: function(idx) {
            var g = this;
            this.$nextTick(function() {
                g.value.Videos.splice(idx, 1);
            })
        },
    },
    computed: {
        getSmallColumns: function() {
            return Math.round(23 / this.value.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(11 / this.value.NumColumns);
        }
    },
    watch: {

    }
}

</script>
