<!--Three column block-->
<template>
    <v-row class="mx-1 my-7" cols="12" width="100%">
        <slot name="meta">
            <div></div>
        </slot>
        <v-row cols="12" style="min-width:100% !important;">
            <!--fix this style= later -->
            <v-col cols="12" width="100%">
                <h3 v-if="contentData.Title" class="q-title text-center">{{contentData.Title}}</h3>
            </v-col>
        </v-row>
        <v-row cols="12" width="100%" wrap align="stretch" class="d-flex">
            <slot name="Cards">
                <v-col v-for="(card, key) in contentData.Cards" :key="key" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="">
                    <q-card :card="card" v-if="!isClickable(card)"></q-card>
                    <q-card :card="card" v-else v-on:click="maybeLoadHref(card)" class="clickable "></q-card>
                </v-col>
            </slot>
        </v-row>
    </v-row>
</template>
<script>
import QCard from './Elements/Card.vue'
export default {
    name: "CardGroup",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    Cards: [
                        {}
                    ],
                    title: null,
                    NumColumns: 4
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.contentData.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.contentData.NumColumns);
        }
    },
    methods: {
        maybeLoadHref: function(card) {
            if (card.buttonHref.indexOf('http') > -1) {
                if (card.buttonHref.indexOf('quility.com') > -1) {
                    document.location = card.buttonHref
                } else {
                    window.open(card.buttonHref, '_blank');
                }
            } else {
                this.$router.push(card.buttonHref)
            }
        },
        isClickable: function(card) {
            if (!card.copyToClipboard && !card.buttonText) {
                if (card.buttonHref != '') {
                    return true
                }
            }
            return false;
        }
    },
    components: {
        QCard
    }
}

</script>
