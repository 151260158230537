import SupportTicket from '@/store/Models/SupportTicket'
import { EventBus } from '@/event-bus'
export default {
    newTicket(payload, attachments) {
        SupportTicket.commit((state) => {
            state.loading = true
        })
        var attachment_tokens = []
        for (var i = 0; i < attachments.length; i++) {
            attachment_tokens.push(attachments[i].token)
        }
        payload.uploads = attachment_tokens;
        return new Promise((resolve, reject) => {
            this.postRequest('/api/my/zendesk/tickets', payload).then(
                function(json) {
                    resolve(json)
                    SupportTicket.commit((state) => {
                        state.loading = false
                    })
                },
                function(error) {
                    reject(error)
                    SupportTicket.commit((state) => {
                        state.loading = false
                    })
                })
        })
    },

    getTickets() {
        SupportTicket.commit((state) => {
            state.loading = true
        })
        return new Promise((resolve, reject) => {
            this.getRequest('/api/my/zendesk/tickets').then(
                function(json) {
                    SupportTicket.create({ data: json.data })
                    SupportTicket.commit((state) => {
                        state.loading = false
                        state.total = json.meta ? json.meta.total : 0
                    })
                    resolve(json)
                },
                function(error) {
                    SupportTicket.commit((state) => {
                        state.loading = false
                    })
                    reject(error)
                })
        })
    },

    getTicket(id) {
        SupportTicket.commit((state) => {
            state.loading = true
            state.current_ticket = {}
        })
        return new Promise((resolve, reject) => {
            this.getRequest('/api/my/zendesk/tickets/' + id).then(
                function(json) {
                    if (json.error) {
                        EventBus.$emit('showError', json.error)
                        SupportTicket.commit((state) => {
                            state.loading = false
                        })
                    }
                    SupportTicket.commit((state) => {
                        state.loading = false
                        state.current_ticket = json.data
                    })
                    resolve(json)
                },
                function(error) {
                    SupportTicket.commit((state) => {
                        state.loading = false
                    })
                    reject(error)
                })
        })
    },

    getAgentTicket(id, agentcode, role) {
        var url = null
        if (role == 'SuperAdmin' || role == 'Exec' || role == 'Staff') {
            url = '/api/private/agents/' + agentcode + '/tickets/' + id;
        } else if (role == 'AgencyOwner') {
            url = '/api/my/agents/' + agentcode + '/tickets/' + id;
        }
        SupportTicket.commit((state) => {
            state.loading = true
            state.current_ticket = {}
        })
        return new Promise((resolve, reject) => {
            this.getRequest(url).then(
                function(json) {
                    if (json.error) {
                        EventBus.$emit('showError', json.error)
                        SupportTicket.commit((state) => {
                            state.loading = false
                        })
                    }
                    SupportTicket.commit((state) => {
                        state.loading = false
                        state.current_ticket = json.data
                    })
                    resolve(json)
                },
                function(error) {
                    SupportTicket.commit((state) => {
                        state.loading = false
                    })
                    reject(error)
                })
        })
    },

    updateTicket(id, comment, attachments) {
        SupportTicket.commit((state) => {
            state.loading = true
        })
        var attachment_tokens = []
        for (var i = 0; i < attachments.length; i++) {
            attachment_tokens.push(attachments[i].token)
        }
        return new Promise((resolve, reject) => {
            var payload = {
                comment: comment,
                uploads: attachment_tokens
            }
            this.putRequest('/api/my/zendesk/tickets/' + id, payload).then(
                function(json) {
                    SupportTicket.commit((state) => {
                        state.loading = false
                    })
                    resolve(json)
                },
                function(error) {
                    SupportTicket.commit((state) => {
                        state.loading = false
                    })
                    reject(error)
                })
        })
    },

	closeTicket(id) {
        return this.postRequest('/api/my/tickets/' + id + '/close')
    },
	
    transferTicketAttachement(id, agentcode) {
        return new Promise((resolve, reject) => {
            this.postRequest('/api/private/agents/' + agentcode + '/transfer_upload/' + id, {}).then(
                function(json) {
                    resolve(json)
                },
                function(error) {
                    reject(error)
                })
        })
    },
}
