import Application from '@/store/Models/Application'
export default {

    // From MyLeads
    getApplications(agent_code, filters, options) {
        Application.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("getApplicationsQ");
        return this.getRequest('/api/private/applications/' + agent_code, params, "getApplicationsQ").then(function(json) {
            if (typeof json.meta == 'undefined') {
                return json;
            }
            Application.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
            return json;
        })
    },
    getSubmittedApplications(agent_code, filters, options) {
        Application.commit((state) => {
            state.loading = true
        })
        const params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("getSubmittedApplications");
        return this.getRequest('/api/private/new_business/application_audit/' + agent_code, params, "getSubmittedApplications")
			.then((json) => {
				if (typeof json.meta === 'undefined') {
					Application.commit((state) => {
						state.loading = false
						state.total = json.data.length
					})
					return json;
				}

				Application.commit((state) => {
					state.loading = false
					state.total = json.meta.total
					if (json.filters) {
						state.availableFilters = json.filters
					}
				})
				return json;
			})
    },
}
