import { Model } from '@vuex-orm/core'

export default class SearchResult
extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'search_result'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/pages',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: ['category_id'],
            availableFilters: null,
            currentPage: null,
            notice: {
                text: '',
                visible: false,
                color: 'error'
            }
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            title: this.attr(''),
            id: this.uid(''),
            category_id: this.attr(''),
            slug: this.attr(''),
            endDate: this.attr(''),
            startDate: this.attr(''),
            created_at: this.attr(''),
            tags: this.attr([]),
            blocks: this.attr([]),
            category_name: this.attr(''),
            external_url: this.attr(''),
            description: this.attr(''),
        }
    }

}
