<!--Full width text column -->
<template>
    <div class="carrier-logo">
        <v-img v-if="CarrierLogoURL !== null" :src="CarrierLogoURL" />
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "CarrierLogo",
    //when setting props
    props: {
        carrier_id: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        CarrierLogoURL: null,
        loading: false
    }),
    mounted: function() {
        this.getCarrier()
    },
    computed: {

    },
    methods: {
        getCarrier: function() {
            if (!this.carrier_id > 0)
                return

            let g = this
            g.loading = true
            QuilityAPI.getCarrier(this.carrier_id, true).then(function(json) {
                g.CarrierLogoURL = json.data.CarrierLogoURL
                g.loading = false
            })
        }
    }
}
</script>