<template>
    <banner class="" :content-data="value" :editing="true">
        <template v-slot:meta>
            <v-row class="mt-12">
                <v-col cols="4">
                    <v-combobox label="Background Color" v-model="value.background_color" :items="available_colors"></v-combobox>
                </v-col>
                <v-col cols="4">
                    <v-combobox label="Text Class" v-model="value.text_class" :items="available_classes"></v-combobox>
                </v-col>
                <v-col cols="4">
                    <url-selector v-model="value.link" label="Link"></url-selector>
                </v-col>
                <v-col cols="6">
                    <image-selector v-model="value.background_image" label="Desktop Background Image"></image-selector>
                </v-col>
                <v-col cols="6">
                    <image-selector v-model="value.background_image_mobile" label="Mobile Background Image"></image-selector>
                </v-col>
            </v-row>
        </template>
        <template v-slot:text>
            <text-editor v-model="value.text"></text-editor>
        </template>
    </banner>
</template>
<script>
import TextEditor from '../TextEditor.vue'
import Banner from '@/CMS/views/ContentBlocks/Banner.vue'
import ImageSelector from '../ImageSelector.vue'
import Styles from '../Styles'
import UrlSelector from '../UrlSelector.vue'
export default {
    props: ['value'],
    created: function() {
        this.reset();
    },
    data: function() {
        return {
            available_colors: Styles.Backgrounds,
            available_classes: Styles.Text,
            background_image: null,
            background_image_mobile: null
        }
    },
    mounted: function() {},
    components: {
        TextEditor,
        Banner,
        ImageSelector,
        UrlSelector
    },
    methods: {
        reset: function() {
            if (typeof this.value.text == 'undefined') {
                this.$set(this.value, 'text', "<h1>Sed nec diam non eros lobortis</h1> tempor et eget sapien. Sed rutrum orci eget ligula mattis, vel vestibulum ligula feugiat. Cras et velit ut ipsum fringilla vulputate.")
            }
            if (typeof this.value.background_image == 'undefined') {
                this.$set(this.value, 'background_image', null)
            }
            if (typeof this.value.background_image_mobile == 'undefined') {
                this.$set(this.value, 'background_image_mobile', null)
            }
            if (typeof this.value.background_color == 'undefined') {
                this.$set(this.value, 'background_color', 'q_blue_1')
            }
            if (typeof this.value.text_class == 'undefined') {
                this.$set(this.value, 'text_class', 'light-on-dark')
            }
            if (typeof this.value.link == 'undefined') {
                this.$set(this.value, 'link', null)
            }
            this.$emit('input', this.value)
        },
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
