<!--Three column block-->
<template>
    <v-row class="mx-5 my-7" cols="12" width="100%">
        <v-card cols="12" class="pa-5">
            <v-row wrap>
                <v-col v-for="(card, key) in contentData.Cards" :key="key" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="text-left">
                    <card :card="card"></card>
                </v-col>
            </v-row>
        </v-card>
    </v-row>
</template>
<script>
import Card from './Elements/Card.vue'
export default {
    name: "CardSyles",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    Cards: [
                        {}
                    ]
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.contentData.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.contentData.NumColumns);
        }
    },
    methods: {

    },
    components: {
        Card
    }
}

</script>
