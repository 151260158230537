export default {

    summitGetSteps() {
        return this.getRequest('/api/private/summit/steps');
    },

    getStepWithSlug($slug) {
        return this.getRequest('/api/private/summit/steps/slug/' + $slug);
    },

    summitSaveStep(step) {
        if (step.ID === undefined || step.ID == null) {
            return this.postRequest('/api/private/summit/steps', step);
        } else {
            return this.putRequest('/api/private/summit/steps/' + step.ID, step);
        }
    },

    summitSaveStepOrder(allSteps) {
        for (let i = 0; i < allSteps.length; i++) {
            allSteps[i].StepOrder = i;
        }

        return this.putRequest('/api/private/summit/steps/reorder', { allSteps: allSteps });
    },

    summitDeleteStep(id) {
        return this.deleteRequest('/api/private/summit/steps/' + id);
    },

    summitDeleteAlert(id) {
        return this.deleteRequest('/api/private/summit/alerts/' + id);
    },

    summitSendTestAlert(alertId, recipient) {
        return this.postRequest('/api/private/summit/alerts/' + alertId +'/send_test',
            { 'recipient': recipient}
        );
    },

    summitGondolaRide(agentCode, stepId) {
        return this.postRequest('/api/private/summit/gondola_ride/' + agentCode + '/' + stepId);
    },

    logWistiaEvent(label, video_id) {
        var payload = {
            "label": label,
            "video_id": video_id
        }
        return this.postRequest('/api/private/wistia_log', payload);
    },

    checkStepRequirements(step, bypass_popups) {
        return this.getRequest('/api/private/summit/steps/' + step.ID + "/complete_step?bypass_popups=" + bypass_popups);
    },

    getSummitNav() {
        return this.getRequest('/api/private/summit/summit_nav');
    },

    skipStep(step) {
        return this.getRequest('/api/private/summit/steps/' + step.ID + "/skip_step");
    },

    summitAllStepProgress(agentCode, statType)
    {
        return this.getRequest('/api/private/scorecards/summit-progress/' + agentCode, { statType: statType} );
    },
}
