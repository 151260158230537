import { Model } from '@vuex-orm/core'

export default class User extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'user'

    static state() {
        return {
            loading: true,
            auth0: {},
            //path: '/api/private/agents',
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            AgentCode: this.uid(''),
            name: this.attr(''),
            email: this.attr(''),
            created_at: this.attr(''),
            avatar: this.attr(''),
            first_login: this.attr(false),
            ArrangementFromDate: this.attr(''),
            ArrangementToDate: this.attr(''),
            IsAgencyOwner: this.attr(''),
            IsSFGDirect: this.attr(''),
            LeadershipLevel: this.attr(''),
            OptID: this.attr(''),
            ProducerLevel: this.attr(''),
            Role: this.attr(''),
            State: this.attr(''),
            Status: this.attr(''),
            Agent: this.attr({}),
            BaseShopAgencyOwner: this.attr({}),
            DirectAgencyOwner: this.attr({}),
            permissions: this.attr([]),
            config: this.attr({}),
            nav_menu: this.attr({}),
            admin: this.attr({}),
            currentSetDivision: this.attr(''),
            all_divisions: this.attr([]),
            DivisionID: this.attr(0),
            popups_history: this.attr([]),
            enums: this.attr([])
        }
    }

 
    get AgentName() {
        return `${this.name}`
    }

    get AgentEmail() {
        return `${this.email}`
    }

}
