<template>
    <v-menu ref="menu" v-model="datepicker" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :dark="!light" v-model="dateRangeText" label="Date Range" prepend-icon="fas fa-calendar-alt" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker no-title v-model="date_range" range v-on:update="update" :allowed-dates="allowedDates">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="datepicker = false">Cancel</v-btn>
            <v-btn text color="primary" @click="update()">OK</v-btn>
        </v-date-picker>
    </v-menu>
</template>
<script>
import moment from 'moment'

export default {
    name: "QDateRangePicker",
    props: ['value', 'light'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            datepicker: false,
            date_range: [],
        }
    },
    mounted: function() {
        this.date_range = this.value
    },
    computed: {
        dateRangeText() {
            return this.date_range.join(' ~ ')
        },
    },
    methods: {
        update: function() {
            this.$emit('update', this.date_range);
            this.datepicker = false;
        },
        allowedDates: function(d) {
            return moment(d).format('dddd') == "Friday" || moment(d).format('dddd') == "Saturday";
        },
    },
    watch: {

    },
}

</script>
<style scoped>
</style>
