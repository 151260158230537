import { Model } from '@vuex-orm/core'
import LeadQuantity from './LeadQuantity'
import LeadAllocation from './LeadAllocation'
import LeadOrder from './LeadOrder'
import lead_level_qty_multiplier from '@/store/LeadOrderQtyMultiplier'


export default class LeadOrderLine extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'lead_order_line'
    static primaryKey = 'ID'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/lead_order_line',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            currentLeadOrder: {}
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            ID: this.uid(0),
            CreateDate: this.attr(''),
            LastChangeDate: this.attr(''),
            LastChangeBy: this.attr(''),
            LeadOrderID: this.number(0),
            ApprovedDate: this.attr(''),
            ApprovedBy: this.attr(''),
            Priority: this.attr(''),
            OrderDate: this.attr(''),
            OrderLineStatus: this.attr(''),
            LeadType: this.attr(''),
            ProductType: this.attr(''),
            State: this.attr(''),
            County: this.attr(''),
            ExpirationDate: this.attr(''),
            Canceled: this.boolean(false),
            CanceledDate: this.attr(''),
            Fulfilled: this.attr(false),
            AgencyOwnerApprovalInd: this.attr(false),
            HomeOfficeApprovalInd: this.attr(false),
            lead_quantities: this.hasMany(LeadQuantity, 'LeadOrderLineID', 'ID'),
            lead_allocations: this.hasMany(LeadAllocation, 'LeadOrderLineID', 'ID'),
            lead_order: this.belongsTo(LeadOrder, 'LeadOrderID'),
        }
    }

    get QtyFilledDetails() {
        var sum = {};
        for (var a = 0; a < this.lead_allocations.length; a++) {
            for (var q = 0; q < this.lead_allocations[a].lead_quantities.length; q++) {
                var qty = this.lead_allocations[a].lead_quantities[q];
                if (qty.Status == 'Filled' && this.lead_allocations[a].AllocationStatus != "Error") {
                    if (typeof sum[qty.LeadLevel] == 'undefined') {
                        sum[qty.LeadLevel] = qty.Quantity
                    } else {
                        sum[qty.LeadLevel] = sum[qty.LeadLevel] + qty.Quantity
                    }
                }
            }
        }
        return sum
    }

    get QtyFilled() {
        var sum = 0;
        for (var a = 0; a < this.lead_allocations.length; a++) {
            for (var q = 0; q < this.lead_allocations[a].lead_quantities.length; q++) {
                var qty = this.lead_allocations[a].lead_quantities[q];
                if (qty.Status == 'Filled' && this.lead_allocations[a].AllocationStatus != "Error") {
                    sum = sum + (qty.Quantity * lead_level_qty_multiplier[qty.LeadLevel])
                }
            }
        }
        return sum
    }

    get OrderNumber() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.OrderNumber;
    }
    get LeadOrderType() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.OrderType;
    }
    get LeadOrderStatus() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.OrderStatus;
    }
    get LeadOrderDate() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.OrderDate;
    }
    get QtyRequested() {
        var sum = 0;
        for (var i = 0; i < this.lead_quantities.length; i++) {
            if (this.lead_quantities[i].Status == 'Requested') {
                sum = sum + this.lead_quantities[i].Quantity;
            }
        }
        return sum;
    }
    get OrderLineDate() {
        return this.OrderDate;
    }
    get AgentName() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.AgentName;
    }
    get AgentCode() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.AgentCode;
    }
    get OptID() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.OptID;
    }
    get BaseShopOwnerAgentCode() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.BaseShopOwnerAgentCode;
    }
    get BaseShopOwnerOptID() {
        if (this.lead_order == null) {
            return null
        }
        return this.lead_order.aseShopOwnerOptID;
    }


}