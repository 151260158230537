import { namedExtensions } from '@/components/document_library/fileTypes'

const previewTypeMap = {
	image: [...namedExtensions.Image],
	video: [...namedExtensions.Video],
	audio: [...namedExtensions.Audio],
	office: [...namedExtensions.Document, ...namedExtensions.Excel, ...namedExtensions.Presentation],
	pdf: [...namedExtensions.PDF],
	text: [...namedExtensions.Text],
	code: [...namedExtensions.Code],
	archive: [...namedExtensions.Archive],
}

const iconNameMap = {
	'fas fa-file-pdf': [...namedExtensions.PDF],
	'fas fa-file-word': [...namedExtensions.Document],
	'fas fa-file-excel': [...namedExtensions.Excel],
	'fas fa-file-powerpoint': [...namedExtensions.Presentation],
	'fas fa-file-image': [...namedExtensions.Image],
	'fas fa-file-video': [...namedExtensions.Video],
	'fas fa-file-audio': [...namedExtensions.Audio],
	'fas fa-file-alt': [...namedExtensions.Text],
	'fas fa-file-csv': ['csv'],
	'fas fa-file-archive': [...namedExtensions.Archive],
}

export default {
	data () {
		return {
			previewDoc: {},
		}
	},
	computed: {
		previewDocumentDocType () {
			if (!this.previewDoc || !Object.keys(this.previewDoc).length) { return '' }
			return this.getPreviewType(this.previewDoc.DocType)
		},
		previewDocumentUrl () {
			if (!this.previewDoc || !Object.keys(this.previewDoc).length) { return '' }
			return this.getDocLink(this.previewDoc.ViewLink, this.previewDoc.DocType)
		},
	},

    methods: {
        getPreviewType (docType) {
			const type = docType.toLowerCase?.() || docType
			const previewTypeName = Object.entries(previewTypeMap).find(([, types]) => types.includes(type))
			return previewTypeName?.[0] || 'text'
        },
		getDocLink (link, docType) {
            if (link.startsWith('http://localhost:8000')) {
                return link.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL)
            }
			if (process.env.NODE_ENV === 'development') { // to avoid CORS errors in dev
				const previewType = this.getPreviewType(docType)
				if (previewType !== 'office') {
					return link.replace(new URL(link).origin, '')
				}
			}
			return link
		},
        getDocIcon (doctype) {
			const type = doctype.toLowerCase?.() || doctype
			const iconName = Object.entries(iconNameMap).find(([, types]) => types.includes(type))
			return iconName?.[0] || 'fas fa-file'
        },
        downloadDoc (doc) {
            window.open(this.getDocLink(doc.DownloadLink, doc.DocType), '_blank')
        },
    },
}
