<template>
    <carrier-products :content-data="value">
        <template v-slot:meta>
            <v-row class="mt-12 mx-5">
                <v-col cols="6">
                    <q-autocomplete-carriers 
                        v-model="value.CarrierIDs" 
                        @update="updateCarriers($event)"
                        multiple
                    ></q-autocomplete-carriers>
                </v-col>
                <v-col cols="6">
                    <v-select label="Product Solution" v-model="value.ProductSolution" :items="solutions"></v-select>
                </v-col>
            </v-row>
        </template>
    </carrier-products>
</template>
<script>
import CarrierProducts from '@/CMS/views/ContentBlocks/CarrierProducts.vue'
import QAutocompleteCarriers from '@/components/utils/QAutocompleteCarriers.vue'

export default {
    props: ['value'],
    created: function() {
        this.reset();
    },
    data: function() {
        return {
            solutions: ['-All-', 'Accidental Death', 'Debt Free Life', 'Dental', 'Disability & Critical Illness', 'Group', 'Mortgage Protection', 'Permanent Life', 'Retirement Solutions', 'Senior Solutions', 'Term Life']
        }
    },
    mounted: function() {
        if(this.value.ProductSolution === undefined)
            this.value.ProductSolution = '-All-'
    },
    components: {
        CarrierProducts,
        QAutocompleteCarriers
    },
    methods: {
        reset: function() {},
        updateCarriers: function(event) {
            this.value.CarrierIDs = event
        }
    },
    watch: {}
}

</script>
