export default {


    getDivisions(filters, options) {
        this.cancelQRequest("QDivisionsGet");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/divisions', params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    }
}
