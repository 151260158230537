<template>
    <div class="pt-10">
        <acknowledge-check-box :content-data="value">
            <template v-slot:meta>
                <v-col cols="12" sm="4">
                    <v-text-field v-model="value.Text" label="Acknowledgement Text"></v-text-field>
                    <v-text-field v-model="value.Ref" label="Ref" :messages="['For coding... must be unique.']"></v-text-field>
                </v-col>
            </template>
        </acknowledge-check-box>
    </div>
</template>
<script>
import AcknowledgeCheckBox from '@/CMS/views/ContentBlocks/AcknowledgeCheckBox.vue';

export default {
    props: ['value'],
    created: function() {
        this.reset();
    },
    components: { AcknowledgeCheckBox },
    methods: {
        reset: function() {
            if (typeof this.value.Text == 'undefined') {
                this.$set(this.value, 'Text', "Please enter check the box to acknowledge that you have read, understood and agree to comply with the above.")
            }
            if (typeof this.value.Ref == 'undefined') {
                this.$set(this.value, 'Ref', "r" + Math.floor(Math.random() * 1000))
            }
            this.$emit('input', this.value)
        },
    },
}

</script>
