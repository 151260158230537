<template>
    <div color="grey lighten-4">
        <v-img :max-height="height ? height : null" :max-width="width ? width  : null" :aspect-ratio="aspectRatio" @click="showMediaLibary" v-if="showImage && image" :src="image"></v-img>
        <v-text-field v-model="image" :label="label" append-outer-icon="fas fa-image" @click:append-outer="showMediaLibary">
        </v-text-field>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus';

export default {
    props: ['value', 'label', 'showImage', 'aspectRatio', 'height', 'width'],

    created: function() {

    },
    mounted: function() {

    },
    data: function() {
        return {
            image: this.value
        }
    },
    components: {

    },
    methods: {
        showMediaLibary: function() {
            EventBus.$emit("medialibrary", this.useImage)
        },
        useImage: function(file) {
            this.image = file;
            this.$emit("input", file);
        },
    },
    watch: {
        'image': function(newValue) {
            this.$emit("input", newValue);
        }
    }
}

</script>
