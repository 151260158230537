import Level from '@/store/Models/AgentManagement/Level'
import { getInstance } from "@/auth/index";
export default {

    getAgentManagement_Levels() {
        if (Level.store().state.entities.level.loaded) {
            return new Promise((resolve, reject) => { resolve() });
        }
        Level.commit((state) => {
            state.loading = true
        })
        var params = {}
        return this.getRequest('/api/private/agentlevel/levels', params).then(function(json) {
            Level.create({ data: json.data })
            Level.commit((state) => {
                state.loading = false
                state.loaded = true
            })
            return json
        })
    },
    getAgentManagement_AgentLevels(agent_code) {
        if (agent_code == null) {
            return new Promise((resolve, reject) => { reject() });
        }
        return this.getRequest('/api/private/agents/' + agent_code + '/agentlevels').then(
            function(json) {
                return json.data
            },
            function(error) {
                return error
            })
    },
    getAgentManagement_Level(id) {
        if (id == null) {
            return new Promise((resolve, reject) => { reject() });
        }
        return this.getRequest('/api/private/agentlevel/' + id).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    createAgentManagement_Level(level) {
        delete(level.id)
        return this.postRequest('/api/private/agentlevel', level).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    updateAgentManagement_Level(level) {
        return this.putRequest('/api/private/agentlevel/' + level.id, level).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    impersonateAgent(agentcode) {
        return this.getRequest('/api/private/impersonate/' + agentcode).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    revertImpersonation() {
        return this.getRequest('/api/private/impersonate/revert').then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    demoModeAgent(agentcode) {
        return this.getRequest('/api/private/demo/' + agentcode).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    revertDemoMode() {
        return this.getRequest('/api/private/demo/revert').then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    getBaseAgent(agent_code) {
        return this.getRequest('/api/private/base_agent/' + agent_code).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    updateBaseAgent(agent) {
        return this.putRequest('/api/private/base_agent/' + agent.AgentCode, agent).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    createBaseAgent(agent) {
        delete(agent.id)
        delete(agent.ID)
        return this.postRequest('/api/private/base_agent', agent).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    lookupOnboardingAgent(email) {
        return this.getRequest('/api/private/onboarding/portal/agent', { 'email': email })
            .then(
                function(json) {
                    return json
                },
                function(error) {
                    return error
                })

    },
    transferOnboardingDocument(url, agentcode) {
        return new Promise((resolve, reject) => {
            this.postRequest('/api/private/agents/' + agentcode + '/transfer_upload', { documents: url }).then(
                function(json) {
                    resolve(json)
                },
                function(error) {
                    reject(error)
                })
        })
    },
    getAgentSmsHistory(agent) {
        return this.getRequest('/api/private/agent_sms/'+agent).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    sendSmsToAgent(agent, message) {
        return this.postRequest('/api/private/agent_sms/'+agent, {message}).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    }
}
