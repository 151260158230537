export default {


    getAdInsertionOrders(filters, options) {
        this.cancelQRequest("QAdInsertionOrdersGet");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/ad_insertion_order', params, "QAdInsertionOrdersGet").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    saveAdInsertionOrder(order) {
        if (order.ID == null) {
            var method = this.postRequest('/api/private/ad_insertion_order', order)
        } else {
            method = this.putRequest('/api/private/ad_insertion_order/' + order.ID, order)
        }
        return method.then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    deleteAdInsertionOrder(order) {
        return this.deleteRequest('/api/private/ad_insertion_order/' + order.ID)
            .then(function(json) {
                return json
            }, function(error) {
                return error
            })
    },

    getAdInsertionMarkets(filters, options) {
        this.cancelQRequest("QAdInsertionMarketsGet");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/ad_market', params, "QAdInsertionMarketsGet").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    saveAdInsertionMarket(market) {
        if (market.ID == null) {
            var method = this.postRequest('/api/private/ad_market', market)
        } else {
            method = this.putRequest('/api/private/ad_market/' + market.ID, market)
        }
        return method.then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    deleteAdInsertionMarket(market) {
        return this.deleteRequest('/api/private/ad_market/' + market.ID)
            .then(function(json) {
                return json
            }, function(error) {
                return error
            })
    }
}
