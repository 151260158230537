import SearchResult from '@/store/Models/SearchResult'
const request = require('superagent');

export default {

    getSearchResults(filters, options) {
        this.cancelQRequest("QCombinedSearchResults")
        SearchResult.create({ data: [] })
        SearchResult.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/search', params, 'QCombinedSearchResults').then(function(json) {
            //console.log(json)
            SearchResult.create({ data: json.pages.data })

            SearchResult.commit((state) => {
                state.loading = false
                state.total = json.pages.meta.total
                state.last_page = json.pages.meta.last_page
                if (json.pages.meta.filters) {
                    state.availableFilters = json.pages.meta.filters
                }
            })
            return json
        })
    },

    getSearchSupportResults(filters, options) {
        this.cancelQRequest("QCombinedSearchResults")
        SearchResult.create({ data: [] })
        SearchResult.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/search_support', params, 'QCombinedSearchResults').then(function(json) {
            //console.log(json)
            SearchResult.create({ data: json.pages.data })

            SearchResult.commit((state) => {
                state.loading = false
                state.total = json.pages.meta.total
                state.last_page = json.pages.meta.last_page
                if (json.pages.meta.filters) {
                    state.availableFilters = json.pages.meta.filters
                }
            })
            return json
        })
    },

    getElasticSearchResults(filters, options) {
        this.cancelQRequest("QCombinedElasticSearchResults")
        SearchResult.create({ data: [] })
        SearchResult.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/elastic/search', params, 'QCombinedElasticSearchResults').then(function(json) {
			if (json.error) {
				SearchResult.commit((state) => {
					state.loading = false
				})
		
				return {
					error: true,
					message: json.exceptionMessage,
				}
			}

            SearchResult.create({ data: json.data })

            SearchResult.commit((state) => {
                state.loading = false
                state.total = json.total
                state.last_page = json.last_page
                if (json.filters) {
                    state.availableFilters = json.filters
                }
            })

            return json
        })
    },
}