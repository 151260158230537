export default {
	sendFIFEmail(contacts, assets, expenses, debt, results, additionalassets, agent_code, isMP){
        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("sendFIFEmail")
		return this.postRequest(
			'/api/private/FIF/agentemail/' + agent_code, //the end point for this resource.
			{
				Contact: contacts,
				Assets: assets,
				Expenses: expenses,
				Debt: debt,
				Results: results,
				AdditionalAssets: additionalassets,
                MortgageProtection: isMP
			}
		).then(function(json) {
            return json;
        })
	}
}