<template>
    <v-select :label="label" :items="available_divisions" v-model="Division" :rules="rules" prepend-icon="fas fa-building"></v-select>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
export default {
    props: ['value', 'label', 'rules', 'withAll'],
    data: function() {
        return {
            Division: this.value,
        }
    },
    mounted: function() {
        if (this.value != null) {
            this.findDivision(this.value)
        }
    },
    computed: {
        available_divisions() {
            if (this.withAll) {
                return [
                    'All',
                    ...this.raw_divisions
                ]
            }
            return this.raw_divisions;
        },
    },
    methods: {
        updateValue: function(v, emit) {
            this.Division = v;
            if (emit === true) {
                this.$emit('input', v);
            }
        },
        findDivision: function() {
            //placeholder for future use. 
        },
    },
    watch: {
        'Division': function(v) {
            this.updateValue(v, true);
        },
        'value': function(v) {
            this.updateValue(v, false);
        },
    }
}

</script>
