import { Mark } from "tiptap";
import { toggleMark, updateMark } from "tiptap-commands";
import Styles from './Styles'

export default class CustomStyle extends Mark {
    get name() {
        return "customstyle";
    }

    get schema() {
        return {
            attrs: {
                classes: {
                    default: []
                }
            },
            parseDOM: [{
                tag: "span",
                getAttrs(dom) {
                    return { 'classes': dom.classList };
                }
            }],
            toDOM: function(mark) {
                if (mark.attrs.classes.length == 0) {
                    return ["span", {}, 0];
                }
                var classes = mark.attrs.classes;
                if (typeof classes.contains == 'function' && !classes.contains('custom-style')) {
                    classes.add('custom-style');
                }
                return ["span", { class: `${classes}` }, 0];
            }
        };
    }

    commands(o) {
        return function(attrs) { return updateMark(o.type, attrs) };
    }
}
