<template>
    <QDynamicSubmenu
        :base-url="baseUrl"
        :default-title="title"
        :default-buttons="buttons"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QNewsSubmenu",
    data() {
        return {
            title: "News",
            baseUrl: '/news',
            buttons: [{
                    label: "News",
                    anchor: "#News",
                    icon: "fas fa-arrow-alt-circle-down",
                },
                {
                    label: "Contests",
                    to: "/contests",
                    icon: "fas fa-arrow-alt-circle-right",
                },
            ],
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
