<!--Full width text column -->
<template>
    <v-row class="mx-5 my-7" cols="12" width="100%">
        <v-col cols="12 text-left">
            <slot name="text">
                <div v-html="contentData.text"></div>
            </slot>
        </v-col>
    </v-row>
</template>
<script>
//use this component like so...
//<text pulls html from the editor">

export default {
    name: "ZendeskText",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {

    },
    methods: {
        reset: function() {

        }
    }
}

</script>
