<template>
    <div class="q-content-keywords">
		<p v-if="title" class="mb-2">{{ title }}</p>
		<!-- <p v-if="description" class="mb-2 body-1 font-weight-light">{{ description }}</p> -->

		<v-combobox
			v-model="mValue"
			:items="items"
			:label="label"
			placeholder="Type new keyword"
			:messages="description"
			chips
			small-chips
			deletable-chips
			multiple
			:color="color"
			:disabled="disabled"
		>
			<template #no-data>
              <p style="font-size: 0.875rem; margin: 0 0.5rem;">Sorry, there are no predefined keywords</p>
            </template>
		</v-combobox>
    </div>
</template>

<script>
export default {
    props: {
		value: { type: String },
		items: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: 'Keywords',
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: 'primary',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
    computed: {
		mValue: {
			get () {
				if (!this.value) {
					return []
				}
				return this.value.split(',').map(item => item.trim())
			},
			set (val) {
				if (!val?.length) {
					this.$emit('input', null)
				} else {
					this.$emit('input', val.join(','))
				}
			},
		},
    },
}
</script>
