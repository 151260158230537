<template>
    <v-card tile height="100%" class="pa-5 ma-0" outlined color="transparent">
        <v-card-title v-if="video.title">
            {{ video.title }}
        </v-card-title>
        <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;" :key="video.video_id">
            <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                <iframe :src="defaultWistiaVideo" :title="title" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="50%" height="50%" :key="video_id"></iframe>
            </div>
        </div>
        <v-card-text v-if="video.text">
            <div v-html="video.text"></div>
        </v-card-text>
        <v-card-actions class="pt-8">
            <div :style="getAlignment">
                <v-btn block depressed color="q_green_2" dark v-if="video.buttonText" @click="clicked(video.buttonHref, video.newWindow) ">
                    <strong>{{ video.buttonText }}</strong>
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        video: {
            type: Object,
            default() {
                return {
                    video_id: null,
                    title: null,
                    text: null,
                    buttonText: null,
                    buttonHref: null,
                    newWindow: null,
                }
            }
        }
    },

    computed: {
        video_id() {
            return this.video.video_id;
        },
        defaultWistiaVideo() {
            return "https://fast.wistia.net/embed/iframe/" + this.video_id + "?videoFoam=true";
        },
        title() {
            return this.video.title;
        },
        getAlignment: function() {
            return "position:absolute; left:0; bottom:0; padding:20px; text-align:right; width:100%"
        }
    },

    methods: {
        clicked: function(href, newWindow) {
            if (href.indexOf('http') > -1) {
                if (href.indexOf('quility.com') > -1 && newWindow != "_blank") {
                    document.location = href
                } else {
                    window.open(href, newWindow);
                }
            } else {
                this.$router.push(href)
            }
        },
    },
}
</script>
