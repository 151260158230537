<template>
    <carrier-contact-info :content-data="value">
        <template v-slot:meta>
            <v-row class="mt-12">
                <v-col cols="6">
                    <q-autocomplete-carriers 
                        v-model="value.CarrierID" 
                        @update="updateCarrier($event)"
                    ></q-autocomplete-carriers>
                </v-col>
            </v-row>
        </template>
    </carrier-contact-info>
</template>

<script>
import CarrierContactInfo from '@/CMS/views/ContentBlocks/CarrierContactInfo.vue'
import QAutocompleteCarriers from '@/components/utils/QAutocompleteCarriers.vue'
export default {
    props: ['value'],
    created: function() {
        this.reset();
    },
    data: function() {
        return {
            loading: false,
            carriers: [],
            CarrierID: null,
            carrier: []
        }
    },
    mounted: function() {
        
        },
    components: {
        CarrierContactInfo,
        QAutocompleteCarriers
    },
    computed: {
        carrierName: function(){
            if(this.carrier.length > 0)
                return `<h3>${this.carrier[0].CarrierName}</h3>`
            else
                return 'Loading...'
        }
    },
    methods: {
        reset: function() {},
        updateCarrier: function(event) {
            this.value.CarrierID = event
        }
    },
    watch: {
        
    }
}

</script>
