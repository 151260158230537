<template>
    <div class="question_field mx-2">
        <slot name="meta">
            <span></span>
        </slot>
        <strong v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</strong>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>
        <q-lead-lookup v-model="value.AnswerContent" :return-object="false" :label="field_label" :rules="question_rules" :filters="the_filters" :division="question.FormQuestionConfig.division" :agent-code="agent_code"></q-lead-lookup>
        <v-progress-linear indeterminate v-if="isLoading"></v-progress-linear>
        <slot></slot>
    </div>
</template>
<script>
/*
This simple text field is used asd a mix in for other form fields... add features to this with caution as it may carry down stream.
 */
import QLeadLookup from '@/components/utils/QLeadLookup.vue';
import FmlFieldMixins from './FmlFieldMixins.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { EventBus } from '@/event-bus';
export default {
    name: "FmlLeadLookup",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: "Lead",
            isLoading: false
        }
    },
    mounted: function() {
        this.$set(this.value, "AnswerDataType", "LeadCode")
        this.$set(this.question.FormQuestionConfig, "AnswerDataType", "LeadCode")
        if (typeof this.$route.query.lead_code != 'undefined') {
            this.value.AnswerContent = this.$route.query.lead_code;
        }
    },
    props: {

    },
    computed: {
        'the_filters': function() {
            var filters = {};
            switch (this.question.FormQuestionConfig.filters.Status) {
                //case 'Active & Available':
                //    filters.AvailableInd = 1
                //    break;
                //case 'Active':
                //    filters.Status = "Active"
                //    break;
            }
            return filters
        },
        'agent_code': function() {
            return this.agentCode ? this.agentCode : this.user.Agent.AgentCode
        }
    },
    methods: {
        updateFieldDefaults: function() {
            this.question.FormQuestionConfig.AnswerDataType = this.AnswerDataType
            this.$set(this.value, "AnswerDataType", this.AnswerDataType)
            this.$set(this.question.FormQuestionConfig, "useQuestionAsLabel", false)
            this.$set(this.question.FormQuestionConfig, "primaryLeadField", true)
            this.$set(this.question.FormQuestionConfig, "filters", {})
            this.$set(this.question.FormQuestionConfig.filters, "Status", 'Active')
            this.$set(this.question.FormQuestionConfig, "label", "Lead Lookup")
            this.$set(this.question, "FormQuestionTitle", "Choose an lead.")
        },

    },

    components: {
        QLeadLookup
    },
    watch: {
        'value.AnswerContent': function(newV) { //LeadCode
            var g = this
            if (this.question.FormQuestionConfig.limitLeadResponse) {
                g.isLoading = true
                //we can only have one response per lead. First check if the lead already has a response... if so.. load that response. 
                QuilityAPI.getLeadFormResponse(this.value.FormQuestionID, newV, this.agent_code).then(function(json) {
                    g.isLoading = false
                    //looks like we already haver a response for the lead. 
                    if (typeof json.data.ID != 'undefined' && json.data.ID != "new") {
                        //push the id onto the query string and the form will reload. 
                        g.$router.push({ query: { "form_response_id": json.data.ID } })
                    } else {
                        //there is no response for this lead... push the lead code onto the query string and the form will reload and pull a blank response template. 
                        g.$router.push({ query: { "lead_code": newV, "form_response_id": "" } })
                    }
                }).catch(function(error) {
                    g.isLoading = false
                })
            }
        }
    }
}

</script>
