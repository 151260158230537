export default {
    getAgentCommision(agent_code, filters, options) {
        this.cancelQRequest("getAgentCommision");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest(
            '/api/private/agent/' + agent_code + '/commission',
            params,
            'getAgentCommision'
        )
        .then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

}
