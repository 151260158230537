<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-marketing-submenu></q-marketing-submenu>
        <div>
            <v-container fluid class="px-5 transparent site-width">
                <slot></slot>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QMarketingSubmenu from '@/components/navigation/Submenus/QMarketingSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QMarketingSubmenu,
    },
}
</script>