var numeral = require('numeral')

// update formatting entered into currency fields
export function formatField(fieldName,leadInfo, noLead = null){
	if(noLead){
		return numeral(leadInfo).format('0,0.00')
	}
	return numeral(leadInfo[fieldName]).format('0,0.00')
}

// convert fields back into number values
// numeral will make these fields strings
// which we do not want.
export function ConvertFields(fieldNames,leadInfo){
	for (var i = fieldNames.length - 1; i >= 0; i--) {
		let curField = fieldNames[i]

		// only update if the field
		// actually exist in the
		// lead object
		if(leadInfo[curField]){
			leadInfo[curField] = parseFloat(numeral(leadInfo[curField]).value())
		}
	}
}

// the relevant fields in the Asset object need to be converted
// in a different manner since the data is structred differently
export function ConvertAssetFields(leadAssetInfo,leadDebtInfo){
	for (var i = leadAssetInfo.length - 1; i >= 0; i--) {
		if(leadAssetInfo[i].current_value){
			leadAssetInfo[i].current_value = parseFloat(numeral(leadAssetInfo[i].current_value).value())
		}

		if(leadAssetInfo[i].contribution){
			leadAssetInfo[i].contribution = parseFloat(numeral(leadAssetInfo[i].contribution).value())
		}
	}

	for (var i = leadDebtInfo.length - 1; i >= 0; i--) {
		leadDebtInfo[i].payoff_amt = parseFloat(numeral(leadDebtInfo[i].payoff_amt).value())
	}

}

// make these avaible so
// that we can just
// import the relevant array
// where it is needed
export let RealEstateCurrencyFields = ['FinalExpense','FutureFunds','AdditionalDebt','AdditionalProperty2Payment','AdditionalProperty2LoanBalance','AdditionalProperty2LoanAmount','AdditionalProperty2Value','AdditionalProperty1Payment','AdditionalProperty1LoanBalance','AdditionalProperty1LoanAmount','AdditionalProperty1Value','PrimaryHomePayment','PrimaryHomeLoanBalance','PrimaryHomeLoanAmount','PrimaryHomeValue','AdditionalProperty2Accelerated','AdditionalProperty1Accelerated','PrimaryHomeAccelerated']
export let InsuranceCurrencyFields = ['PrimaryInsuranceValue','PrimaryInsurancePremium','OtherInsuranceValue','OtherInsurancePremium','CashValue','SpousePrimaryInsuranceValue','SpousePrimaryInsurancePremium','SpouseOtherInsuranceValue','SpouseOtherInsurancePremium','SpouseCashValue','EmployerLifeInsurance','SpouseEmployerLifeInsurance']
export let IncomeCurrencyFields = ['AnnualIncome','Pension','InvestmentIncome','SpouseIncome','SpousePension']