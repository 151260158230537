<!--Full width text column -->
<template>
    <div>
        <slot name="meta">
            <div></div>
        </slot>
        <v-row class="mx-5 my-0" cols="12">
            <h4>Available Products</h4>
            <div class="pt-0 mt-0" style="width:100%">
                <v-btn text small color="grey darken-1" @click="toggleShowFilters">
                    <v-icon small left>fas fa-filter</v-icon> Filter Products
                </v-btn>
            </div>
            <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
            <v-container class="px-0 mx-0" fluid v-if="contentData.CarrierIDs">
                <QCarrierProductsTable :products="products" :showFilters="doShowFilters" :loading="isLoading" />
            </v-container>
        </v-row>
    </div>
</template>
<script>
import { debounce } from 'vue-debounce';
import QuilityAPI from '@/store/API/QuilityAPI.js';
import QGenericDataTableMixin from '../../../components/datatables/QGenericDataTableMixin';
import QCarrierProductsTable from '../../../components/datatables/QCarrierProductsTable.vue';

export default {
    name: "CarrierProducts",
    mixins: [QGenericDataTableMixin],

    components: { QCarrierProductsTable },

    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    title: null,
                    CarrierIDs: []
                }
            }
        }
    },
    mounted: function() {
        this.loadData()
    },
    data: () => ({
        isLoading: false,
        products: [],

        doShowFilters: false,
        filters: {
            ProductSolution: "All",
            Product: "",
            eAppInd: "",
            PaperAppInd: "",
            InstantDecisionInd: "",
            FlexiblePremiumPossibleInd: ""
        }
    }),
    methods: {
        loadData: debounce(function(opt) {
            if (this.contentData.CarrierIDs === undefined || this.contentData.CarrierIDs.length === 0)
                return

            this.isLoading = true
            var filters = {
                Carriers: this.contentData.CarrierIDs.join(','),
                itemsPerPage: 'all'
            }

            if (this.contentData.ProductSolution !== '-All-' && this.contentData.ProductSolution !== undefined)
                filters.ProductSolution = this.contentData.ProductSolution

            QuilityAPI.getProducts(filters)
                .then((json) => {
                    this.isLoading = false
                    if (typeof json.response != 'undefined' && json.response.clientError) {
                        this.showError(json.response.body.message);
                        return
                    }
                    this.products = this.filterProductsByAgentDivision(json.data);
                }).catch((err) => {
                    this.isLoading = false
                    if (err.status == 431) {
                        this.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    this.showError(err.message);
                });
        }, 200),

        toggleShowFilters: function() {
            this.doShowFilters = !this.doShowFilters
        }
    },
    watch: {
        'contentData.ProductSolution': function(newV, oldV) {
            this.loadData()
        },
    }
}

</script>
