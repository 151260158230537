export const acceptedFiles = [
	"application/vnd.ms-excel",
	"application/csv",
	"application/vnd.ms-word",
	".csv",
	".txt",
	".jpg",
	".png",
	".gif",
	".docx",
	".doc",
	".xlsx",
	".pdf",
	".msg",
	".zip",
	"application/vnd.ms-powerpoint",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/zip",
	"application/x-compressed",
	"application/x-zip-compressed",
	"multipart/x-zip",
].toString()
