import PopupLog from '@/store/Models/PopupLog'

export default {

    getPopupLog(id, filters, options) {
        PopupLog.commit((state) => {
            state.loading = true
        })
        PopupLog.create({ data: {} })
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/popups/' + id + "/log", params).then(function(json) {
            PopupLog.create({ data: json.data })
            PopupLog.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
        })
    },


}
