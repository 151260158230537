<template>
    <spacer>
        <template v-slot:meta>
            <v-col cols="12" sm="4" class="pt-8 pl-5">
                <p>Add the spacer height in either px or %. Default is 25px</p>
                <v-text-field v-model="value.Height" label="Height"></v-text-field>
            </v-col>
        </template>
    </spacer>
</template>
<script>
import TextEditor from '../TextEditor.vue'
import Spacer from '@/CMS/views/ContentBlocks/Spacer.vue'
export default {
    props: ['value'],
    created: function() {
        this.reset();
    },
    components: {
        TextEditor,
        Spacer
    },
    methods: {
        reset: function() {
            if (typeof this.value.Height == 'undefined') {
                this.$set(this.value, 'Height', "25px")
            }
            this.$emit('input', this.value)
        },
    },
}

</script>
