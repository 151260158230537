<template>
    <v-container fluid class="grey lighten-5 my-0 pa-0">
        <v-container fluid class="px-5 site-width">
            <slot></slot>
        </v-container>
    </v-container>
</template>

<script>
//import QResourcesSubmenu from '@/components/navigation/Submenus/QResourcesSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        //QResourcesSubmenu,
    },
}
</script>
