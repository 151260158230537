import { toggleBlockType } from 'tiptap-commands';
import { Node } from 'tiptap';

export default class Div extends Node {
    get name() {
        return 'div';
    }

    get schema() {
        return {
            attrs: {
                textAlign: {
                    default: 'left',
                },
                class: {
                    default: ''
                }
            },
            content: 'inline*',
            group: 'block',
            draggable: false,
            parseDOM: [{
                tag: 'div',
                getAttrs: (node) => ({
                    textAlign: node.style.textAlign || 'left',
                    class: node.class
                })
            }],
            toDOM: (node) => ['div', { style: `text-align: ${node.attrs.textAlign}`, class: node.attrs.class }, 0]
        };
    }

    commands({ type, schema }) {
        return (attrs) => toggleBlockType(type, schema.nodes.div, attrs);
    }
}
