import Category from '@/store/Models/Category'
import Page from '@/store/Models/Page'
import SupportPage from '@/store/Models/SupportPage'
export default {

    // Get all categories
    getCategories(filters, options) {
        return this.getRequest('/api/private/categories').then(function(json) {
            Category.create({ data: json.data })
        })
    },

    refreshCategories() {
        return this.getRequest('/api/private/categories').then(function(json) {
            CategoryModel.deleteAll()
            CategoryModel.insertOrUpdate({ data: json.data })
        })
    },

    // Get a category
    getCategory(id) {
        return this.getRequest('/api/private/categories/' + id).then(function(json) {
            return json
        },
        function(error) {
            return error
        })
    },

    // Create new category
    createCategory(category) {
        return this.postRequest('/api/private/categories', category).then(function(json) {
            Category.insert({ data: json.data })
            return json.data
        },
        function(error) {
            return error
        })
    },

    // Update given category
    saveCategory(category) {
        return this.putRequest('/api/private/categories/' + category.id, category).then(function(json) {
            Category.insert({ data: json.data })
            return json.data
        })
    },

    // Delete the given category
    deleteCategory(id) {
        return this.deleteRequest('/api/private/categories/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    // Get pages by category
    getCategoryPages(category_id, attributes) {
        Page.commit((state) => {
            state.loading = true
        })
        var params = {
            //...options,
            //...filters
        }
        this.getRequest('/api/private/categories/' + category_id + '/pages', params).then(function(json) {
            Page.insert({ data: json.data })
            Page.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
        })
    },

    // Get support categories 
    getSupportCategoryPages(category_id, attributes) {
		if (!category_id) { return }

        SupportPage.commit((state) => {
            state.loading = true
        })
        var params = {
            //...options,
            //...filters
        }
        this.getRequest('/api/private/categories/' + category_id + '/pages', params).then(function(json) {
            SupportPage.insert({ data: json.data })
            SupportPage.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
        })
    },

    reorderCategories(categories) {
        return this.putRequest('/api/private/categories/reorder', { categories: categories }).then((json) => {
            return json
        })
    }
}
