export default {
    /**
     *
     * hits the endpoint /api/private/policyplaced/agent/{agent}
     * returns the placed policy data for the agent whose
     * agent code is {agent}
     *
     * @param {*} agent_code
     * @param {*} filters
     * @param {*} options
     */
    getAgentPlacedPolicies(agent_code, filters, options) {
        var params = {
            ...options,
            ...filters
        }
        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentPlacedPolicies");
            return this.getRequest(
                '/api/private/policyplaced/agent/' + agent_code, //the end point for this resource.
                params, //filters, pagination and what not handles by the datatable
                "getAgentPlacedPolicies" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    }
}
