export default {


    getProductCodes(filters, options) {
        this.cancelQRequest("QProductCodesGet");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/product_codes', params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getProductCode(id) {
        this.cancelQRequest("QProductCodesGet");
        return this.getRequest('/api/private/products_codes/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    createProductCode(product_code) {
        return this.postRequest('/api/private/product_codes', product_code).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    updateProductCode(product_code) {
        return this.putRequest('/api/private/product_codes/' + product_code.ID, product_code).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    deleteProductCode(id) {
        return this.deleteRequest('/api/private/product_codes/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

}
