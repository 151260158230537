export default {
    updateDivisionCarrier(carrier) {
        return this.putRequest('/api/private/carriers/' + carrier.ID + '/divisions', carrier).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    }
}
