<style scoped>
    .summit-badge {
        width: 1.5rem;
        margin-left: 0.3rem;
        float: right;
    }
</style>

<template>
    <div :height="cardHeight" class="leaderboard">
        <div
			class="pt-4 pb-4 pb-4"
			:class="[backgroundColor || 'deep-purple lighten-5', textClass]"
		>
            <div v-if="subtitle" class="overline">{{ subtitle }}</div>
            <h4 class="mb-0">{{ title }}</h4>
        </div>
        <p class="ref-value" v-if="leaderboardData.length > 0">{{ formatDateWithDay(startDate) }} to {{ formatDateWithDay(endDate) }}</p>
        <v-row class="px-4 pb-2" no-gutters>
            <v-col id="q-leaderboard-step-2" v-if="!$vuetify.breakpoint.xs && showAll && leaderboardData.length > 0" cols="12" sm="8" class="text-left">
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-col>
            <v-spacer v-else></v-spacer>
            <v-col id="q-leaderboard-step-3" cols="12" sm="4">
                <v-text-field v-if="showAll" class="pa-0 ma-0" v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details color="q_leads_4"></v-text-field>
            </v-col>
        </v-row>
        <v-data-table light v-if="leaderboardData.length > 0" dense :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" :headers="the_headers" :items="leaderboardData" item-key="AgentCode" class="leaderboard-datatable" :hide-default-footer="!showAll">
            <template v-slot:item.Rank="{ item }">
                <span class="nowrap"><strong>#{{ item.RankByLeadershipLevel ? item.RankByLeadershipLevel : item.Rank }}</strong></span>
            </template>
            <template v-slot:item.AgentName="{ item }">
                <span :class="item.AgentCode == user.AgentCode ? 'agent-strong' : ''">
                    <span v-if="showAll">
                        <router-link class="q_leads_4--text" :to="'/agents/' + item.AgentCode">{{ getAgentPreferredName(item) }}</router-link>
                    </span>
                    <span v-else>{{ getAgentPreferredName(item) }}</span>
                </span>
                <v-img v-if="item.CurrentSummitBadge" :src="item.CurrentSummitBadge.micro" class="summit-badge"></v-img>
                <v-img v-if="['regular', 'golden'].includes(item.SlingshotBadges)" :src="slingshotBadgeUrl(item.SlingshotBadges)" class="summit-badge"></v-img>
            </template>
            <template v-slot:item.Apps="{ item }">
                <span :class="item.AgentCode == user.AgentCode ? 'agent-strong nowrap' : 'nowrap'">{{ Math.round(item.Apps) }}</span>
            </template>
            <template v-slot:item.APV="{ item }">
                <span class="nowrap"><strong>{{ formatCurrency(item.APV) }}</strong></span>
            </template>
        </v-data-table>
    </div>
</template>
<script>
// TODO: check if we need to add json2csv module to dependencies.
const { Parser } = require('json2csv');

export default {
    name: "QRxLeaderboardDataTable",
    props: {
        'title': {
            type: String,
            default: 'Rx Leaderboard'
        },
        'subtitle': {
            type: String,
            default: null
        },
        'rows': {
            type: Number,
            default: 10
        },
        'moreLink': {
            type: String,
            default: null
        },
        'leaderboardData': {
            type: Array,
            default: function() {
                return []
            }
        },
        'startDate': {
            type: String,
            default: null
        },
        'endDate': {
            type: String,
            default: null
        },
        'showAll': {
            type: Boolean,
            default: false
        }, 
        'totalTitle': {
            type: String,
            default: 'Total'
        },
		backgroundColor: String,
		textClass: String,
    },
    data: function() { // data internal to component (not available outside)
        return {
            selected: [],
            search: null,
            filters: {
                County: null,
                Type: null,
                LeadLevel: null,
            }
        }
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.xs
        },

        the_headers() {
            return [{
                text: 'Rank',
                value: 'Rank',
                width: 70,
                align: 'center',
                class: 'rank-header',
            }, {
                text: 'Name',
                value: 'AgentName',
                class: 'header-class',
                width: 150,
            }, {
                text: this.totalTitle,
                value: 'total',
                width: 100,
                align: 'center',
            }].filter((h) => h !== undefined);
        },

        cardHeight: function() {
            if (this.$vuetify.breakpoint.xs) {
                return null
            }
            return 257 + (this.rows * 56) + "px"
        }
    },

    methods: {
        row_classes: function(item) {
            return 'test'
        },
        exportResults: function() {
            var fields = ['Rank', 'AgentName', 'Apps', 'APV'];
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.leaderboardData);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify(this.title + " " + this.endDate);
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("ExportLeaderboard", { "leaderboard": this.title })
            } catch (err) {
                console.error(err);
            }

        },
        getAgentPreferredName: function(item) {
            if (typeof item.PreferredName != 'undefined' && item.PreferredName != null && item.PreferredName != '') {
                return item.PreferredName
            }
            if (typeof item.PreferredAgentName != 'undefined' && item.PreferredAgentName != null && item.PreferredAgentName != '') {
                return item.PreferredAgentName
            }
            return item.AgentName

        }
    },
}
</script>

<style>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}

.agent-strong {
    font-weight: 900;
}

.rank-header {
    white-space: nowrap;
}

.leaderboard p.ref-value {
	margin-bottom: 0.5rem;
}
</style>
