import { Model } from '@vuex-orm/core'

export default class Application extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'application'

    static primaryKey = 'ID'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/applications',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: [
                'Type',
                'ProductType',
                'Carrier'
            ],
            availableFilters: null,
            current_application: {}
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            ID: this.uid(),
            FirstName: this.attr(''),
            LastName: this.attr(''),
            Source: this.attr(''),
            Type: this.attr(''),
            Carrier: this.attr(''),
            Product: this.attr(''),
            ProductType: this.attr(''),
            FaceAmount: this.attr(''),
            APV: this.attr(''),
            Status: this.attr(''),
            SplitPercent: this.attr(''),
            CrossSalePercentage: this.attr(''),
            CreateDate: this.attr(''),
        }
    }
}
