<template>
    <v-card cols="12" width="100%" class="pa-9 ml-3" style="max-width:550px;">
        <v-row>
            <h3 class="q-title text-center">Summit Income Calculator</h3>
        </v-row>
        <v-row class="d-flex">
            <v-select :items="Object.keys(formulaConstants).map( x => parseInt(x) )"
                      v-model="contractLevel" label="Contract Level" class="mr-2" style="max-width:145px;">
            </v-select>
            <v-text-field v-model.number="desiredIncome" type="number" label="What I Want in My Bank Account (Net Income)" class="ml-2"></v-text-field>
        </v-row>
        <v-row v-if="CalculatorType === 'Recruiting'">
            <v-text-field v-model.number="recruitingBudget" type="number" label="What I Want to Invest in Recruiting"></v-text-field>
        </v-row>
        <v-row class="d-flex flex-nowrap">
            <div class="text-right">Issue Paid I Need to Achieve: <br />
                <span v-if="CalculatorType === 'Income'" class="body-2">(To cover lead cost and create desired income)</span>
                <span v-if="CalculatorType === 'Recruiting'" class="body-2">(To cover lead cost, recruiting budget and create desired income)</span>
            </div>
            <div class="pl-5 align-self-end q-display-2 text-right" style="min-width: 150px;">
                {{ neededSubmit }}
            </div>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "QSummitCalculator",

    props: {
        CalculatorType: String
    },

    mounted() {
        // set contact level to agent's, use 105 for any level higher
        let agentContractLevel = parseInt(this.user.Agent.ContractLevel);
        if (agentContractLevel > 105) {
            agentContractLevel = 105;
        }
        this.contractLevel = agentContractLevel;
    },

    data() {
        return {
            // indexed by Contract Level, these come from the Activate Calculator v6 sheet
            // https://quility-my.sharepoint.com/:x:/p/lucas_eckert/EWI7F0zfh79HkkHvBI6LjFUBz853-UGGowB5PBTRaOB2oQ?wdOrigin=TEAMS-ELECTRON.p2p.bim&wdExp=TEAMS-CONTROL&wdhostclicktime=1670620964177&web=1
            formulaConstants: {
                105: {
                    avgLeadCost: 22.15125,
                    avgAPV: 1004.866913,
                    convRate: 0.119931958,
                },
                100: {
                    avgLeadCost: 21.09875,
                    avgAPV: 1005.649008,
                    convRate: 0.196783754,
                },
                95: {
                    avgLeadCost: 20.0425,
                    avgAPV: 1030.993693,
                    convRate: 0.081748743,
                },
                90: {
                    avgLeadCost: 18.98625,
                    avgAPV: 991.4754202,
                    convRate: 0.074821991,
                },
                85: {
                    avgLeadCost: 17.9325,
                    avgAPV: 1042.870077,
                    convRate: 0.05143649,
                },
                80: {
                    avgLeadCost: 16.88,
                    avgAPV: 1020.215039,
                    convRate: 0.030186746,
                },
            },

            desiredIncome: 5000.00,
            recruitingBudget: 1000.00,
            contractLevel: 85,
        }
    },

    computed: {
        neededSubmit: function() {
            const constants = this.formulaConstants[this.contractLevel];
            const advanceRatio = 0.75;

            let neededIncome = this.desiredIncome;
            if (this.CalculatorType === 'Recruiting') {
                if (this.recruitingBudget == '') {
                    this.recruitingBudget = 0;
                }
                neededIncome += this.recruitingBudget;
            }

            let leadsNeeded = (neededIncome / constants.avgAPV) / constants.convRate;
            let totalIncome = (neededIncome + (constants.avgLeadCost * leadsNeeded));
            let neededPremiumPlaced = totalIncome / (this.contractLevel / 100) / advanceRatio;

            return this.formatCurrency(neededPremiumPlaced);
        }
    },
}
</script>

<style scoped>

</style>
