import { Model } from '@vuex-orm/core'

export default class Level extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'level'

    static state() {
        return {
            loading: false,
            path: '/api/private/agentlevel/levels',
            filter_keys: [],
            availableFilters: null,
            loaded: false
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.attr(null),
            LevelName: this.uid(''),
            LevelType: this.attr(''),
            LevelRank: this.attr(''),
        }
    }
}
