import { DISABLED_PAGES_IN_DEMO_MODE } from '@/constants';

export default {
    computed: {
		filteredMenu () {
			return this.user.nav_menu
				.filter(this.allowDivision)
				.filter(this.allowInDemoMode)
				.map((item) => {
					const itemCopy = { ...item }
                    itemCopy.children = itemCopy.children
						.filter(this.allowDivision)
						.filter(this.allowInDemoMode)
                    return this.normalizeItemUrl(itemCopy)
				})
        },
    },
    methods: {
        allowDivision (item) {
            return item.divisions === 'Any' || this.hasDivision(item.divisions)
        },
        allowInDemoMode (item) {
            return !this.isDemoMode || !DISABLED_PAGES_IN_DEMO_MODE.includes(item.to)
        },
		normalizeItemUrl (item) {
			if (item.to?.startsWith('http')) {
				item.href = item.to
				item.to = null
			}
			if (item.href?.startsWith('/')) {
				item.to = item.href
				item.href = null
			}
			if (item.to?.endsWith('/') && item.to.length > 1) {
				item.to = item.to.slice(0, -1)
			}
			if (item.children) {
				item.children.forEach(this.normalizeItemUrl)
			}
			return item
		},
    },
}
