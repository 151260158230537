<template>
    <div class="glossary-editor">
        <Glossary :contentData="value" :sortWords="false" :filterWords="false">
            <template #header="{ item, index }">
                <div class="q-glossary__header" :ref="(itemCount - 1 === index) ? 'listItem' : undefined">
                    <div style="width: 100%;">
                        <v-text-field
                            v-model="item.word"
                            placeholder="Word"
                            @click.stop
                            @keyup.prevent
                        />
                    </div>

                    <v-btn @click="remove(index)" color="error" text icon>
                        <v-icon>fas fa-trash</v-icon>
                    </v-btn> 
                </div>
                
            </template>

            <template #text="{ item, index }">
                <TextEditor v-model="item.text" :key="item.word + index" placeholder="Description" />
            </template>
        </Glossary>
        <div class="mx-5 my-0">
            <v-btn @click="addNewTerm" style="width: 100%;">
                Add new term
            </v-btn>
        </div>
		<div class="glossary-editor__fab">
			<v-btn @click="sort" class="mr-2">
				<v-icon left>fas fa-sort</v-icon>
                Sort
            </v-btn>
			<v-btn @click="addNewTermAndScroll" color="primary">
				<v-icon left>fas fa-plus</v-icon>
                Add new term
            </v-btn>
		</div>
    </div>
</template>

<script>
import TextEditor from "../TextEditor.vue"
import Glossary from "../../../views/ContentBlocks/Glossary"

const GlossaryKey = 'Glossary'

export default {
    components: { Glossary, TextEditor },

    props: {
        value: { type: Object, default: () => ({}) }
    },

	mounted() {
		if (!this.value[GlossaryKey]) { return }
		this.sort()
	},

	computed: {
		itemCount() {
			return this.value[GlossaryKey]?.length
		},
	},

    methods: {
        reset() {
            if (typeof this.value[GlossaryKey] === 'undefined') {
                // Set here. Create reactive field   
                this.$set(this.value, GlossaryKey, [])
                this.addNewTerm()
            }
        },

        addNewTerm() {
            this.value[GlossaryKey].push({
                text: '',
                word: '',
            })
        },

		addNewTermAndScroll() {
			this.addNewTerm()
			this.$refs.listItem.scrollIntoView()
		},

        remove(index) {
            this.value[GlossaryKey].splice(index, 1)
        },

		sort() {
			this.value[GlossaryKey].sort((a, b) => a.word.localeCompare(b.word))
		},
    },

    created() { this.reset() },
}
</script>

<style lang="scss">
.q-glossary__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.glossary-editor {
	position: relative;
	&__fab {
		position: fixed;
		bottom: 150px;
		right: 1rem;
		z-index: 1000;
	}
}
</style>