import { Model } from '@vuex-orm/core'

export class PopupLog extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'popup_log'

    static state() {
        return {
            loading: false,
            path: '/api/private/popup',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: [],
            availableFilters: null,
            notice: {
                text: '',
                visible: false,
                color: 'error'
            }
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            AgentName: this.attr(''),
            id: this.uid(''),
            AgentCode: this.attr(''),
            created_at: this.attr(''),
            acknowledgement_date: this.attr(''),
            acknowledged: this.attr(false),
        }
    }

}

export default PopupLog;
