<template>
    <div class="q-content-priority">
		<p v-if="title" class="q-content-priority__title">{{ title }}</p>
		<p v-if="description" class="q-content-priority__description">{{ description }}</p>

        <v-radio-group v-model="mValue" :disabled="disabled" hide-details class="mt-2">
            <v-radio
				v-for="option in options" :key="option.value"
				:value="option.value"
				:label="option.label"
				:color="color"
			/>
        </v-radio-group>
    </div>
</template>

<script>
export default {
    props: {
		value: {
			type: Number,
			default: 5,
		},
		title: {
			type: String,
			default: 'Content Priority in Search Results',
		},
		description: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: 'primary',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
    computed: {
		mValue: {
			get () { return this.value },
			set (val) { this.$emit('input', val) },
		},
		options () {
			return [
				{ label: '1 - High Priority', value: 1 },
				{ label: '2', value: 2 },
				{ label: '3', value: 3 },
				{ label: '4', value: 4 },
				{ label: '5 - Low Priority', value: 5 },
			]
		},
    },
}
</script>

<style lang="scss">
.q-content-priority {
	padding-bottom: 0.75rem;
}

.q-content-priority__title {
	margin-bottom: 0.5rem !important;
	font-size: 1em;
	font-weight: 500;
}

.q-content-priority__description {
	margin-bottom: 0.5rem !important;
	font-size: 0.875em !important;
	font-weight: 400;
}
</style>