import { getInstance } from "@/auth/index";
export default {

    loadTop5Leadersboards(timeframe, filters, options) {
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/leaderboards/' + timeframe.toLowerCase(), params)
            .then(function(json) {
                return json
            }).catch(function(err) {
                throw err
            })
    },

    loadLeaderboard(timeframe = 'weekly', type = 'direct', agentCode = '', rows, showAll, filters, options) {
        const queueKey = 'getLeaderboard' + type
        this.cancelQRequest(queueKey)
        const params = {
            ...options,
            ...filters,
			numPerPage: rows,
			showAll,
        }
		const commonPath = `/api/private/leaderboards/${timeframe.toLowerCase()}/${type.toLowerCase()}/${agentCode}`
		const capitalizedTimeframe = timeframe.charAt(0).toUpperCase() + timeframe.slice(1)
		const paths = {
			totalAgencyReset: `/api/private/reset_leaderboard/totalagency/${agentCode}/${capitalizedTimeframe}`,
			totalAgencySME: `/api/private/sme_leaderboard/totalagency/${agentCode}/${capitalizedTimeframe}`,
			symmetryReset: `/api/private/reset_leaderboard/symmetry/${capitalizedTimeframe}`,
			symmetrySME: `/api/private/sme_leaderboard/symmetry/${capitalizedTimeframe}`,
		}
		const path = paths[type] || commonPath
        return this.getRequest(path, params, queueKey)
            .then(function(json) {
                return json
            }).catch(function(err) {
                throw err
            })
    },

    loadEventLeaderboard(agentcode, type, filters, options) {
        var queuKey = "loadEventLeaderboard" + type
        this.cancelQRequest(queuKey)
        if(!options){
            options = {
                'type': type
            }
        }
        else{
            options['type'] = type
        }

        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/leaderboards/events/' + agentcode, params, queuKey)
        .then(function(json) {
            return json
        }).catch(function(err) {
            throw err
        })
    },
    loadRxLeadersboards(timeframe, filters, options) {
        var params = {
            ...options,
            ...filters,
            timeframe
        }
        return this.getRequest('/api/private/leaderboards/rx', params)
            .then(function(json) {
                return json
            }).catch(function(err) {
                throw err
            })
    },
}
