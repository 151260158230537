<!--Full width text column -->
<template>
    <div :class="block_class" @click="maybeOpenLink">
        <slot name="meta" />
        <div
			v-if="!editing"
			class="banner-image__block banner-image__ratio-block"
			:class="contentData.background_color"
			:style="background_style"
		>
            <div class="banner-image__text" :class="contentData.text_class">
                <slot name="text">
                    <div v-html="contentData.text" class="pa-6" />
                </slot>
            </div>
        </div>
        <!-- EDITING VIEW OF BOTH IMAGES -->
        <div
			v-if="editing"
			class="banner-image__block banner-image__ratio-block"
			:class="contentData.background_color"
			:style="desktopBackgroundStyle"
		>
            <div class="banner-image__text" :class="contentData.text_class">
                <slot name="text">
                    <div v-html="contentData.text" class="pa-6" />
                </slot>
            </div>
        </div>
		<div
			v-if="editing && contentData.background_image_mobile"
			class="mt-6 mx-auto"
			:style="`max-width: ${$vuetify.breakpoint.thresholds.sm}px`"
		>
			<div
				class="banner-image__block banner-image__ratio-block"
				:class="contentData.background_color"
				:style="mobileBackgroundStyle"
			>
				<div class="banner-image__text" :class="contentData.text_class">
					<slot name="text">
						<div v-html="contentData.text" class="pa-6" />
					</slot>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
//use this component like so...
//<text pulls html from the editor">

const ASPECT_RATIO = 48 / 11
const ASPECT_RATIO_MOBILE = 3 / 2

export default {
    name: "Banner",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    background_image: null,
                    background_color: null,
                    text_class: null
                }
            }
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
		desktopAspectRatio: ASPECT_RATIO,
		mobileAspectRatio: ASPECT_RATIO_MOBILE,
    }),
    computed: {
		isMobile () {
			return this.$vuetify.breakpoint.smAndDown
		},
		desktopAspectRatioPadding () {
			return `${(100 / this.desktopAspectRatio).toFixed(2)}%`
		},
		mobileAspectRatioPadding () {
			return `${(100 / this.mobileAspectRatio).toFixed(2)}%`
		},
		aspectRatioPadding () {
			return this.isMobile ? this.mobileAspectRatioPadding : this.desktopAspectRatioPadding
		},
		use_background_image () {
			return this.isMobile && this.contentData.background_image_mobile
				? this.contentData.background_image_mobile
				: this.contentData.background_image
		},
        background_style () {
			return {
				backgroundImage: `url("${this.use_background_image}")`,
				paddingBottom: this.aspectRatioPadding,
			}
        },
		desktopBackgroundStyle () {
			return {
				backgroundImage: `url("${this.contentData.background_image}")`,
				paddingBottom: this.desktopAspectRatioPadding,
			}
		},
		mobileBackgroundStyle () {
			return {
				backgroundImage: `url("${this.contentData.background_image_mobile}")`,
				paddingBottom: this.mobileAspectRatioPadding,
			}
		},
        isClickable: function() {
            if (this.contentData.link != null && this.contentData.link != '') {
                return true
            }
            return false
        },
        block_class: function() {
            if (this.isClickable) {
                return "banner clickable"
            }
            return "banner"
        }
    },
    methods: {
        maybeOpenLink: function() {
            if (this.editing) {
                return
            }
            if (this.contentData.link != null && this.contentData.link != '') {
                if (this.contentData.link.indexOf('http') > -1) {
                    window.open(this.contentData.link, "_blank")
                } else {
                    this.$router.push(this.contentData.link)
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
	.banner-image__ratio-block {
		position: relative;
		height: unset !important;
	}

	.banner-image__text {
		position: absolute;
		inset: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
</style>
