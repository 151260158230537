<template>
    <div class="question_field mx-2">
        <slot name="meta">
            <span></span>
        </slot>
        <strong v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</strong>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>
        <v-checkbox :disabled="disableField" :label="field_label" :rules="question_rules" :hint="question.FormQuestionConfig.hint" :prefix="question.FormQuestionConfig.prefix" :prepend-icon="question.FormQuestionConfig.prependIcon" :suffix="question.FormQuestionConfig.suffix" :append-outer-icon="question.FormQuestionConfig.appendIcon" v-model="value.AnswerContent"></v-checkbox>
        <slot></slot>
    </div>
</template>
<script>
/*
This simple text field is used asd a mix in for other form fields... add features to this with caution as it may carry down stream.
 */
import FmlFieldMixins from './FmlFieldMixins.vue'
export default {
    name: "FmlCheckBox",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: 'Boolean'
        }
    },
    mounted: function() {

    },
    props: {

    },
    computed: {

    },
    methods: {
        updateFieldDefaults: function() {
            this.question.FormQuestionConfig.AnswerDataType = this.AnswerDataType
            this.question.FormQuestionConfig.useQuestionAsLabel = false;
        },
    },

    components: {

    },
    watch: {

    }
}

</script>
