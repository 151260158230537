<template>
    <div>
        <v-text-field v-model="the_url" :label="label" append-outer-icon="fas fa-file-alt" @click:append-outer="showDocumentLibary">
        </v-text-field>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus';

export default {
    props: ['value', 'label', 'relative_path'],
    data: function() {
        return {
            the_url: this.value
        }
    },
    created: function() {

    },
    mounted: function() {
        //this.image = this.value;
    },
    components: {

    },
    methods: {
        showDocumentLibary: function() {
            EventBus.$emit("documentlibrary", this.useFile)
        },
        useFile: function(file, title) {
            this.the_url = file;
            this.$emit("linktitle", title);
        },
        updateValue: function(v, emit_event) {
			this.the_url = v;
            if (emit_event) {
                this.$emit("input", v);
            }
        }
    },
    watch: {
        'value': function(newV) {
            this.updateValue(newV, false)
        },
        'the_url': function(newV) {
			let url = ''
			if (this.relative_path) {
				// Cleanning hostname from URL using a RegEx
				url = newV.replace( /^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '/' )
			} else {
				url = newV
			}
            this.updateValue(url, true)
        }
    }
}

</script>
