<template>
    <div>
        <Accordion :contentData="value" :alwaysOpen="true">
            <template #header="{ item, index }">
                <div style="width: 100%;">
                    <v-text-field 
                        v-model="item.header"
                        placeholder="Panel header"
                        @click.stop
                        @keyup.prevent
                    />
                </div>

                <div>
                    <v-btn @click="remove(index)" color="error" text icon>
                        <v-icon>fas fa-trash</v-icon>
                    </v-btn>                
                </div>

            </template>

            <template #text="{ item }">
                <text-editor v-model="item.text" placeholder="Panel inner text" />
            </template>
        </Accordion>
        <div class="mx-5 my-0">
            <v-btn @click="addNewPanel" style="width: 100%;">Add panel</v-btn>
        </div>
    </div>   
</template>

<script>
import TextEditor from '../TextEditor.vue'
import Accordion from '../../../views/ContentBlocks/Accordion.vue'

const panelsKey = 'AccordionPanels'

export default {
    components: { TextEditor, Accordion },

    props: {
        value: { type: Object, default: () => ({}) }
    },

    created() { this.reset() },

    methods: {
        reset() {
            if (typeof this.value[panelsKey] === 'undefined') {
                // Set here. Create reactive field   
                this.$set(this.value, panelsKey, [])
                this.addNewPanel()
            }
        },
        addNewPanel() {
            this.value[panelsKey].push({
                text: '',
                header: '',
            })
        },
        remove(index) {
            this.$set(this.value, panelsKey, this.value[panelsKey].filter((item, i) => i !== index))
        }
    }
}
</script>