<template>
    <div class="question_field mx-2">
        <slot name="meta">
            <span></span>
        </slot>
        <strong v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</strong>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>
        <v-combobox :disabled="disableField" :label="field_label" :rules="question_rules" :hint="question.FormQuestionConfig.hint" :prefix="question.FormQuestionConfig.prefix" :prepend-icon="question.FormQuestionConfig.prependIcon" :suffix="question.FormQuestionConfig.suffix" :append-outer-icon="question.FormQuestionConfig.appendIcon" v-model="value.AnswerContent" :multiple="question.FormQuestionConfig.select_options.multiple" :chips="question.FormQuestionConfig.select_options.chips" :items="question.FormQuestionConfig.select_options.items"></v-combobox>
        <slot></slot>
    </div>
</template>
<script>
/*
This simple text field is used asd a mix in for other form fields... add features to this with caution as it may carry down stream.
 */
import FmlFieldMixins from './FmlFieldMixins.vue'
export default {
    name: "FmlTags",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: this.question.FormQuestionConfig.AnswerDataType
        }
    },
    mounted: function() {

    },
    props: {

    },
    computed: {

    },
    methods: {
        updateFieldDefaults: function() {
            this.$set(this.question.FormQuestionConfig, "select_options", {});
            this.$set(this.question.FormQuestionConfig, "AnswerDataType", this.AnswerDataType)
            this.$set(this.question.FormQuestionConfig, "useQuestionAsLabel", true)
            this.$set(this.question.FormQuestionConfig.select_options, "multiple", true)
            this.$set(this.question.FormQuestionConfig.select_options, "chips", true)
            this.$set(this.question.FormQuestionConfig.select_options, "label_value", false)
            this.$set(this.question.FormQuestionConfig.select_options, "item_collection", "Custom")
            //this.$set(this.question.FormQuestionConfig.select_options, "item_collection", "Custom")
            //this.$set(this.question.FormQuestionConfig.select_options, "label_value", true)
            this.$set(this.question.FormQuestionConfig.select_options, "items_config", "")
            this.$set(this.question.FormQuestionConfig.select_options, "items", [])
        },

    },

    components: {

    },
    watch: {
        'question.FormQuestionConfig.select_options.multiple': function(newV) {
            if (newV) {
                this.AnswerDataType = 'Array'
            } else {
                this.AnswerDataType = 'String'
            }
            this.question.FormQuestionConfig.AnswerDataType = this.AnswerDataType
        }
    }
}

</script>
