<template>
	<v-row class="mx-5" cols="12">
		<v-col cols="12" lg="6">
			<v-autocomplete
				v-model="mValue.groups"
				:items="groups"
				:loading="loading"
				chips
				small-chips
				label="Groups"
				multiple
			>
				<template v-if="groups.length > 1" #prepend-item>
					<v-list-item link @click="toggleAllGroups">
						<v-list-item-action>
							<v-simple-checkbox :value="isAllGroups" @input="toggleAllGroups" />
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>- All groups -</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-autocomplete>
		</v-col>

		<v-col cols="12" lg="6">
			<v-autocomplete
				v-model="mValue.tags"
				:items="tags"
				:loading="loading"
				chips
				small-chips
				label="Subjects"
				multiple
			>
				<template v-if="tags.length > 1" #prepend-item>
					<v-list-item link @click="toggleAllTags">
						<v-list-item-action>
							<v-simple-checkbox :value="isAllTags" @input="toggleAllTags" />
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>- All subjects -</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-autocomplete>
		</v-col>
	</v-row>
</template>

<script>
import QuilityAPI from "@/store/API/QuilityAPI"

const WITHOUT_SUBJECTS = '- Without Subjects -'

export default {
  	name: "MarketingResourceEditor",

	props: {
		value: { type: Object, default: () => ({}) },
	},

	data () {
		return {
			data: null,
			mValue: {
				groups: [],
				tags: [],
			},
			loading: false,
			isAllGroups: false,
			isAllTags: false,
		}
	},

	created () {
		const { groups, tags } = this.value
		this.mValue = { groups, tags }
	},

	mounted () {
		this.loadData()
	},

	computed: {
		groups () {
			if (!this.data) { return [] }
			return Object.keys(this.data)
		},
		tags () {
			if (!this.groups.length) { return [] }
			const groups = this.mValue.groups?.length ? this.mValue.groups : this.groups
			const items = groups.map(group => this.data[group]).flat()
			const uniqueItems = [...new Set(items)].sort()
			const withoutDocumentTagsIndex = uniqueItems.indexOf('-empty-')
			if (withoutDocumentTagsIndex !== -1) {
				uniqueItems.splice(withoutDocumentTagsIndex, 1)
				return [WITHOUT_SUBJECTS, ...uniqueItems]
			}
			return uniqueItems
		},
	},

	watch: {
		mValue: {
			handler () {
				this.processAllGroupsSelector()
				this.processAllTagsSelector()
				this.$emit('input', { ...this.value, groups: this.mValue.groups, tags: this.mValue.tags })
			},
			deep: true,
		},
		tags () {
			if (this.mValue.tags && this.data) {
				this.mValue.tags = this.mValue.tags.filter(tag => this.tags.includes(tag))
			}
		},
	},

	methods: {
		async loadData () {
			this.loading = true
			this.data = null
			const response = await QuilityAPI.getMarketingResourceLibrarySubjects()
			if (response.error) {
				this.showError('Could not load groups and subjects', response.msg)
			} else {
				this.data = response.data
			}
			this.loading = false
		},
		toggleAllGroups () {
			if (this.isAllGroups) {
				this.isAllGroups = false
				this.mValue.groups = []
			} else {
				this.isAllGroups = true
				this.mValue.groups = this.groups
			}
		},
		toggleAllTags () {
			if (this.isAllTags) {
				this.isAllTags = false
				this.mValue.tags = []
			} else {
				this.isAllTags = true
				this.mValue.tags = this.tags
			}
		},
		processAllGroupsSelector () {
			if (this.mValue.groups?.length === this.groups.length) {
				this.isAllGroups = true
			} else {
				this.isAllGroups = false
			}
		},
		processAllTagsSelector () {
			if (this.mValue.tags?.length === this.tags.length) {
				this.isAllTags = true
			} else {
				this.isAllTags = false
			}
		},
	},
}
</script>
