import { Node } from 'tiptap'
import { setBlockType, textblockTypeInputRule, toggleBlockType } from 'tiptap-commands'

export default class Heading extends Node {

    get name() {
        return 'heading'
    }

    get defaultOptions() {
        return {
            levels: [1, 2, 3, 4, 5, 6],
            classes: 'text-left'
        }
    }

    get schema() {
        return {
            attrs: {
                level: {
                    default: 1,
                },
                classes: 'text-left'
            },
            content: 'inline*',
            group: 'block',
            defining: true,
            draggable: false,
            parseDOM: this.options.levels
                .map(level => ({
                    tag: `h${level}`,
                    getAttrs: (node) => ({
                        level: level,
                        classes: node.classList
                    }),
                })),
            toDOM: node => [`h${node.attrs.level}`, { class: node.attrs.classes }, 0],
        }
    }

    commands({ type, schema }) {
        return attrs => toggleBlockType(type, schema.nodes.heading, attrs)
    }

    keys({ type }) {
        return this.options.levels.reduce((items, level) => ({
            ...items,
            ...{
                [`Shift-Ctrl-${level}`]: setBlockType(type, { level }),
            },
        }), {})
    }

    inputRules({ type }) {
        return this.options.levels.map(level => textblockTypeInputRule(
            new RegExp(`^(#{1,${level}})\\s$`),
            type,
            () => ({ level }),
        ))
    }

}
