<template>
    <script-embed-group>
        <template v-slot:meta>
            <v-row cols="12" class="d-block px-5" style="min-width:100% !important;">
                <v-col cols="12">
                    <v-text-field v-model="value.Title" label="Title"></v-text-field>
                </v-col>
                <v-col cols="1">
                    <v-text-field v-model="value.NumColumns" label="Number of Columns"></v-text-field>
                </v-col>
            </v-row>
        </template>
        <template v-slot:Iframes>
            <v-col v-for="(embed, key) in value.Embeds" :key="embed.id" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="text-left px-5">
                <v-hover v-slot:default="{ hover }">
                    <v-card>
                        <v-card-text>
                            <text-editor v-model="embed.pretext"></text-editor>
                            <v-textarea label="Code" v-model="embed.code"></v-textarea>
                            <text-editor v-model="embed.text"></text-editor>
                        </v-card-text>
                        <v-btn @click="deleteEmbed(key)" v-if="hover" fab small absolute top right color="red">
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="6">
                <v-btn large color="primary" @click="addEmbed">Add Embed</v-btn>
            </v-col>
        </template>
    </script-embed-group>
</template>
<script>
import TextEditor from '../TextEditor.vue'
import ScriptEmbedGroup from '@/CMS/views/ContentBlocks/ScriptEmbedGroup.vue'
export default {
    props: ['value'],
    data: function() {
        return {
            counter: Math.floor(Math.random() * 1000000000),
        }
    },
    created: function() {
        this.reset();
    },
    components: {
        TextEditor,
        ScriptEmbedGroup,
    },
    methods: {
        reset: function() {
            if (typeof this.value.Embeds == 'undefined') {
                this.counter = this.counter + 1
                this.$set(this.value, 'Embeds', [{
                    id: this.counter,
                    title: "",
                    pretext: "",
                    code: "<script><\/script>",
                    text: "",
                }])
            }
            if (typeof this.value.NumColumns == 'undefined') {
                this.$set(this.value, 'NumColumns', 4)
            }
            this.$emit('input', this.value)
        },
        addEmbed: function() {
            this.counter = this.counter + 1
            this.value.Embeds.push({
                id: this.counter,
                title: "",
                pretext: "",
                code: "<script><\/script>",
                text: "",
            })
        },
        deleteEmbed: function(idx) {
            var g = this;
            this.$nextTick(function() {
                g.$delete(this.value.Embeds, idx)
            })
        }
    },
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.value.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.value.NumColumns);
        }
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
