<template>
	<v-dialog v-model="computedValue" width="95%" max-width="900px" :fullscreen="$vuetify.breakpoint.xs" content-class="doc-preview-dialog">
		<v-card :key="previewDocumentUrl" class="doc-preview-dialog__card">
			<div class="doc-preview-dialog__card-header">
				<v-btn v-if="withDownload" fab color="primary" @click="downloadDoc(doc)">
					<v-icon>fas fa-download</v-icon>
				</v-btn>
				<div v-if="doc.DocTitle" class="text-center font-weight-bold ml-auto">{{ doc.DocTitle }}</div>
				<v-btn fab color="red" class="ml-auto" @click="computedValue = false">
					<v-icon>fas fa-times-circle</v-icon>
				</v-btn>
			</div>

			<div v-if="previewDocumentDocType === 'archive'" class="doc-preview-dialog__card-content content-archive">
				<strong>No Preview Available</strong><br>
				<v-btn color="primary" @click="downloadDoc(doc)">
					Download <v-icon right>fas fa-download</v-icon>
				</v-btn>
			</div>

			<PdfPreview v-else-if="previewDocumentDocType === 'pdf'" :value="previewDocumentUrl" class="doc-preview-dialog__card-content" />

			<div v-else-if="previewDocumentDocType === 'image'" class="doc-preview-dialog__card-content content-media">
				<v-img :src="previewDocumentUrl" />
			</div>

			<video v-else-if="previewDocumentDocType === 'video'" controls class="doc-preview-dialog__card-content content-media">
				<source :src="previewDocumentUrl">
				Your browser does not support the video tag.
			</video>

			<audio v-else-if="previewDocumentDocType === 'audio'" controls class="doc-preview-dialog__card-content content-media">
				<source :src="previewDocumentUrl">
				Your browser does not support the audio tag.
			</audio>

			<VueDocPreview v-else :url="previewDocumentUrl" :type="previewDocumentDocType" class="doc-preview-dialog__card-content" />
		</v-card>
	</v-dialog>
</template>

<script>
import VueDocPreview from 'vue-doc-preview'

import PdfPreview from '@/CMS/editor/components/PdfPreview.vue'
import QDocPreviewMixin from '@/CMS/editor/components/QDocPreviewMixin'

export default {
    components: {
		VueDocPreview,
		PdfPreview,
	},

	mixins: [QDocPreviewMixin],

    props: {
        value: { type: Boolean, default: false },
        doc: { type: Object, default: () => ({}) },
        withDownload: { type: Boolean, default: false },
    },

	watch: {
		doc: {
			handler () {
				this.previewDoc = this.value ? this.doc : {}
			},
			immediate: true,
		},
	},

	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
	},
}
</script>

<style lang="scss">
$btn-height: 3.5rem;
$min-height: 10rem;

.doc-preview-dialog {
	position: relative;
	min-height: $min-height;

	&__card {
		min-height: $min-height;
	}

	&__card-header {
		position: absolute;
		width: 100%;
		padding: 0 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: 1rem;
		z-index: 1;
	}

	&__card-content {
		min-height: $min-height;
		padding: $btn-height 0.5rem 0.5rem;
	}

	.content-archive {
		text-align: center;
	}

	.content-media {
		width: 100%;
	}
}
</style>
