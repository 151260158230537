export default {


    getWeeklyOnboardingReport(filters, options) {
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/reports/onboarding', params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

}
