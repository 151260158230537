export default {


    getProducts(filters, options) {
        this.cancelQRequest("QProductsGet");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/products', params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getProduct(id) {
        this.cancelQRequest("QProductsGet");
        return this.getRequest('/api/private/products/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    createProduct(product) {
        return this.postRequest('/api/private/products', product).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    updateProduct(product) {
        return this.putRequest('/api/private/products/' + product.ID, product).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    deleteProduct(id) {
        return this.deleteRequest('/api/private/products/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

}
