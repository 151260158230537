<template>
    <full-width-text>
        <template v-slot:text>
            <v-col cols="12">
                <v-text-field v-model="value.Title" label="Title"></v-text-field>
            </v-col>
            <text-editor v-model="value.text"></text-editor>
        </template>
    </full-width-text>
</template>
<script>
import TextEditor from '../TextEditor.vue'
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue'
export default {
    props: ['value'],
    created: function() {
        this.reset();
    },
    components: {
        TextEditor,
        FullWidthText
    },
    methods: {
        reset: function() {
            if (typeof this.value.text == 'undefined') {
                this.$set(this.value, 'text', "")
            }
            if (typeof this.value.Title == 'undefined') {
                this.$set(this.value, 'Title', "")
            }
            this.$emit('input', this.value)
        },
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
