export default {
    getImages(page, division, search) {
        var url = '/api/private/images?page=' + page + '&sortBy=created_at&sortDesc=true&division=' + division
        if(search !== undefined && search.length > 0)
            url += '&search='+search
        return this.getRequest(url).then(function(json) {
            return json
        })
    },
    deleteImage(id) {
        return this.deleteRequest('/api/private/images/' + id)
			.catch((err) => {
				const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
				return { error: err, message }
			})
    },
}
