<template>
    <v-container fluid class="px-5 transparent">
    	<div class="site-width">
        	<slot></slot>
    	</div>
    </v-container>
</template>
<script>
export default {
    props: [],
    data: () => ({}),
    components: {

    },
}

</script>
