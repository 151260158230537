<template>
    <div class="question_field mx-2">
        <slot name="meta">
            <span></span>
        </slot>
        <strong v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</strong>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>
        <v-textarea :disabled="disableField" :label="field_label" :rules="question_rules" :hint="question.FormQuestionConfig.hint" :prefix="question.FormQuestionConfig.prefix" :prepend-icon="question.FormQuestionConfig.prependIcon" :suffix="question.FormQuestionConfig.suffix" :append-outer-icon="question.FormQuestionConfig.appendIcon" v-model="value.AnswerContent">
        </v-textarea>
        <slot></slot>
    </div>
</template>
<script>
import FmlFieldMixins from './FmlFieldMixins.vue'
export default {
    name: "FmlTextArea",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: 'String'
        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },

    components: {

    },
    watch: {

    }
}

</script>
