<template>
	<v-combobox
		v-model="valueComputed"
		:items="itemsComputed"
		:rules="rules"
		:loading="loading || itemsLoading"
		:label="label"
		placeholder="Select existing or type new"
	/>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'

export default {
	props: {
		value: { type: [Array, String], default: () => [] },
		rules: { type: Array, default: () => [] },
		label: { type: String, default: 'Group' },
		items: { type: Array, default: () => [] },
		loadItems: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
	},

	data () {
		return {
			groups: [],
			itemsLoading: false,
		}
	},

	created () {
		if (this.loadItems) {
			this.loadGroups()
		}
	},

	watch: {
		loadItems (newValue) {
			if (newValue) {
				this.loadGroups()
			}
		},
	},

	computed: {
		valueComputed: {
			get () { return this.$props.value },
			set (value) { this.$emit('input', value) }
		},
		itemsComputed () {
			const items = this.loadItems
				? this.groups
				: Array.isArray(this.items) ? this.items : []
			return items.length
				? items
				: [{ header: 'No existing groups to select', disabled: true }]
		},
	},

	methods: {
		async loadGroups () {
			this.itemsLoading = true
			const data = await QuilityAPI.getMarketingResourcesGroupsList()
			if (!this.loadItems) { return }
			if (data.error) {
				this.showError('Could not load groups', data.msg)
				this.groups = []
			} else {
				this.groups = data
			}
			this.itemsLoading = false
		},
	},
}
</script>
