<template>
    <div class="q-indeterminate-checkbox d-flex align-center" :class="computedClasses" @click="onClick">
        <div class="q-indeterminate-checkbox__checkbox mr-4 d-flex align-center">
            <v-icon v-if="value === computedValues.true" color="success">
                fas fa-check
            </v-icon>
            <v-icon v-else-if="value === computedValues.false" color="error">
                fas fa-times
            </v-icon>
            <div class="q-indeterminate-checkbox__clear-icon" v-else-if="value === computedValues.null" />
        </div>

        <div class="q-indeterminate-checkbox__label">
            <slot name="label">
                {{ label }}
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { default: null },
        trueValue: { default: true },
        falseValue: { default: false },
        nullValue: { default: null },
        label: { type: String, default: '' },
        valueKey: { type: String, default: 'value' },
        textKey: { type: String, default: 'text' },
    },

    data() {
        return {
            innerValue: null
        }
    },

    computed: {
        computedValues() {
            return {
                null: this.getValue(this.nullValue),
                true: this.getTrueValue(this.trueValue),
                false: this.getFalseValue(this.falseValue)
            }
        },
        computedClasses() {
            return {
                'q-indeterminate-checkbox__true': this.value === this.computedValues.true,
                'q-indeterminate-checkbox__false': this.value === this.computedValues.false,
                'q-indeterminate-checkbox__null': this.value === this.computedValues.null,
            }
        }
    },

    methods: {
        getValue(value) {
            if (value !== null && typeof value === 'object') {
                return value[this.valueKey]
            }
            return value
        },
        getTrueValue(value) {
            if (value !== null && typeof value === 'object')
                return value[this.valueKey]
            
            if(value == "0")
                return "1"

            return value
        },
        getFalseValue(value) {
            if (value !== null && typeof value === 'object')
                return value[this.valueKey]
            
            if(value == "1")
                return "0"

            return value
        },
        updateValue(value) {
            this.$emit('input', value)
        },
        nextValue() {
            if (this.value === this.computedValues.true) {
                return this.computedValues.false
            }
            if (this.value === this.computedValues.false) {
                return this.computedValues.null
            }
            if (this.value === this.computedValues.null) {
                return this.computedValues.true
            }
        },
        onClick() {
            this.updateValue(this.nextValue())
        }
    },

    watch: {
        value: {
            handler(newValue) { this.innerValue = this.getValue(newValue) },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.q-indeterminate-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;

    &__checkbox {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(0, 0, 0, 0.54);
    }

    &__clear-icon {
        width: 100%;
        height: 100%;
    }

    .v-icon.v-icon {
        font-size: 14px !important;
    }
}
</style>