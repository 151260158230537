<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        standard-submenu
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QBenefitsSubmenu",
    data() {
        return {
            title: "Benefits, Promotions & Bonuses",
            titlelink: [],
        }
    },
    computed: {
        buttons() {
            return [
                {
                    label: "Benefits",
                    to: "/page/benefits",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Promotion Guidelines",
                    href: "https://hq.quility.com/cms/document/view/203903",
                    icon: "fas fa-external-link-alt",
                },
                {
                    label: "Bonuses",
                    href: "https://hq.quility.com/api/public/document/44512/view/best-in-class-bonus-program-one-sheet",
                    icon: "fas fa-external-link-alt",
                },
                {
                    label: "Bird-Dog Bonus",
                    to: "/page/quility-b2b",
                    icon: "fas fa-arrow-alt-circle-right",
                },
            ]
        },
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
