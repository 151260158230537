<!--Full width text column -->
<template>
    <v-row class="mx-5 my-0" cols="12" width="100%">
        <h3 v-if="contentData.Title" class="q-title">{{contentData.Title}}</h3>
        <v-col cols="12 text-left">
            <slot name="text">
                <div v-html="contentData.text"></div>
            </slot>
        </v-col>
    </v-row>
</template>
<script>
//use this component like so...
//<text pulls html from the editor">

export default {
    name: "FullWidthText",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    title: null,
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {

    },
    methods: {

    }
}

</script>
