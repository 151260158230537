<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-quility-tech-submenu></q-quility-tech-submenu>
        <div>
            <v-container fluid class="px-5 transparent site-width">
                <slot></slot>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QQuilityTechSubmenu from '@/components/navigation/Submenus/QQuilityTechSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QQuilityTechSubmenu,
    },
}
</script>