<template>
    <zendesk-text>
        <template v-slot:text>
            <div>
                <div v-html="value.text">
                </div>
                <v-banner>
                    <em class="grey--text">Zendesk support articles are only edited through Zendesk</em>
                    <template v-slot:actions>
                        <v-btn href="https://symmetry.zendesk.com" target="_tab">Zendesk <v-icon right>fas fa-external-link-alt</v-icon>
                        </v-btn>
                    </template>
                </v-banner>
            </div>
        </template>
    </zendesk-text>
</template>
<script>
import ZendeskText from '@/CMS/views/ContentBlocks/ZendeskText.vue'
export default {
    props: ['value'],
    created: function() {

    },
    components: {
        ZendeskText
    },
    methods: {},
    watch: {}
}

</script>
