import PopupNotice from '@/store/Models/PopupNotice'

export default {


    getBulkJobs(filters, options) {
        if (typeof this.current_request.abort != 'undefined') {
            this.current_request.abort();
        }
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/bulkjob', params).then(function(json) {
            return json
        })
    },

    getSampleBulkJobData(id) {
        var params = {}
        return this.getRequest('/api/private/bulkjob/' + id + '/sample', params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    processUpload(id, jobType) {
        var params = {
            ...jobType
        }
        return this.putRequest('/api/private/bulkjob/' + id + '/start', params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    dispatchExportJob(job) {
        return this.postRequest('/api/private/jobs', job).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    pingJobStatus(job_id) {
        return this.getRequest('/api/private/jobs/' + job_id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getJobs(filters, options) {
        var params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("QgetJobsExport");
        return this.getRequest('/api/private/jobs', params, "QgetJobsExport").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    downloadExportJob(job_id) {
        return this.getRequest('/api/private/jobs/' + job_id + '/download').then(function(response) {
                return response
            },
            function(error) {
                return error
            })
    },


    downloadBulkJobErrorLogFile(id) {

        return this.getRequest('/api/private/bulkjob/' + id + '/errorlog', {}).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getStaff(Staff) {
        return this.getRequest('/api/private/staff', Staff).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    addStaff(Staff) {
        return this.putRequest('/api/private/staff', Staff).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getAgentAudit(filters, options, agentcode) {
        this.cancelQRequest("QAgentAudit");
        var params = {
            ...options,
            ...filters
        }
        var append = agentcode ? "/" + agentcode : '';
        return this.getRequest('/api/private/audit/agents' + append, params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getWritingNumberAudit(filters, options, agentcode) {
        this.cancelQRequest("QWritingNumberAudit");
        var params = {
            ...options,
            ...filters
        }
        var append = agentcode ? "/" + agentcode : '';
        return this.getRequest('/api/private/audit/writing_numbers' + append, params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getAvailableLogs(log_type, log_file) {
        var params = {}
        if (typeof log_file != 'undefined') {
            params = { 'log_file': log_file }
        }
        return this.getRequest('/api/private/audit/logs/' + log_type, params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getAgentHierarchyAuditLog(agent_code) {

        return this.getRequest('/api/private/audit/agents/' + agent_code + '/hierarchy').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getAgentUserLog(filters, options, agentcode) {
        this.cancelQRequest("QAgentUserLog");
        var params = {
            ...options,
            ...filters
        }
        var append = agentcode ? "/" + agentcode : '';
        return this.getRequest('/api/private/user_log' + append, params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

}
