<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        standard-submenu
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from "@/components/navigation/Submenus/base/QDynamicSubmenu.vue";

export default {
    name: "QTrainingSubmenu",
    data() {
        return {
            title: "Training",
            titlelink: [],
        };
    },
    computed: {
        buttons() {
            const withDivision = this.hasDivision("Corporate") || this.hasRole(["Exec", "SuperAdmin", "Staff"]);

            return [
                {
                    label: "Quility U",
                    href: withDivision
                        ? "https://quility.lightspeedvt.com/"
                        : "https://auth.quility.com/samlp/z6P8HtF55HXSgXbAT1anWb2BMM2Fdko0",
                    icon: "fas fa-external-link-alt",
                },
                {
                    label: "Summit",
                    to: "/summit",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Training Scripts",
                    to: "/page/training-scripts",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Calls & Webinars",
                    to: "/page/calls-and-webinars",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Telesales Training",
                    to: "/page/telesales",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Navigator Training",
                    to: "/page/quility-navigator-training",
                    icon: "fas fa-arrow-alt-circle-right",
                },
            ];
        },
    },
    components: {
        QDynamicSubmenu,
    },
};
</script>

<style></style>
