<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <div
			class="pt-4 pb-4 pl-4"
			:class="[backgroundColor || 'deep-purple lighten-5', textClass]"
		>
            <div v-if="subtitle" class="overline">{{ subtitle }}</div>
            <h4 class="mb-0">{{ the_title }}</h4>
        </div>
        <v-data-table light v-if="!loading && leaderboard_data.length > 0" dense :mobile-breakpoint="2" :items-per-page.sync="rows" :headers="the_headers" :items="leaderboard_data" item-key="AgentCode" class="elevation-1 leaderboard-datatable" :hide-default-footer="false" :footer-props="{itemsPerPageOptions:[10,25,50,100, -1]}">
            <template #item="{ item, headers }">
                <tr>
                    <td v-for="header in headers" :key="header.value">
                        {{ header.value === 'index' ? item.Rank : item[header.value] }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import QLeaderboardDataTable from './QLeaderboardDataTable.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QEventsLeaderboardDataTable",
    components: {
        QLeaderboardDataTable
    },
    props: {
        'agentCode': {
            type: String,
            default: null
        },
        'type': {
            type: String,
            default: null
        },
        'timeFrame': {
            type: String,
            default: null
        },
        'title': {
            type: String,
            default: null
        },
        'subtitle': {
            type: String,
            default: null
        },
        'showAll': {
            type: Boolean,
            default: false
        },
        'rows': {
            type: Number,
            default: 25
        },
        'startDate': {
            type: String,
            default: null
        },
        'endDate': {
            type: String,
            default: null
        },
		backgroundColor: String,
		textClass: String,
    },
    data: function() { // data internal to component (not available outside)
        return {
            leaderboard_data: {},
            loading: false,
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        the_title () {
			return this.timeFrame
				? `${this.title} ${this.titleCase(this.timeFrame)}`
				: this.title
        },
        the_headers: function() {
            return [{
                    value: 'index',
                    text: '#',
                },
                {
                    text: 'Name',
                    value: 'AgentName'
                },
                {
                    text: 'Total Registrations',
                    value: 'Total'
                }
            ]
        }
    },
	watch: {
		type: 'loadData',
		agentCode: 'loadData',
		startDate: 'loadData',
		endDate: 'loadData',
	},
    methods: {
        loadData () {
            this.loading = true
            const filters = {
                startDate: this.startDate,
                endDate: this.endDate
            }
            QuilityAPI.loadEventLeaderboard(this.agentCode, this.type, filters)
                .then((json) => {
                    this.leaderboard_data = json.data
                    this.loading = false
                })
                .catch((error) => {
                    console.error(error)
                    this.loading = false
                })
        }
    }
}
</script>

<style>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}
</style>
