<template>
    <div class="q-content-searchable-date">
		<p v-if="title" class="mb-2">{{ title }}</p>
		<!-- <p v-if="description" class="mb-2 body-1 font-weight-light">{{ description }}</p> -->

        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
					v-model="mValue"
					:label="label"
					:messages="description"
					prepend-icon="fas fa-calendar-alt"
					:append-icon="mValue ? 'fas fa-times-circle' : ''"
					readonly
					v-bind="attrs"
					v-on="on"
					@click:append="mValue = undefined"
				/>
            </template>

            <v-date-picker v-model="mValue" no-title @input="menu = false" />
        </v-menu>
    </div>
</template>

<script>
export default {
    props: {
		value: { type: String },
		label: {
			type: String,
			default: 'Searchable End Date',
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: 'primary',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			menu: false,
		}
	},
    computed: {
		mValue: {
			get () { return this.value },
			set (val) {
				if (!val) {
					this.$emit('input', null)
				} else {
					this.$emit('input', val)
				}
			},
		},
    },
}
</script>
