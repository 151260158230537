<template>
    <div class="question_field mx-2">
        <slot name="meta">
            <span></span>
        </slot>
        <strong v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</strong>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>
        <v-radio-group :disabled="disableField" v-model="value.AnswerContent" :label="field_label" :rules="question_rules" :hint="question.FormQuestionConfig.hint" :prefix="question.FormQuestionConfig.prefix" :prepend-icon="question.FormQuestionConfig.prependIcon" :suffix="question.FormQuestionConfig.suffix" :append-outer-icon="question.FormQuestionConfig.appendIcon" :row="question.FormQuestionConfig.radio_options.row">
            <v-radio v-for="(item, key) in question.FormQuestionConfig.radio_options.items" :key="question.FormQuestionConfig.radio_options.label_value ? item.value : item + '_' + key" :label="question.FormQuestionConfig.radio_options.label_value ? item.text : item" :value="question.FormQuestionConfig.radio_options.label_value ? item.value : item"></v-radio>
        </v-radio-group>
        <slot></slot>
    </div>
</template>
<script>
/*
This simple text field is used asd a mix in for other form fields... add features to this with caution as it may carry down stream.
 */
import FmlFieldMixins from './FmlFieldMixins.vue'
export default {
    name: "FmlRadio",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: "String"
        }
    },
    mounted: function() {

    },
    props: {

    },
    computed: {

    },
    methods: {
        updateFieldDefaults: function() {
            this.$set(this.question.FormQuestionConfig, "radio_options", {});
            this.$set(this.question.FormQuestionConfig, "AnswerDataType", this.AnswerDataType)
            this.$set(this.question.FormQuestionConfig, "useQuestionAsLabel", true)
            this.$set(this.question.FormQuestionConfig.radio_options, "label_value", false)
            this.$set(this.question.FormQuestionConfig.radio_options, "row", false)
            this.$set(this.question.FormQuestionConfig.radio_options, "items_config", "Yes\nNo")
            this.$set(this.question.FormQuestionConfig.radio_options, "items", ["Yes", "No"])
        },

    },

    components: {

    },
    watch: {

    }
}

</script>
<style scoped>
.v-input--selection-controls {
    margin-top: 0;
}

</style>
