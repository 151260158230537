<template>
    <div color="grey lighten-4">
        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field v-model="the_date" :label="label" prepend-icon="fas fa-calendar-alt" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dd" no-title scrollable @input="save()">
            </v-date-picker>
        </v-menu>
    </div>
</template>
<script>
export default {
    props: ['value', 'label'],
    created: function() {

    },
    mounted: function() {
        if (this.value === null)
            this.value = ""
        this.the_date = new Date(this.value).toISOString().substr(0, 10);
        this.dd = new Date(this.value).toISOString().substr(0, 10);
    },
    data: function() {
        return {
            the_date: new Date().toISOString().substr(0, 10),
            dd: null,
            menu: false,
        }
    },
    components: {

    },
    methods: {
        save: function() {
            this.the_date = this.dd
            this.$emit("input", this.dd);
            this.menu = false
        }
    },
    watch: {
        'the_date': function(newV) {
            this.$emit("input", this.the_date);
        }
    }
}

</script>
