<template>
    <anchor>
        <template v-slot:meta>
            <v-col cols="6" sm="4" class="pa-6 ma-0">
                <p>Add the name of anchor you would like to add</p>
                <v-text-field v-model="value.AnchorID" label="Anchor ID"></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" class="pa-6 ma-0">
                <v-alert border="top" colored-border type="info" elevation="2">
                    <p class="body-2">To create a link to the location on the page with your anchor, copy the URL for the content page and add /#your_andchor name. </p>
                    <p class="body-2">Example:<br /> <em>If your content page is hq.quility.com/page/test-page, and the anchor you add is my_anchor, the link would be hq.quility.com/page/marketing-test-page/#my_anchor</em></p>
                </v-alert>
            </v-col>
        </template>
    </anchor>
</template>
<script>
import TextEditor from '../TextEditor.vue'
import Anchor from '@/CMS/views/ContentBlocks/Anchor.vue'
export default {
                props: ['value'],
                created: function() {
                                this.reset();
                },
                components: {
                                TextEditor,
                                Anchor
                },
                methods: {
                                reset: function() {
                                                if (typeof this.value.AnchorLink == 'undefined') {
                                                                this.$set(this.value, 'AnchorLink', "Undefined")
                                                }
                                                this.$emit('input', this.value)
                                },
                },
}
</script>