<template>
    <v-hover v-slot:default="{ hover }">
        <v-card class="q-image-square-card" color="grey lighten-4" height="100%" :elevation="hover ? 12 : 1">
            <div class="q-image-square-card__cover">
                <div class="q-image-square-card__image-container">
                    <img class="q-image-square-card__image" :src="src" @click="$emit('click')" />
                    <div class="q-image-square-card__title" v-if="title">
                        {{ title }}
                    </div>
                </div>
            </div>
            <div class="q-image-square-card__actions" v-if="hover">
                <v-btn fab small @click="togglePreview">
                    <v-icon small>fas fa-eye</v-icon>
                </v-btn>
                <slot name="actions" />
            </div>
            <v-dialog v-model="doShowPreview" class="q-image-square-card-modal">
                <v-card class="q-image-square-card-modal__card">
                    <v-card-title v-if="title" style="padding-right: 5rem;">
                        {{ title }}
                    </v-card-title>
                    <v-card-text>
                        <img :src="src" class="q-image-square-card-modal__image" />
                    </v-card-text>
                    <v-btn fab class="q-image-square-card-modal__close-button" @click="togglePreview">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card>
            </v-dialog>
        </v-card>
    </v-hover>
</template>
<script>
export default {
    props: {
        src: { type: String, required: true },
        title: { type: String },
    },

    data() {
        return {
            doShowPreview: false,
        }
    },

    methods: {
        togglePreview() {
            this.doShowPreview = !this.doShowPreview
        },
    }
}
</script>
<style lang="scss">
.q-image-square-card {
    &__cover {
        padding-bottom: 100%;
        position: relative;
    }

    &__image-container {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    &__image {
        min-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__actions {
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 1;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &>* {
            margin: 6px !important;
        }
    }

    &__title {
        position: absolute;
        bottom: 0;
        right: 0;

        max-width: 100%;
        width: 100%;

        text-align: center;
        color: white;
        font-size: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 4px;
    }
}

.q-image-square-card-modal {
    &__card {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        .v-card__text {
            display: flex;
            justify-content: center;
        }
    }

    &__close-button {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
        min-height: 300px;
    }
}
</style>