import { Model } from '@vuex-orm/core'

export class FormManagement extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'form_management'

	static primaryKey = 'ID'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/forms',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: ['EnabledInd_switch', "EditableInd_switch", "FormCategory", "FormType", "LastChangeBy"],
            availableFilters: null,
            currentPage: null,
            notice: {
                text: '',
                visible: false,
                color: 'error'
            }
        }
    }


    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
			ID: this.uid(''),
			CreateDate: this.attr(''),
			LastChangeDate: this.attr(''),
			LastChangeBy: this.attr(''),
			FormTitle: this.attr(''),
			FormCategory: this.attr(''),
			FormType: this.attr(''),
			FormContent: this.attr(''),
			FormConfig: this.attr(''),
			EffDate: this.attr(''),
			ExpDate: this.attr(''),
			DivisionID: this.attr(''),
			EnabledInd: this.attr(''),
			EditableInd: this.attr(''),
			Slug: this.attr('')
        }
    }

}

export default FormManagement;
