<!--Four column block-->
<template>
    <v-row class="mx-5 my-7" cols="12" width="100%">
        <v-row wrap>
            <v-col cols="12" sm="6" md="3" class="text-left px-4 ">
                <slot name="ColOne">
                    <div v-if="typeof contentData.ColOne === 'string'" v-html="contentData.ColOne"></div>
                    <div v-else>
                        <div v-for="(block, key) in contentData.ColOne" :key="key" :class="blockClasses(block, 'my-0')">
                            <div :ref="block.Ref" v-if="showBlock(block)" :is="block.type" :content-data="block"></div>
                        </div>
                    </div>
                </slot>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="text-left px-4 ">
                <slot name="ColTwo">
                    <div v-if="typeof contentData.ColTwo === 'string'" v-html="contentData.ColTwo"></div>
                    <div v-else>
                        <div v-for="(block, key) in contentData.ColTwo" :key="key" :class="blockClasses(block, 'my-0')">
                            <div :ref="block.Ref" v-if="showBlock(block)" :is="block.type" :content-data="block"></div>
                        </div>
                    </div>
                </slot>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="text-left px-4 ">
                <slot name="ColThree">
                    <div v-if="typeof contentData.ColThree === 'string'" v-html="contentData.ColThree"></div>
                    <div v-else>
                        <div v-for="(block, key) in contentData.ColThree" :key="key" :class="blockClasses(block, 'my-0')">
                            <div :ref="block.Ref" v-if="showBlock(block)" :is="block.type" :content-data="block"></div>
                        </div>
                    </div>
                </slot>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="text-left px-4 ">
                <slot name="ColFour">
                    <div v-if="typeof contentData.ColFour === 'string'" v-html="contentData.ColFour"></div>
                    <div v-else>
                        <div v-for="(block, key) in contentData.ColFour" :key="key" :class="blockClasses(block, 'my-0')">
                            <div :ref="block.Ref" v-if="showBlock(block)" :is="block.type" :content-data="block"></div>
                        </div>
                    </div>
                </slot>
            </v-col>
        </v-row>
    </v-row>
</template>
<script>
import Spacer from '@/CMS/views/ContentBlocks/Spacer.vue';
import CallToAction from '@/CMS/views/ContentBlocks/CallToAction.vue';
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue';
import ZendeskText from '@/CMS/views/ContentBlocks/ZendeskText.vue';
import CardStyles from '@/CMS/views/ContentBlocks/CardStyles.vue';
import ButtonGroup from '@/CMS/views/ContentBlocks/ButtonGroup.vue';
import CardGroup from '@/CMS/views/ContentBlocks/CardGroup.vue';
import WistiaVideoGroup from '@/CMS/views/ContentBlocks/WistiaVideoGroup.vue';
import IframeGroup from '@/CMS/views/ContentBlocks/IframeGroup.vue';
import Banner from '@/CMS/views/ContentBlocks/Banner.vue';
import ScriptEmbedGroup from '@/CMS/views/ContentBlocks/ScriptEmbedGroup.vue';
import CarrierContactInfo from '@/CMS/views/ContentBlocks/CarrierContactInfo.vue';
import CarrierProducts from '@/CMS/views/ContentBlocks/CarrierProducts.vue';
import CarrierLogo from '@/CMS/views/ContentBlocks/CarrierLogo.vue';
import AcknowledgeCheckBox from '@/CMS/views/ContentBlocks/AcknowledgeCheckBox.vue';
import Accordion from '@/CMS/views/ContentBlocks/Accordion.vue';
import DataTableGroup from '@/CMS/views/ContentBlocks/DataTableGroup.vue';
import SummitCalculator from "@/CMS/views/ContentBlocks/SummitCalculator";
import Leaderboard from '@/CMS/views/ContentBlocks/Leaderboard.vue';
import QForm from '@/CMS/views/ContentBlocks/Form.vue';

export default {
    name: "FourCol25252525",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    ColOne: null,
                    ColTwo: null,
                    ColThree: null,
                    ColFour: null
                }
            }
        }
    },
    components: {
        Spacer,
        CallToAction,
        FullWidthText,
        ZendeskText,
        CardStyles,
        ButtonGroup,
        CardGroup,
        WistiaVideoGroup,
        IframeGroup,
        Banner,
        ScriptEmbedGroup,
        CarrierContactInfo,
        CarrierProducts,
        CarrierLogo,
        AcknowledgeCheckBox,
        Accordion,
        DataTableGroup,
        SummitCalculator,
        Leaderboard,
        QForm
    }
}
</script>