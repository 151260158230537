<template>
    <v-sheet color="white">
        <q-product-library-agent-submenu></q-product-library-agent-submenu>
        <v-container fluid class="px-5 transparent">
            <v-row>
                <v-col cols="12" sm="3" :style="logoColumnStyle">
                    <carrier-logo v-if="carrier_id" :carrier_id="carrier_id" />
                </v-col>
                <v-col cols="12" sm="9">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>
<script>
import CarrierLogo from '@/CMS/views/ContentBlocks/CarrierLogo.vue'
import QProductLibraryAgentSubmenu from '@/components/navigation/Submenus/QProductLibraryAgentSubmenu.vue';

export default {
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    title: null,
                }
            }
        }
    },
    data: () => ({
        carrier_id: null
    }),
    components: {
        CarrierLogo,
        QProductLibraryAgentSubmenu
    },
    mounted: function() {
        this.carrier_id = this.contentData.carrier_id
    },
    updated: function() {
        this.carrier_id = this.contentData.carrier_id
    },
    computed: {
        logoColumnStyle() {
            if (!this.$vuetify.breakpoint.sm) { return '' }

            return 'border-right: 1px solid #ccc; min-height:93vh;'
        }
    }
}

</script>
