<template>
    <v-row class="mx-5 my-5" elevation="1" cols="12" width="100%">
        <slot name="meta">
            <div></div>
        </slot>
        <QSummitAccelerateCalculator v-if="contentData.CalculatorType == 'Accelerate'"></QSummitAccelerateCalculator>
        <QSummitLeadsCalculator v-else-if="contentData.CalculatorType == 'Leads'" :calculator-type="contentData.CalculatorType"></QSummitLeadsCalculator>
        <QSummitCalculator v-else :calculator-type="contentData.CalculatorType"></QSummitCalculator>
    </v-row>
</template>
<script>

import QSummitCalculator from "@/components/summit/QSummitCalculator";
import QSummitAccelerateCalculator from "@/components/summit/QSummitAccelerateCalculator";
import QSummitLeadsCalculator from "@/components/summit/QSummitLeadsCalculator";

export default {
    name: "SummitCalculator",

    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    CalculatorType: 'Income',
                }
            }
        }
    },

    methods: {

    },

    components: {
        QSummitLeadsCalculator,
        QSummitAccelerateCalculator,
        QSummitCalculator,
    }
}
</script>
