<template>
    <call-to-action :content-data="value">
        <template v-slot:headline>
            <v-text-field class="headline" v-model="value.headline" label="Headline"></v-text-field>
        </template>
        <template v-slot:text>
            <text-editor v-model="value.text"></text-editor>
        </template>
        <template v-slot:meta>
            <v-col cols="6">
                <v-text-field v-model="value.buttonText" label="Button Text"></v-text-field>
            </v-col>
            <v-col cols="6">
                <url-selector v-model="value.buttonHref" label="Button Link"></url-selector>
            </v-col>
            <v-col cols="6">
                <v-combobox label="Button Color" v-model="value.buttonColor" :items="available_colors"></v-combobox>
            </v-col>
            <v-col cols="6">
                <v-combobox label="Style" v-model="value.style" :items="available_styles"></v-combobox>
            </v-col>
        </template>
    </call-to-action>
</template>
<script>
import TextEditor from '../TextEditor.vue'
import CallToAction from '@/CMS/views/ContentBlocks/CallToAction.vue'
import Styles from '../Styles'
import UrlSelector from '../UrlSelector.vue'
export default {
    props: ['value'],
    data: function() {
        return {
            available_styles: Styles.CTAStyles,
            available_colors: Styles.Backgrounds
        }
    },
    created: function() {
        this.reset();
    },
    components: {
        TextEditor,
        CallToAction,
        UrlSelector
    },
    methods: {
        reset: function() {
            if (typeof this.value.headline == 'undefined') {
                this.$set(this.value, 'headline', "Sed nec diam non eros lobortis tempor et eget sapien.")
            }
            if (typeof this.value.style == 'undefined') {
                this.$set(this.value, 'style', "cta-green")
            }
            if (typeof this.value.buttonColor == 'undefined') {
                this.$set(this.value, 'color', "white")
            }
            if (typeof this.value.text == 'undefined') {
                this.$set(this.value, 'text', "Sed rutrum orci eget ligula mattis, vel vestibulum ligula feugiat. Cras et velit ut ipsum fringilla vulputate.")
            }
            if (typeof this.value.buttonText == 'undefined') {
                this.$set(this.value, 'buttonText', "Click Here!")
            }
            if (typeof this.value.buttonHref == 'undefined') {
                this.$set(this.value, 'buttonHref', "https://www.quility.com")
            }
            this.$emit('input', this.value)
        },
    },
    watch: {
        'value.type': function(newV) {
            var g = this;
            this.$nextTick(g.reset())
        },
    }
}

</script>
