export default {
    updateDivisionCarrierProduct(product) {
        return this.putRequest('/api/private/products/' + product.ID + '/divisions', product).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    }
}
