import { Model } from '@vuex-orm/core'

export default class Category extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'category'

    static primaryKey = 'id'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/category',
            per_page: null,
            total: null,
            filter_keys: ['zendesk_category_id'],
            availableFilters: null,
            currentCategory: null,
            notice: {
                text: '',
                visible: false,
                color: 'error'
            }
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.uid(),
            category: this.attr(''),
            description: this.attr(''),
            zendesk_category_id: this.attr(0),
			parent_category_id: this.attr(0),
            order: this.attr(0),
        }
    }

    /**
     * Get full name of the user.
     */
    get dropdown_category_name() {
        if (this.zendesk_category_id > 0) {
            return `${this.category}  (Zendesk)`
        }
        return `${this.category}`
    }
}
