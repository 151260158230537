<template>
    <div :height="cardHeight" class="leaderboard">
        <div
			class="pt-4 pb-4 pl-4"
			:class="[backgroundColor || 'deep-purple lighten-5', textClass]"
		>
            <div v-if="subtitle" class="overline">{{ subtitle }}</div>
            <h4 class="mb-0">{{ title }}</h4>
        </div>

        <p v-if="leaderboardData.length > 0 && (startDate || endDate)" class="ref-value mb-0">{{ formatDateWithDay(startDate) }} to {{ formatDateWithDay(endDate) }}</p>

        <v-row class="px-4 py-2" no-gutters>
            <v-col
				v-if="!isMobile && showAll && leaderboardData.length > 0"
				id="q-leaderboard-step-2"
				cols="12" sm="8" class="text-left"
			>
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-col>
            <v-spacer v-else></v-spacer>
            <v-col id="q-leaderboard-step-3" cols="12" sm="4">
                <v-text-field
					v-if="showAll && leaderboardData.length > 0"
					v-model="search"
					:disabled="isDemoMode"
					append-icon="icon-q-search"
					label="Search"
					class="pa-0 ma-0"
					color="q_leads_4"
					single-line hide-details
				/>
            </v-col>
        </v-row>

        <v-data-table
			v-if="leaderboardData.length > 0"
			:items="leaderboardData"
			:headers="the_headers"
			:search="search"
			:item-key="itemKey"
			:items-per-page.sync="computedRows"
			:mobile-breakpoint="2"
			:hide-default-footer="!showAll"
			:footer-props="{ itemsPerPageOptions: [5, 10, 20, 50, 'All'] }"
			light dense
			class="leaderboard-datatable"
		>
			<template v-for="h in the_headers" v-slot:[`item.${h.value}`]="{ item, value }">
				<slot :name="`item.${h.value}`" v-bind="{ item, value, header: h }">
					<template v-if="h.value === 'Rank'">
						<span :key="h.value"  class="nowrap"><strong>#{{ item.RankByLeadershipLevel ? item.RankByLeadershipLevel : item.Rank }}</strong></span>
					</template>
					<template v-else-if="h.value === 'AgentName'">
						<span :key="h.value">
							<span :class="item.AgentCode == user.AgentCode ? 'agent-strong' : ''">
								<span v-if="showAll">
									<router-link class="q_leads_4--text" :to="'/agents/' + item.AgentCode">{{ getAgentPreferredName(item) }}</router-link>
								</span>
								<span v-else>{{ getAgentPreferredName(item) }}</span>
							</span>
							<v-img v-if="item.CurrentSummitBadge" :src="item.CurrentSummitBadge.micro" class="summit-badge"></v-img>
							<v-img v-if="['regular', 'golden'].includes(item.SlingshotBadges)" :src="slingshotBadgeUrl(item.SlingshotBadges)" class="summit-badge"></v-img>
						</span>
					</template>
					<template v-else-if="h.type === 'number'">
						<span :key="h.value"  :class="item.AgentCode === user.AgentCode ? 'agent-strong nowrap' : 'nowrap'">{{ Math.round(item[h.value]) }}</span>
					</template>
					<template v-else-if="h.type === 'currency'">
						<span :key="h.value"  class="nowrap"><strong>{{ formatCurrency(item[h.value]) }}</strong></span>
					</template>
					<template v-else>
						<span :key="h.value"  class="nowrap">{{ item[h.value] }}</span>
					</template>
				</slot>
			</template>

            <template v-slot:body.append>
				<slot name="body.append">
					<tr v-if="agentPosition && agentPosition.Rank > 0" class="agent-row">
						<template v-for="h in the_headers">
							<td :key="h.value" v-if="h.value === 'Rank'">#{{ agentPosition.Rank }}</td>
							<td :key="h.value" v-else-if="h.value === 'AgentName'" class="text-left">
								{{ getAgentPreferredName(agentPosition) }}
								<v-img v-if="agentPosition.CurrentSummitBadge" :src="agentPosition.CurrentSummitBadge.micro" class="summit-badge"></v-img>
								<v-img v-if="['regular', 'golden'].includes(agentPosition.SlingshotBadges)" :src="slingshotBadgeUrl(agentPosition.SlingshotBadges)" class="summit-badge"></v-img>
							</td>
							<td :key="h.value" v-else-if="h.type === 'currency'" class="text-right">{{ formatCurrency(agentPosition.APV) }}</td>
							<td :key="h.value" v-else>{{ agentPosition[h.value] }}</td>
						</template>
					</tr>
				</slot>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { Parser } from 'json2csv'

const headersConfig = [
	{ text: 'Rank', value: 'Rank', width: 70, mobileWidth: 0, align: 'center', class: 'rank-header' },
	{ text: 'Name', value: 'AgentName', width: 150, mobileWidth: 'max-content', class: 'header-class' },
	{ text: 'Apps', value: 'Apps', width: 100, mobileWidth: 0, align: 'center', type: 'number', onMobile: false },
	{ text: 'APV', value: 'APV', width: 150, mobileWidth: 0, align: 'right', type: 'currency' },
]

export default {
	name: 'QLeaderboardDataTable',
    props: {
        title: {
            type: String,
            default: 'Leaderboard',
        },
        subtitle: {
            type: String,
        },
        itemKey: {
            type: String,
            default: 'AgentCode',
        },
        rows: {
            type: Number,
            default: 10,
        },
        moreLink: {
            type: String,
        },
        leaderboardData: {
            type: Array,
            default: () => [],
        },
        exportFields: {
            type: Array,
            default: () => ['Rank', 'AgentName', 'Apps', 'APV'],
        },
        headersConfig: {
            type: Array,
			default: () => [...headersConfig]
        },
        agentPosition: {
            type: Object,
        },
        startDate: {
            type: String,
        },
        endDate: {
            type: String,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
		backgroundColor: String,
		textClass: String,
    },
    data () {
        return {
            search: null,
        }
    },
    computed: {
		computedRows: {
			get () { return this.rows },
			set (val) { this.$emit('update:rows', val) },
		},
        isMobile () {
            return this.$vuetify.breakpoint.xs
        },
        the_headers () {
			return this.headersConfig
				.map((item) => {
					const { mobileWidth, width, onMobile, ...rest } = item
					if (typeof onMobile === 'boolean' && !onMobile && this.isMobile) { return undefined }
					return {
						...rest,
						width: this.isMobile ? mobileWidth : width,
					}
				})
				.filter(Boolean)
        },
        cardHeight () {
            if (this.isMobile) { return null }
            return 257 + (this.rows * 56) + 'px'
        },
    },
    methods: {
        exportResults () {
            const opts = { fields: this.exportFields }
            try {
                const parser = new Parser(opts)
                const csv = parser.parse(this.leaderboardData)
                const fileURL = window.URL.createObjectURL(new Blob([csv]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = this.slugify(this.title + ' ' + this.endDate)
                fileLink.setAttribute('download', fileName + '.csv')
                document.body.appendChild(fileLink)
                fileLink.click()
                this.logActivity('ExportLeaderboard', { leaderboard: this.title })
            } catch (err) {
                console.error(err)
            }
        },
        getAgentPreferredName (item) {
            return item.PreferredName || item.PreferredAgentName || item.AgentName
        },
    },
}
</script>

<style>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}

.agent-strong {
    font-weight: 900;
}

.rank-header {
    white-space: nowrap;
}
</style>

<style scoped>
.summit-badge {
	width: 1.5rem;
	margin-left: 0.3rem;
	float: right;
}
</style>
