export const namedExtensions = {
	Audio: ['mp3', 'wav', 'ogg'],
	Video: ['mp4', 'webm', 'avi', 'mov', 'flv', 'wmv', 'mkv'],
	Image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'],
	Document: ['doc', 'docx'],
	Excel: ['xls', 'xlsx'],
	Presentation: ['ppt', 'pptx'],
	PDF: ['pdf'],
	Text: ['txt'],
	Code: ['csv'],
	Archive: ['zip'],
}

export const originalMIMETypes = {
	'audio/*': namedExtensions.Audio,
	'video/*': namedExtensions.Video,
	'image/*': namedExtensions.Image,
}

export const MIMEType = {
	// Virtual MIME type, not real MIME type
	'doc/*': namedExtensions.Document,
	'excel/*': namedExtensions.Excel,
	'ppt/*': namedExtensions.Presentation,
	'application/vnd.ms-powerpoint': ['ppt'],
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['pptx'],
	'pdf/*': namedExtensions.PDF,
	'text/*': namedExtensions.Text,
	'code/*': namedExtensions.Code,
	'archive/*': namedExtensions.Archive,
	...originalMIMETypes,
}
