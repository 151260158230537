<template>
    <span>
        <v-icon v-if="state === '1'" @click="toggle('0')" color="success">
            fas fa-check
        </v-icon>
        <v-icon v-else-if="state === '0'" @click="toggle('')" color="red">
            fas fa-times
        </v-icon>
        <v-icon v-else @click="toggle('1')">
            far fa-square
        </v-icon>
    </span>
</template>
<script>
export default {
    name: "QTriStateToggle",
    props: ['value'], // data passed to component from parent
    computed: {
        state: function() {
            return this.value
        }
    },
    methods: {
        toggle(value) {
            this.$emit('input', value);
        },
    }
}
</script>
