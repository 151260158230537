<script>
/*
This mixin is used asd a mix in for other form fields... add features to this with caution as it may carry down stream.
 */
export default {
    data: function() {
        return {
            AnswerDataType: 'String'
        }
    },
    mounted: function() {
        this.updateAnswerDataType();
    },
    props: {
        agentCode: {
            type: String,
            default: null
        },
        value: {
            type: Object,
            default: function() {
                return {
                    FormResponseID: null,
                    FormQuestionID: null,
                    AnswerDataType: "String", //needs to match AnswerDataType Above!!!!
                    AnswerContent: null
                }
            },
        },
        question: {
            type: Object,
            default: function() {
                return {
                    ID: "new",
                    key: null,
                    //this will hold the response answer if being used in the view and within form question set.
                    FormResponseAnswer: {
                        FormResponseID: null,
                        FormQuestionID: null,
                        AnswerDataType: "String",
                        AnswerContent: null
                    },
                    FormQuestionTitle: "My First Question?",
                    FormQuestionContent: null,
                    FormQuestionConfig: {
                        type: "text-field",
                        label: null,
                        AnswerDataType: "String",
                        rules: [],
                        cols: 12,
                        smCols: null,
                        mdCols: null,
                        lgCols: null,
                        label: 'Answer',
                        hint: null,
                        prefix: null,
                        prependIcon: null,
                        default: null,
                        permissions: {},
                        useQuestionAsLabel: true
                    },
                }
            }
        },
        disableField: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        'question_rules': function() {
            var rules = [];
            var g = this;
            this.question.FormQuestionConfig.rules.forEach(function(rule) {
                if (typeof g.form_rules[rule] != 'undefined') {
                    rules.push(g.form_rules[rule]);
                }
            })
            return rules
        },
        'field_label': function() {
            var str = this.question.FormQuestionConfig.useQuestionAsLabel ? this.question.FormQuestionTitle : this.question.FormQuestionConfig.label;
            if (this.question.FormQuestionConfig.rules.indexOf("required") > -1) {
                str = str + "*";
            }
            return str
        },
        answerContent() {
            return this.value.AnswerContent
        }
    },
    methods: {
        updateAnswerDataType: function() {
            this.question.FormQuestionConfig.AnswerDataType = this.question.FormQuestionConfig.formatAsCurrency ? 'Currency' : this.AnswerDataType
            if (typeof this.question.FormResponseAnswer != "undefined") {
                this.question.FormResponseAnswer.AnswerDataType = this.question.FormQuestionConfig.formatAsCurrency ? 'Currency' : this.AnswerDataType
            }
        },
        updateFieldDefaults: function() {
            this.question.FormQuestionConfig.AnswerDataType = this.AnswerDataType
            this.question.FormQuestionConfig.useQuestionAsLabel = true;
        },
		checkNullish (answerContent) {
			if (answerContent === null) { return true }
			if (Array.isArray(answerContent) && !answerContent.length) { return true }
			return false
		},
    },

    components: {

    },
    watch: {
        'question.FormResponseAnswer': function() {
            this.$nextTick(updateAnswerDataType)
        },
        answerContent(newValue, oldValue) {
			if (this.checkNullish(newValue) && this.checkNullish(oldValue)) { return }
            if (newValue != oldValue) {
                this.$emit('value-changed', newValue, oldValue)
            }
        },
    }
}
</script>