import QuilityAPI from '@/store/API/QuilityAPI.js'
import User from '@/store/Models/User'
import { getInstance } from "@/auth/index";

export const ga_page_view = (method, params) => {
    //we need to get some custom dimensions to pass to ga... role, division and leadership level. 
    //grab these values to pass to ga. 
    //Also update the ga function in QuilityAPI to update the events for the API. 
    var user = User.query().first();
    var auth = getInstance();
    if (typeof user != 'undefined' && user != null) {
        var leadership_level = user.Agent.LeadershipLevel
        if (user.currentSetDivision != null) {
            var division = user.currentSetDivision;
        } else if (user.permissions.length > 0) {
            var d = [];
            for (var i = 0; i < user.permissions.length; i++) {
                if (user.permissions[i].indexOf("division:") > -1) {
                    d.push(user.permissions[i].replace('division:', ''))
                }
            }
            var division = d[0];
        }
    } else {
        var leadership_level = 'Undefined'
        var division = 'Undefined'
    }
    if (auth.isAuthenticated) {
        if (typeof auth.auth_user["http://quility.com/roles"] == "undefined") {
            var role = null
        }
        var role = auth.auth_user["http://quility.com/roles"][0];
    }
    //gtag('config', 'UA-60122662-7', {
    //    'send_page_view': false, //to.path,
    //});
    gtag('config', 'UA-60122662-7', {
        'send_page_view': false, //to.path,
        'custom_map': {
            'dimension1': 'division',
            'dimension2': 'leadership_level',
            'dimension3': 'role',
        }
    });
    gtag('set', {
        'division': division,
        'leadership_level': leadership_level,
        'role': role
    })
    gtag('event', method, {
        ...params,
        send_to: 'UA-60122662-7',
    })
};
