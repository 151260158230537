<template>
    <div class="q-business-time-period-select" :class="alignLeft && 'justify-space-between'">
        <div v-if="!hideTimePeriodSwitch && !weeksOnly" class="q-business-time-period-select__switch">
            <v-switch class="mt-0 pt-0" :disabled="disabled" :dark="dark" hide-details inset v-model="monthlyperiod" :label="date_period" :color="color" :dense="denseSwitch" />
        </div>
        <v-select class="q-business-time-period-select__select" :disabled="disabled" :dark="dark" hide-details :label="label" :items="available_weeks" v-model="the_date" :rules="rules" item-text="label" return-object prepend-icon="fas fa-calendar-alt" :color="color" :menu-props="{ contentClass: computedContentClass }" />
    </div>
</template>

<script>
// import moment from 'moment'
// import GlobalSessionData from '@/store/Models/GlobalSessionData'

export default {
    name: "QBusinessTimePeriodSelect",
  	props: {
      color: { type: String },
	  value: {} ,
	  label: {} ,
	  rules: {} ,
	  withAll: {} ,
	  allowCurrent: {} ,
	  allowNextWeek: {} ,
	  stateKey: {} ,
	  dark: {} ,
	  disabled: {} ,
	  weeksOnly: { type: Boolean, default: false } ,
	  alignLeft: {} ,
	  dontReset: {},
	  earliestDate: {} ,
	  hideTimePeriodSwitch: { type: Boolean, default: false } ,
	  hideAllOption: { type: Boolean },
	  calendarDates: { type: Boolean, default: false },
	  allowYTD: { type: Boolean, default: false },
	  denseSwitch: { type: Boolean, default: false },
	  contentClass: { type: String, default: '' },
	},
    data: function() {
        return {
            the_date: this.value,
            monthlyperiod: true,
            stat_period: "months",
        }
    },
    mounted: function() {
        if (this.weeksOnly) {
            this.stat_period = "weeks"
        }
        if (this.dontReset !== false) {
            this.$nextTick(this.resetSelection)
        }
    },
    computed: {
        date_period () {
            return this.stat_period == 'months' ? 'Monthly' : 'Weekly'
        },
        //available_weeks is part of the mixins...
        available_weeks () {
            const getDates = this.calendarDates
                ? this.getAvailableCalendarDates
                : this.getAvailableBusinessDates

            const options = getDates(this.stat_period, this.allowNextWeek, this.allowCurrent, this.earliestDate)
			if (this.calendarDates && this.allowYTD) {
				options.unshift({ label: 'YTD', endDateFormat: null, startDateFormat: null, period: 'month' })
			}
            if (!this.hideAllOption) {
                options.unshift({ label: '-All-', endDateFormat: null, startDateFormat: null, period: 'month' })
            }
            return options
        },
        computedContentClass () {
            return ['q-business-time-period-select__menu', this.contentClass].filter(Boolean).join(' ')
        },
    },
    methods: {
        updateValue: function(v, emit) {
            this.the_date = v;
            if (emit === true) {
                this.$emit('input', v);
            }
        },
        resetSelection() {
            this.the_date = this.available_weeks[0];
        },
        selectOption(endDate, timePeriod) {
            if (endDate == null){
                this.updateValue(null)
            }
            this.stat_period = timePeriod == 'month' ? "months" : "weeks";
            this.monthlyperiod = timePeriod == 'month';
            const selectedOption = this.available_weeks.find((week) => week.endDateFormat == endDate && week.period == timePeriod)
            this.updateValue(selectedOption)
        }
    },
    watch: {
        'the_date': function(v) {
            this.updateValue(v, true);
        },
        'value': function(v) {
            this.updateValue(v, false);
        },
        'monthlyperiod': function(v) {
            if (v) {
                this.stat_period = "months"
            } else {
                this.stat_period = 'weeks'
            }
            this.$nextTick(this.resetSelection)
        }
    }
}
</script>

<style lang="scss">
.q-business-time-period-select {
    display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: -0.5rem;
	word-break: normal;
}

.q-business-time-period-select__switch {
    display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 1;
	margin: 0.5rem;
}

.q-business-time-period-select__select {
	margin: 0.5rem !important;
	flex-grow: 1;
}
</style>
