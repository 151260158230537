export default {

	// agent_code 	= 'SFG0000001'		(String)
	// filters 		= ...filters		(Spread operator)
	// options		= ...options		(Spread operator)
	// url			= '/location'		(String)
	// query		= 'QAgentStats'		(String)
    getConservedPoliciesStats(agent_code, filters, options) {
        this.cancelQRequest("QAgentConservedPoliciesStats");
        let params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/stats/agent/' + agent_code + '/conserved_policies', params, 'QAgentConservedPoliciesStats').then((json) => {
                return json
            }).catch((err) => {
				return err
			})
    },
    getSubmittedStats(agent_code, filters, options) {
        this.cancelQRequest("QAgentSubmittedStats");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/stats/agent/' + agent_code + '/submitted', params, "QAgentSubmittedStats").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getUniqueWriterStats(agent_code, filters, options) {
        this.cancelQRequest("getUniqueWriterStats");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/stats/agent/' + agent_code + '/unique_writers', params, "getUniqueWriterStats").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getCloseRatioStats(agent_code, filters, options) {
        this.cancelQRequest("QAgentCloseRatioStats");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/stats/agent/' + agent_code + '/close_ratio', params, "QAgentCloseRatioStats").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getPlacedStats(agent_code, filters, options) {
        this.cancelQRequest("QAgentPlacedStats");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/stats/agent/' + agent_code + '/placed', params, "QAgentPlacedStats").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getStatRangeCombined(agent_code, filters, options) {
        this.cancelQRequest("QAgentStatRangeCombined");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/stats/agent/' + agent_code + '/range_combined', params, "QAgentStatRangeCombined").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getStatRangePromotion(agent_code, filters, options) {
        this.cancelQRequest("QAgentStatRangePromotion");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/stats/agent/' + agent_code + '/range_combined', params, "QAgentStatRangePromotion").then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    saveScoreCardGoal(agent_code, stat_endpoint, value) {
        var params = {
            "name": stat_endpoint,
            "value": value
        }
        return this.postRequest('/api/private/scorecards/goals/' + agent_code, params, null).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getCsvData(url, params) {
        return this.postRequest(url, params).then(function(json){
            return json
        },
        function(error) {
            return error
        })
    }
}
