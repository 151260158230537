<!--Three column block-->
<template>
    <v-row class="mx-5 my-7" cols="12" width="100%">
        <slot name="meta">
            <div></div>
        </slot>
        <v-row cols="12" style="min-width:100%">
            <!-- we should fix the 'style' here -->
            <h3 v-if="contentData.GroupTitle" class="q-title">{{contentData.GroupTitle}}</h3>
        </v-row>
        <v-row wrap class="pb-8" justify="center">
            <slot name="Buttons">
                <v-col v-for="(button, key) in contentData.Buttons" :key="key" cols="" :sm="getSmallColumns" :md="getMediumColumns" class="text-left">
                    <v-btn class="q-multi-line-btn" dark :color="button.color" block x-large @click="clicked(button.href, button.newWindow)">
                        <strong>{{button.text}}</strong>
                    </v-btn>
                </v-col>
            </slot>
        </v-row>
    </v-row>
</template>
<script>
import Card from './Elements/Card.vue'
export default {
    name: "CardSyles",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    Buttons: [
                        {}
                    ],
                    GroupTitle: null,
                    NumColumns: 4
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.contentData.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.contentData.NumColumns);
        }
    },
    methods: {
        clicked: function(href, newWindow) {
            if (href.indexOf('http') > -1) {
                if (href.indexOf('quility.com') > -1 && newWindow != "_blank") {
                    document.location = href
                } else {
                    window.open(href, newWindow);
                }
            } else {
                this.$router.push(href)
            }
        }
    },
    components: {
        Card
    }
}
</script>
<style lang="scss">
.q-multi-line-btn {
    // Multi line buttons are bad, but we use it here for mobile.

    .v-btn__content {
        flex-shrink: 1;
        white-space: normal
    }
}
</style>