<template>
    <three-col-255025>
        <template v-slot:ColOne>
            <q-edit-page-blocks v-model="value.ColOne"></q-edit-page-blocks>
        </template>
        <template v-slot:ColTwo>
            <q-edit-page-blocks v-model="value.ColTwo"></q-edit-page-blocks>
        </template>
        <template v-slot:ColThree>
            <q-edit-page-blocks v-model="value.ColThree"></q-edit-page-blocks>
        </template>
    </three-col-255025>
</template>
<script>
export default {
    props: ['value'],
    created: function() {
        this.reset();
    },
    methods: {
        reset: function() {
            if (typeof this.value.ColOne == 'undefined') {
                this.$set(this.value, 'ColOne', this.defaultCmsColumnSubBlocks())
            }
            if (typeof this.value.ColTwo == 'undefined') {
                this.$set(this.value, 'ColTwo', this.defaultCmsColumnSubBlocks())
            }
            if (typeof this.value.ColThree == 'undefined') {
                this.$set(this.value, 'ColThree', this.defaultCmsColumnSubBlocks())
            }
            this.$emit('input', this.value)
        },
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
