import { Model } from '@vuex-orm/core'

export default class AvailableLead extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'available_lead'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/leads/available',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: ['State', 'County', 'LeadType', 'LeadLevel'],
            availableFilters: null,
            currentAvailableLead: {}
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            LeadID: this.attr(null),
            CODE: this.attr(''),
            LeadType: this.attr(''),
            LeadLevel: this.attr(''),
            City: this.attr(''),
            County: this.attr(''),
            State: this.attr(''),
            Zip: this.attr(''),
        }
    }

}
