import { Model } from '@vuex-orm/core'

export default class AgentProfile extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'agent_profile'

    static state() {
        return {
            loading: false,
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.uid(null),
            AgentCode: this.attr(''),
            DisplayName: this.attr(''),
            ListOrder: this.attr(''),
            Titles: this.attr([]),
            Section: this.attr(''),
            ProfilePhoto: this.attr(''),
            Division: this.attr(''),
            Title: this.attr(''),
            Email: this.attr(''),
            Bio: this.attr(''),
            file: this.attr('')
        }
    }

}
