<template>
    <Leaderboard :content-data="value" :editing="true">
		<template #meta>
			<v-row class="my-8">
				<v-col cols="12" lg="4">
					<v-combobox label="Leaderboard Type" v-model="value.leaderboard_type" :items="leaderboard_types" clearable />
				</v-col>
				<v-col cols="12" lg="4">
					<v-text-field label="Leaderboard Title (optional)" v-model="value.Title" />
				</v-col>
				<v-col cols="12" sm="6" lg="2">
					<v-combobox label="Background Color" v-model="value.background_color" :items="available_colors" clearable />
				</v-col>
				<v-col cols="12" sm="6" lg="2">
					<v-combobox label="Text Class" v-model="value.text_class" :items="available_classes" clearable />
				</v-col>
			</v-row>
		</template>
    </Leaderboard>
</template>

<script>
import Leaderboard, { leaderboardItems } from '@/CMS/views/ContentBlocks/Leaderboard.vue'
import Styles from '../Styles'

export default {
	name: 'LeaderboardEditor',
    components: {
		Leaderboard,
    },
    props: {
		value: {
            type: Object,
            default () {
                return {
                    background_color: null,
                    text_class: null,
                    leaderboard_type: null,
                    Title: ''
                }
            }
        },
	},
    data () {
        return {
			leaderboard_types: leaderboardItems.map(({ label }) => label),
            available_colors: Styles.Backgrounds,
            available_classes: Styles.Text,
        }
    },
}
</script>
