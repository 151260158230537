<template>
    <v-card tile height="100%" class="pa-5">
        <v-img v-if="card.image" :aspect-ratio="16/9" height="auto" width="100%" :src="card.image" contain></v-img>
        <v-card-title v-if="card.title">
            {{ card.title }}
        </v-card-title>
        <v-card-text v-if="card.text">
            <div v-html="card.text"></div>
        </v-card-text>
        <v-card-actions v-if="card.copyToClipboard || card.buttonText" class="pt-8">
            <div :style="computedButtonsAligment">
                <v-btn depressed color="q_green_2" dark v-if="card.buttonText" @click="clicked(card.buttonHref, card.newWindow) ">
                    <strong>{{ card.buttonText }}</strong>
                </v-btn>
                <v-btn text small class="ml-2" v-if="card.copyToClipboard" v-clipboard:copy="card.copyToClipboard" v-clipboard:success="handleCopyStatus">
                    {{ card.copyText }}
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "CardSyles",
    //when setting props
    props: {
        card: {
            type: Object,
            default: function() {
                return {
                    title: null,
                    text: null,
                    buttonText: null,
                    buttonHref: null,
                    newWindow: null,
                    image: null,
                    copyText: null,
                    copySucceeded: null,
                    copyToClipboard: null
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {
        computedButtonsAligment() {
            return `position:absolute; left:0; bottom:0; padding:20px; text-align: ${this.card.buttonAlignment}; width:100%;`;
        },
    },
    methods: {
        clicked: function(href, newWindow) {
            if (href.indexOf('http') > -1) {
                if (href.indexOf('quility.com') > -1 && newWindow != "_blank") {
                    document.location = href
                } else {
                    window.open(href, newWindow);
                }
            } else {
                this.$router.push(href)
            }
        },
        handleCopyStatus(status) {
            this.showInfo("Copied to clipboard")
        },
        handleCopyError(status) {
            this.showError("Opps, something went wrong. Select the deisred text and use CTRL+C to copy.")
        }

    }
}
</script>
