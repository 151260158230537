<template>
    <data-table-group>
        <template v-slot:meta>
            <v-col cols="12">
                <v-text-field v-model="value.Title" label="Title"></v-text-field>
            </v-col>
            <v-col cols="1">
                <v-text-field v-model="value.NumColumns" label="Number of Columns"></v-text-field>
            </v-col>
        </template>
        <template v-slot:DataTables>
            <draggable tag="v-row" class="px-4" v-model="value.DataTables" handle=".handle">
                <v-col v-for="(datatable, key) in value.DataTables" :key="datatable.id" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="text-left">
                    <v-hover v-slot:default="{ hover }">
                        <v-card>
                            <v-card-text>
                                <v-text-field class="mt-5" label="Title" v-model="datatable.title"></v-text-field>
                                <url-selector v-model="datatable.csvUrl" label="CSV File" :relative_path="true"></url-selector>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field label="Rank on Column Name" v-model="datatable.rankOnColumnName"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select label="Rank Sort Direction" :items="['Ascending', 'Descending']" v-model="datatable.rankSortDirection" />
                                    </v-col>
                                </v-row>
                                <v-checkbox class="mt-0" v-model="datatable.filterOnAgentCode" label="filter on Agent Code" false-value="0" true-value="1"></v-checkbox>
                            </v-card-text>
                            <v-btn class="handle" v-if="hover" dark color="gray" small fab absolute top left>
                                <v-icon small>fas fa-arrows-alt</v-icon>
                            </v-btn>
                            <v-btn @click="deleteDataTable(key)" v-if="hover" fab small absolute top right color="red">
                                <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                        </v-card>
                    </v-hover>
                </v-col>
            </draggable>
            <v-col cols="1">
                <v-btn large color="primary" @click="addDataTable">Add</v-btn>
            </v-col>
        </template>
    </data-table-group>
</template>
<script>
import draggable from "vuedraggable";
import TextEditor from '../TextEditor.vue'
import ImageSelector from '../ImageSelector.vue'
import UrlSelector from '../UrlSelector.vue'
import DataTableGroup from '@/CMS/views/ContentBlocks/DataTableGroup.vue'
import Styles from '../Styles'
export default {
    props: ['value'],
    data: function() {
        return {
            available_colors: Styles.Backgrounds,
            counter: Math.floor(Math.random() * 1000000000),
            button_alignments: [{
                    text: "Left",
                    value: "left"
                },
                {
                    text: "Center",
                    value: "center"
                },
                {
                    text: "Right",
                    value: "right"
                }


            ]
        }
    },
    created: function() {
        this.reset();
    },
	mounted() {
	},
    components: {
        draggable,
        TextEditor,
        DataTableGroup,
        UrlSelector,
        ImageSelector
    },
    methods: {
        reset: function() {
            if (typeof this.value.DataTables == 'undefined') {
                this.counter = this.counter + 1
                this.$set(this.value, 'DataTables', [{
                    id: this.counter,
                    title: "",
                    csvUrl: "",
                }])
            }
            if (typeof this.value.NumColumns == 'undefined') {
                this.$set(this.value, 'NumColumns', 1)
            }
            this.$emit('input', this.value)
        },
        addDataTable: function() {
            this.counter = this.counter + 1
            this.value.DataTables.push({
                id: this.counter,
                title: "",
                csvUrl: "",
            })
        },
        deleteDataTable: function(idx) {
            var g = this;
            this.$nextTick(function() {
                g.$delete(this.value.DataTables, idx)
            })
        },
    },
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.value.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.value.NumColumns);
        }
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
