<template>
    <div>
		<slot name="meta" />

		<div class="site-width">
			<q-quility-rx-leaderboards
				v-if="showRxLeaderboard"
				:board="contentData.leaderboard_type"
				:title="leaderboardTitle"
				:type="rxLeaderboardType"
				:backgroundColor="contentData.background_color"
				:textClass="contentData.text_class"
			/>

			<q-events-leaderboard-data-table
				v-else-if="showEventsLeaderboard"
				:type="eventsLeaderboardType"
				:agent-code="user.AgentCode"
				:title="leaderboardTitle"
				:backgroundColor="contentData.background_color"
				:textClass="contentData.text_class"
				:rows="10"
			/>

			<div v-else-if="loading || type">
				<v-progress-linear v-if="loading" indeterminate color="primary" />
				<q-leaderboard-data-table
					v-else-if="type"
					:title="leaderboardTitle"
					:leaderboard-data="leaderboardData"
					:agentPosition="agentPositions.PersonalProducer"
					:start-date="startDate"
					:end-date="endDate"
					:backgroundColor="contentData.background_color"
					:textClass="contentData.text_class"
					:rows="10"
				/>
			</div>
		</div>
    </div>
</template>

<script>
import QLeaderboardDataTable from '@/components/datatables/QLeaderboardDataTable.vue'
import QQuilityRxLeaderboards from '@/components/datatables/QQuilityRxLeaderboards.vue'
import QEventsLeaderboardDataTable from '@/components/datatables/QEventsLeaderboardDataTable.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export const leaderboardItems = [
	{ lbType: 'top10', label: 'Personal Producer', title: 'Top 10 Personal Producers', lbData: 'PersonalProducer'},
	{ lbType: 'top10', label: 'Agency Director', title: 'Top 10 Agency Directors', lbData: 'AgencyDirector'},
	{ lbType: 'top10', label: 'Agency Owner', title: 'Top 10 Agency Owners', lbData: 'AgencyOwner'},
	{ lbType: 'top10', label: 'Key Leader', title: 'Top 10 Key Leaders', lbData: 'KeyLeader'},
	{ lbType: 'rx', label: 'Quility Rx - Personal Producer Used Codes', title: '', type: 'personal'},
	{ lbType: 'rx', label: 'Quility Rx - Personal Producer Total Savings', title: '', type: 'personal'},
	{ lbType: 'rx', label: 'Quility Rx - Personal Producer Compensable', title: '', type: 'personal'},
	{ lbType: 'rx', label: 'Quility Rx - Baseshop Used Codes', title: '', type: 'baseshop'},
	{ lbType: 'rx', label: 'Quility Rx - Baseshop Total Savings', title: '', type: 'baseshop'},
	{ lbType: 'rx', label: 'Quility Rx - Baseshop Compensable', title: '', type: 'baseshop'},
	{ lbType: 'events', label: 'SFG Direct Conference Registration', title: 'Overall Direct Agency Promoter', type: 'overalldirect'},
	{ lbType: 'events', label: 'Baseshop Conference Registration', title: 'Overall Baseshop Promoter', type: 'overallbaseshop'},
	{ lbType: 'events', label: 'Agency Director Conference Registration', title: 'Agency Director Promoter', type: 'agencydirector'},
	{ lbType: 'events', label: 'Agency Owner Conference Registration', title: 'Agency Owner Promoter', type: 'agencyowner'},
	{ lbType: 'events', label: 'Key Leader Conference Registration', title: 'Key Leader Promoter', type: 'keyleader'},
	{ lbType: 'events', label: 'Team Leader Conference Registration', title: 'Team Leader Promoter', type: 'teamleader'},
	{ lbType: 'events', label: 'Enfluencer’s Qualification', title: 'Enfluencer’s Qualification', type: 'leadersreception'},
]

export default {
    name: "Leaderboard",
    components: {
        QLeaderboardDataTable,
        QQuilityRxLeaderboards,
		QEventsLeaderboardDataTable,
    },
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    background_color: null,
                    text_class: null,
                    leaderboard_type: null,
                    Title: ''
                }
            }
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            leaderboard_data: {
                PersonalProducer: [],
                AgencyDirector: [],
                AgencyOwner: [],
                KeyLeader: []
            },
            agentPositions: {
                PersonalProducer: {},
                AgencyDirector: {},
                AgencyOwner: {},
                KeyLeader: {}
            },
            startDate: null,
            endDate: null,
            loading: false
        }
    },
    computed: {
		type () {
			return this.contentData.leaderboard_type
		},
		item () {
			return leaderboardItems.find(({ label }) => label === this.type)
		},
        showRxLeaderboard () {
            return this.item?.lbType === 'rx' || false
        },
        rxLeaderboardType () {
			if (!this.showRxLeaderboard) return
            return this.item.type
        },
        showEventsLeaderboard () {
			return this.item?.lbType === 'events' || false
        },
		eventsLeaderboardType () {
			if (!this.showEventsLeaderboard) return
			return this.item.type
		},
        leaderboardTitle () {
            if (this.contentData.Title) return this.contentData.Title
			return this.item?.title
        },
        leaderboardData () {
            // 'Personal Producer', 'Agency Director', 'AgencyOwner', 'Key Leader'
			if (this.showRxLeaderboard || this.showEventsLeaderboard) return
			return this.leaderboard_data[this.item.lbData]
        },
    },
    watch: {
		type: {
			handler (newVal) {
				if (newVal) {
					this.loadData()
				}
		    },
			immediate: true,
		},
	},
    methods: {
        loadData () {
			if (!this.type) return
            if (this.showRxLeaderboard || this.showEventsLeaderboard) return
			this.loadTop5Leadersboards()
        },
		loadTop5Leadersboards () {
            this.loading = true
            const options = { leadershipLevel: this.type }
            QuilityAPI.loadTop5Leadersboards('monthly', options)
				.then((json) => {
					this.leaderboard_data = json.data
					this.agentPositions = json.meta.agent_positions
					this.startDate = json.meta.start_date
					this.endDate = json.meta.end_date
					this.loading = false
				})
				.catch((err) => {
					console.error(err)
					this.loading = false
				})

		}
    }
}
</script>
