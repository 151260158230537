<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="bulk_jobs_datatable">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}
                            <v-btn dark icon @click="refreshData">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items">
                <template v-slot:item.ID="{ item }">
                    <v-btn v-if="item.HasErrorLog" icon @click="downloadErrorLog(item.ID, item.JobName)" class=" nowrap">
                        <v-icon>fas fa-file-download</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QGenericDataTableMixin",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            the_data: [],
            data_loading: false,
            total_num: 0,
            options: {
                'sortBy': ['StartDate'],
                'sortDesc': [true]
            }
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['UserName'];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Name',
                    value: 'JobName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                },
                {
                    text: 'User',
                    value: 'UserName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Imported',
                    value: 'NumProcessed',
                    sortable: true,
                    filterable: true,
                    align: 'center',
                },
                {
                    text: 'Errors',
                    value: 'NumErrors',
                    sortable: true,
                    filterable: true,
                    align: 'center',
                },
                {
                    text: 'Date',
                    value: 'StartDate',
                    sortable: true,
                    filterable: true
                }, {
                    text: 'Error Log',
                    value: 'ID',
                    sortable: false,
                    filterable: false,
                    align: 'center',
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getBulkJobs(this.filters, { ...opt }).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
    },
    watch: {

    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
