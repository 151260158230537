import { VTabs } from 'vuetify/lib/components/VTabs'
import QEditPageBlocks from '@/CMS/editor/editPageBlocks.vue'
import TwoCol5050 from '@/CMS/views/ContentBlocks/TwoCol5050.vue';
import TwoCol2575 from '@/CMS/views/ContentBlocks/TwoCol2575.vue';
import TwoCol7525 from '@/CMS/views/ContentBlocks/TwoCol7525.vue';
import ThreeCol255025 from '@/CMS/views/ContentBlocks/ThreeCol255025.vue';
import ThreeCol333333 from '@/CMS/views/ContentBlocks/ThreeCol333333.vue';
import FourCol25252525 from '@/CMS/views/ContentBlocks/FourCol25252525.vue';

import Vue from 'vue';

/**
 * @file
 * Create new component here extending Vuetify component with new prop default value.
 */


// HQD-708: Show showArrows by default on mobile.  
Vue.component('v-tabs', Vue.extend({
    mixins: [VTabs],
    props: {
        showArrows: { default: true },
    },
}))

Vue.component('q-edit-page-blocks', QEditPageBlocks)
Vue.component('two-col-5050', TwoCol5050)
Vue.component('two-col-2575', TwoCol2575)
Vue.component('two-col-7525', TwoCol7525)
Vue.component('three-col-255025', ThreeCol255025)
Vue.component('three-col-333333', ThreeCol333333)
Vue.component('four-col-25252525', FourCol25252525)
