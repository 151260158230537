<template>
	<div class="q-dropzone__wrapper">
		<div class="q-dropzone" :class="{ 'q-dropzone--dragover': dropzoneHighlight }">
			<slot v-bind="{ dropzoneHighlight }">
				<div v-if="!value">{{ dropzoneMessage }}</div>
				<div v-else>
					<p>{{ value.name }}</p>
					<img class="q-dropzone__image-preview" v-if="isFileImage(value)" :src="getFileImageUrl(value)" />
					<p class="mt-4 mb-0">{{ dropzoneMessage }}</p>
				</div>
			</slot>

			<input ref="fileInputRef" type="file" class="q-dropzone__input" :tabindex="-1" aria-hidden="true"
				:accept="accept" :multiple="type !== 'single'" :disabled="disabled" @change="uploadFile"
				@dragenter="dropzoneHighlight = true" @dragleave="dropzoneHighlight = false"
			/>
		</div>
		<slot name="message"></slot>

		<v-file-input v-if="rules" class="q-dropzone__v-file-input pt-0" :value="value" :rules="[(v) => incorrectExtValidationResult, ...rules]" />
	</div>
</template>

<script>
import { MIMEType, originalMIMETypes } from './fileTypes.ts'

const validateFiles = (files, fileTypes) => files.filter((file) => {
	const fileName = file.name || file.url
	if (!fileName) { return false }
	if (file.url) { return true }

	const MIMETypes = Object.keys(MIMEType).filter((t) => fileTypes.includes(t))

	const isContainedMIMEType = MIMETypes.some((type) => {
		const extensions = MIMEType[type]
		const extension = fileName
			.substring(fileName.lastIndexOf(".") + 1)
			.toLowerCase()
		return extensions.includes(extension)
	})

	if (isContainedMIMEType) {
		// Do not validate MIMEType because there is too much to validate.
		return true
	}

	const extension = fileName
		.substring(fileName.lastIndexOf(".") + 1)
		.toLowerCase()

	const isCorrectExt = fileTypes.toLowerCase().includes(extension)

	return isCorrectExt
})

// Source code from https://github.com/epicmaxco/vuestic-ui/blob/95b222b68ab88270164a61700223ab8cd64520f3/packages/ui/src/components/va-file-upload/VaFileUpload.vue
export default {
	name: 'QDropzone',

	props: {
		value: {
			type: [Array, File],
		},
		fileTypes: {
			type: String,
			default: Object.keys(MIMEType).join(','),
		},
		type: {
			type: String,
			default: 'single',
		},
		dropzoneMessage: {
			type: String,
			default: 'Click here or drop a file to upload',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		rules: {
			type: Array,
			default: () => [],
		},
	},

	data () {
		return {
			dropzoneHighlight: false,
			incorrectExtValidationResult: true,
		}
	},

	computed: {
		valueComputed: {
			get() { return this.$props.value },
			set(value) { this.$emit('input', value) }
		},

		accept () {
			return this.fileTypes
				.split(',')
				.map((type) => {
					type = type.trim()
					if (type === '*') { return type }
					if (originalMIMETypes[type]) { return type }
					if (MIMEType[type]) { return MIMEType[type].map((ext) => `.${ext}`).join(',')}
					return type
				})
				.join(',')
		},
	},

	methods: {
		isFileImage(file) {
			return file && file['type'].split('/')[0] === 'image'
		},

		getFileImageUrl(file) {
			return URL.createObjectURL(file)
		},

		uploadFile(e) {
			this.incorrectExtValidationResult = true

			let files = e.target?.files || e.dataTransfer?.files

			if (!files) { return }

			files = Array.from(files)

			const validatedFiles = this.$props.fileTypes
				? validateFiles(files, this.$props.fileTypes)
				: files

			const value = this.$props.type === 'single'
				? validatedFiles[0]
				: [...this.$props.value, ...validatedFiles]

			if (files.length > 0 && validatedFiles.length === 0) {
				const types = this.$props.fileTypes
					.split(',')
					.map((type) => MIMEType[type.trim()])
					.flat()
				const allowedTypes = [...new Set(types)].join(', ')
				this.incorrectExtValidationResult = `Incorrect file type. Allowed types: ${allowedTypes}`
			}

			this.$emit('input', value)
		},
	},
}
</script>

<style lang="scss">
.q-dropzone {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
  color: #ccc;
  font-size: 1.2rem;
  text-align: center;
  outline: none;

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__image-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 300px;
    max-width: 300px;
  }

  &--dragover {
    border-color: #2196f3;
    color: #2196f3;
  }

  &__wrapper &__v-file-input {
    // We interested only in validation error message
	pointer-events: none;
    .v-input__slot,
    .v-input__prepend-outer {
      display: none;
    }
  }
}
</style>
