<template>
    <div>
        <slot name="meta">
            <div></div>
        </slot>
        <fml-form v-if="contentData.formSlug" :slug="contentData.formSlug" :no-template="true"></fml-form>
    </div>
</template>
<script>
import FmlForm from '@/FML/views/index.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "Form",
    components: {
        FmlForm
    },
    mounted: function() {

    },
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    formSlug: null,
                }
            }
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {

        }
    },
    computed: {

    },
    methods: {

    }
}
</script>