function createDiv(height) {
	var div = document.createElement('div');
	div.style.top = 0;
	div.style.right = 0;
	div.style.width = '5px';
	div.style.position = 'absolute';
	div.style.cursor = 'col-resize';
	div.style.userSelect = 'none';
	div.style.height = height + 'px';
	div.classList.add('column-resizer')
	return div;
}

function paddingDiff(col) {
	if (getStyleVal(col, 'box-sizing') == 'border-box') {
		return 0;
	}

	var padLeft = getStyleVal(col, 'padding-left');
	var padRight = getStyleVal(col, 'padding-right');
	return (parseInt(padLeft) + parseInt(padRight));
}

function getStyleVal(elm, css) {
	return (window.getComputedStyle(elm, null).getPropertyValue(css))
}

function clearResizeGrid(table) {
	table.querySelectorAll('.column-resizer').forEach((resizer) => {
		resizer.remove();
	});
}

export function resizableGrid(table, onResizeEnd) {
	clearResizeGrid(table);

	const row = table.getElementsByTagName('tr')[0]
	const cols = row.querySelectorAll('[data-resizable=true]') // row ? row.children : undefined;
	if (!cols) return;

	table.style.overflow = 'hidden';

	var tableHeight = table.offsetHeight;

	for (var i = 0; i < cols.length - 1; i++) {
		var div = createDiv(tableHeight);
		cols[i].appendChild(div);
		cols[i].style.position = 'relative';
		setListeners(div, i);
	}

	function setListeners(div, index) {
		var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

		div.addEventListener('mousedown', function (e) {
			curCol = e.target.parentElement;
			nxtCol = curCol.nextElementSibling;
			pageX = e.pageX;

			const padding = paddingDiff(curCol);

			curColWidth = curCol.offsetWidth - padding;
			if (nxtCol) {
				nxtColWidth = nxtCol.offsetWidth - padding;
			}
		});

		div.addEventListener('mouseover', function (e) {
			e.target.style.borderRight = '2px solid rgb(97, 97, 208)';
		})

		div.addEventListener('mouseout', function (e) {
			e.target.style.borderRight = '';
		})

		document.addEventListener('mousemove', function (e) {
			if (curCol) {
				var diffX = e.pageX - pageX;

				if (nxtCol) {
					nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';
				}

				curCol.style.width = (curColWidth + diffX) + 'px';
				onResizeEnd(index, curCol.style.width)

				if (nxtCol) {
					onResizeEnd(index + 1, nxtCol.style.width)
				}
			}
		});

		document.addEventListener('mouseup', function (e) {
			curCol = undefined;
			nxtCol = undefined;
			pageX = undefined;
			nxtColWidth = undefined;
			curColWidth = undefined
		});
	}
};