<template>
    <two-col-5050>
        <template v-slot:Title>
            <v-col cols="12">
                <v-text-field v-model="value.Title" label="Title"></v-text-field>
            </v-col>
        </template>
        <template v-slot:ColOne>
            <q-edit-page-blocks v-model="value.ColOne"></q-edit-page-blocks>
        </template>
        <template v-slot:ColTwo>
            <q-edit-page-blocks v-model="value.ColTwo"></q-edit-page-blocks>
        </template>
    </two-col-5050>
</template>
<script>
export default {
    props: {
        value: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    created: function() {
        this.reset();
    },
    methods: {
        reset: function() {
            if (typeof this.value.Title == 'undefined') {
                this.$set(this.value, 'Title', "")
            }

            if (typeof this.value.ColOne == 'undefined') {
                this.$set(this.value, 'ColOne', this.defaultCmsColumnSubBlocks())
            }
            if (typeof this.value.ColTwo == 'undefined') {
                this.$set(this.value, 'ColTwo', this.defaultCmsColumnSubBlocks())
            }
            this.$emit('input', this.value)
        },
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
