<template>
    <div v-if="isLoading || !src" class="d-flex flex-column align-center justify-center py-12 px-8">
        <v-progress-linear color="primary" class="mr-4 mt-6" :value="progress.value / progress.total * 100" />
        <br/>
        Loading PDF preview...
    </div>
    <div class="q-pdf-viewer" v-else>
        <div 
            v-for="n in numberOfPages"
            :key="n"
            class="q-pdf-viewer__page-wrapper"
        >
            <div class="q-pdf-viewer__page">
                <VuePdf :page="n" :src="src" />        
            </div>
            <div v-if="numberOfPages > 1" class="q-pdf-viewer__page-info">
                {{ n }} / {{ numberOfPages }}
            </div>       
        </div>
    </div>
</template>

<script>
import VuePdf from 'vue-pdf'

// Make sure to v-if this component. Idk why, but some time pdfjs doesn't make a request.
export default {
    components: { VuePdf },

    props: {
        value: { type: String, required: true }
    },

    data() {
        return {
            isLoading: false,
            src: null,
            numberOfPages: 0,
            progress: {
                value: 0,
                total: 0
            }
        }
    },

    methods: {
        loadPdf(src) {
            /**
             * Use createLoadingTask to load all pages of the PDF
             * @see https://github.com/FranckFreiburger/vue-pdf#example---display-multiple-pages-of-the-same-pdf-document
             */
            this.isLoading = true;
            this.progress.value = 0
            this.src = VuePdf.createLoadingTask(src, {
                onProgress: ({ loaded, total }) => {
                    this.progress.value = loaded
                    this.progress.total = total
                }
            });
            this.src.promise.then((pdf) => {
                this.isLoading = false;
                this.numberOfPages = pdf.numPages;
            })
        },
    },

    watch: {
        value: {
            handler(newValue) {
                if (this.src) {
                    this.src = null
                }

                if (!newValue) {
                    return
                }

                setTimeout(() => { this.loadPdf(newValue); })
                
            },
            immediate: true
        }
    },
}
</script>

<style lang="scss" scoped>
  .q-pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f3f4;

    &__page {
        &-wrapper {
            padding: 16px;
            width: 100%;
        }

        width: 100%;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }

    &__page-info {
        padding: 8px 24px;
        display: flex;
        justify-content: flex-end;
    }
  }
</style>
