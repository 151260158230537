<template>
    <v-container fluid class="my-0 pa-0">
        <q-news-submenu></q-news-submenu>
        <v-container fluid class="px-5 site-width">
            <slot></slot>
        </v-container>
    </v-container>
</template>

<script>
import QNewsSubmenu from '@/components/navigation/Submenus/QNewsSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QNewsSubmenu
    },
}
</script>
