export default {
    getContentRoles()
    {
        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getContentRoles");
            return this.getRequest(
                '/api/private/lightspeed/course_roles', //the end point for this resource.
                {}, //filters, pagination and what not handles by the datatable
                "getContentRoles" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    }
}