<template>
    <QDynamicSubmenu
        :base-url="baseUrl"
        :default-title="title"
        :default-buttons="buttons"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QProductLibraryAgentSubmenu",
    data() {
        return {
            title: "Carriers & Products",
            baseUrl: '/admin',
        }
    },
    computed: {
        buttons() {
            const buttons = [{
                label: "Carrier Resources",
                to: "/page/available-carriers",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Product Concepts",
                to: "/training/product-concepts",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Infinite Banking Concepts",
                to: "/page/ibc",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "E-Apps",
                to: "/carriers/carriers-e-app-platforms",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "QuilityRx",
                to: "/qms/quility-rx",
                icon: "fas fa-arrow-alt-circle-right",
            }]

            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff', 'AgencyOwner'])) {
                buttons.push({
                    label: "Master Commission Schedule",
                    href: "https://quility-my.sharepoint.com/:x:/p/laurren_herberg/EeSjfbhA45NKiGylmdfs9dQBLiIv1VAJBnuniJXLfrQZ-A?e=wr5BBh",
                    icon: "fas fa-external-link-alt",
                });
            }

            return buttons;
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
