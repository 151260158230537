<template>
    <v-container fluid class="grey lighten-5 my-0 pa-0">
        <v-banner>
            <h4>Support</h4>
            <template v-slot:actions>
                <v-btn to="/support" target="_tab">Create Ticket</v-btn>
            </template>
        </v-banner>
        <h2>{{ contentData.title }}</h2>
        <v-container fluid class="px-5 site-width">
            <slot></slot>
        </v-container>
    </v-container>
</template>

<script>
export default {
    props: ['contentData'],
    data: () => ({}),
    components: {},
}
</script>
