import { Model } from "@vuex-orm/core";

export default class Lead extends Model {
	// This is the name used as module name of the Vuex Store.
	static entity = "lead";
	static primaryKey = "LeadCode";

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/leads',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: ['SourceofReferral', 'LeadType', 'LeadStatus', 'State', 'County', 'LeadLevel'],
            availableFilters: null,
            currentLead: {}
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            LeadCode: this.uid(),
            LeadType: this.attr(''),
            LeadLevel: this.attr(''),
            LeadSubSource: this.attr(''),
            LeadSource: this.attr(''),
            LeadStatus: this.attr(''),
            FirstName: this.attr(''),
            LastName: this.attr(''),
            Street: this.attr(''),
            City: this.attr(''),
            State: this.attr(''),
            Zip: this.attr(''),
            Phone: this.attr(''),
            County: this.attr(''),
            AssignDate: this.attr(''),
            Sex: this.attr(''),
            Birthday: this.attr(''),
            Age: this.attr(''),
            Comments: this.attr(''),
            PDFURL: this.attr(''),
            LoanAmount: this.attr(''),
            PurchaseAmount: this.attr(''),
            ExtPartnerID: this.attr(''),
            SourceofReferral: this.attr(''),
			LeadSearch: this.attr(""),
			Email: this.attr(""),
        }
    }
}
