<!--Full width text column -->
<template>
    <v-row min-width="100%">
        <slot name="meta">
        </slot>
        <v-col cols="12" :style="'height:' + contentData.Height">
        </v-col>
    </v-row>
</template>
<script>
//use this component like so...
//<text pulls html from the editor">

export default {
    name: "Spacer",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    Height: "",
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {

    },
    methods: {

    }
}

</script>
