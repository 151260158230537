<template>
    <div class="q-glossary__letters-list-wrapper">
        <div class="q-glossary__letters-list" :class="{ 'q-glossary__letters-list--fixed': isFixed }" ref="letters" :style="computedStyle">
            <div
                v-for="(letter, index) in visibleLetters" 
                :key="index"
                class="q-glossary__letter"
                :class="{
                    'q-glossary__letter--disabled': !letters.includes(letter)
                }"
                @click="goToLetter(letter)"
            >
                {{ letter }}
            </div>
        </div>
        <div v-if="isFixed" class="q-glossary__letters-list--fake-placeholder" />
    </div>
</template>

<script>
    const querySelectorAll = (selector) => [...document.querySelectorAll(selector)]

    const getVisibleBlock = (arr) => arr.find((el) => el.offsetHeight > 0 && el.offsetWidth > 0)

    export default {
        props: {
            value: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                parentRect: {
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0
                },
                headerHeight: 56 // Size of header - simply offset so header do not overflow letters.
            }
        },

        computed: {
            letters() {
                return this.value
                    .map(item => this.getFirstLetter(item.slug))
                    .filter((item, index, array) => item !== '' && array.indexOf(item) === index)
                    .sort((a, b) => a.localeCompare(b))
            },
            visibleLetters() {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    return this.letters
                }

                const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

                return alphabet
            },
            computedStyle() {
                if (!this.isFixed) { return {} }

                return {
                    top: this.headerHeight + 'px',
                    left: this.parentRect.left + 'px',
                    width: this.parentRect.width + 'px'
                }
            },
            isFixed() {
                return this.parentRect.top - this.headerHeight <= 0
            }
        },

        methods: {
            getFirstLetter(word = '') {
                return word.charAt(0).toUpperCase()
            },
            goToLetter(letter) {
                const el = getVisibleBlock(querySelectorAll(`#q-glossary-letter-${letter}`))

                if (!el) { return }

                const { top } = el.getBoundingClientRect()

                const offsetTopToWindow = window.scrollY + top

                window.scrollTo({
                    top: offsetTopToWindow - this.headerHeight - this.$refs.letters.offsetHeight,
                    behavior: 'smooth'
                })
            },
            getParentRect() {
                this.parentRect = this.$refs.letters.parentElement.getBoundingClientRect()
            },
            handleScroll() {
                this.getParentRect()
            },
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll)
            this.parentResizeObserver = new ResizeObserver(this.getParentRect)
            this.parentResizeObserver.observe(this.$refs.letters.parentElement)
            this.handleScroll()
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll)
            this.parentResizeObserver.disconnect()
        },
    }
</script>

<style lang="scss" scoped>
$letters-list-height: 48px;

.q-glossary {
    position: relative;
    &__letters-list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;

        &--fixed {
            position: fixed;
            top: 70px;
            z-index: 1;
            background: white;
            border-bottom: 1px solid #e6e6e6;
        }

        &--fake-placeholder {
            height: $letters-list-height;
            width: 100%;
        }
    }

    &__letter {
        $letter-size: 32px;

        width: $letter-size;
        height: $letter-size;

        border-radius: 50%;

        color: white;
        background-color: #005851;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 4px;

        cursor: pointer;
        user-select: none;

        &:last-child {
            margin-right: 0;
        }

        &--disabled {
            opacity: 0.5;
            cursor: default;
        }
    }
}
</style>