<!--Full width text column -->
<template>
    <v-row min-width="100%" >
        <slot name="meta">
        </slot>
        <div cols="12" :id="contentData.AnchorID" class="pa-0 ma-0" style="height:0;">
        </div>
    </v-row>
</template>
<script>
//use this component like so...
//<text pulls html from the editor">

export default {
    name: "Anchor",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    AnchorID: "",
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {

    },
    methods: {

    }
}

</script>
