import { Model } from '@vuex-orm/core'
import lead_level_qty_multiplier from '@/store/LeadOrderQtyMultiplier'

export default class LeadQuantity extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'lead_quantity'
    static primaryKey = 'ID'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/lead_quantity',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            currentLeadOrder: {}
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            ID: this.uid(0),
            CreateDate: this.attr(''),
            LastChangeDate: this.attr(''),
            LastChangeBy: this.attr(''),
            LeadOrderID: this.number(0),
            LeadOrderLineID: this.number(0),
            LeadAllocationID: this.number(0),
            Status: this.attr(''),
            LeadLevel: this.attr(''),
            Quantity: this.number(0),
        }
    }

}
