<!--
this component is used for all Resources pages
-->
<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        standard-submenu
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QResourcesSubmenu",
    data() {
        return {
            title: "Resources",
            titlelink: [{
                label: null,
                link: null,
            }],
            buttons: [{
                    label: "Core Values",
                    to: "/page/symmetry-core-values",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Handbook",
                    to: "/page/handbooks",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Levels of Leadership",
                    to: "/levelsofleadership",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Corporate Leadership",
                    to: "/corporate_staff",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Thrive",
                    to: "/page/thrive",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Con•nect",
                    to: "/page/connect",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Agent Spotlight",
                    href: "https://form.asana.com/?k=MsYWKxovp-LV32tnh85dvQ&d=271178874148697",
                    icon: "fas fa-external-link-alt"
                },

            ],
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
