export function findDelimiter(strData) {
	const delimiters = [",", ";", "|"];
	const delimiterCounts = delimiters.map((delimiter) => {
		const delimiterCount = strData.split(delimiter).length;
		return { delimiter, delimiterCount };
	})

	const sortedDelimiterCounts = delimiterCounts.sort((a, b) => {
		return b.delimiterCount - a.delimiterCount;
	})

	return sortedDelimiterCounts[0].delimiter;
}

/** Originally taken from here: https://stackoverflow.com/questions/1293147/how-to-parse-csv-data */
export function CSVToArray(strData, strDelimiter) {
	strDelimiter = strDelimiter || ",";

	var objPattern = new RegExp(
		"(\\" +
			strDelimiter +
			"|\\r?\\n|\\r|^)" +
			'(?:"([^"]*(?:""[^"]*)*)"|' +
			'([^"\\' +
			strDelimiter +
			"\\r\\n]*))",
		"gi"
	);

	var arrData = [[]];

	var arrMatches = null;

	while ((arrMatches = objPattern.exec(strData))) {
		var strMatchedDelimiter = arrMatches[1];

		if (
			strMatchedDelimiter.length &&
			strMatchedDelimiter !== strDelimiter
		) {
			arrData.push([]);
		}

		var strMatchedValue;

		if (arrMatches[2]) {
			strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
		} else {
			strMatchedValue = arrMatches[3];
		}

		arrData[arrData.length - 1].push(strMatchedValue);
	}

	return arrData;
}
