<template>
    <v-row class="mx-5 my-0" cols="12" width="100%">
        <v-col cols="12">
            <v-expansion-panels :value="panelModelValue" multiple :readonly="alwaysOpen">
                <v-expansion-panel
                    v-for="(item,index) in panels"
                    :key="`${index}-${item.id}`"
                >
                    <v-expansion-panel-header :hide-actions="alwaysOpen">
                        <slot name="header" v-bind="{ item, index }">
                            {{ item.header }}
                        </slot>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <slot name="text" v-bind="{ item, index }">
                            <div v-html="item.text"/>
                        </slot>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <div v-if="$slots.footer" class="mt-2">
                <slot name="footer" />
            </div>
        </v-col>
    </v-row>
</template>

<script>
const panelsKey = 'AccordionPanels'

export default {
    name: "Accordion",

    props: {
        contentData: { type: Object, required: true },
        alwaysOpen: { type: Boolean, default: false, }
    },

    computed: {
        panelModelValue: {
            get() {
                return this.alwaysOpen ? [...(this.panels).keys()].map((k, i) => i) : undefined
            },
            set() { /* ... */ }
        },

        panels() {
            return this.contentData[panelsKey]
        }
    },
}
</script>
