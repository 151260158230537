import { Model } from '@vuex-orm/core'

export default class NeedsAnalysisLead extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'needs_analysis_lead'
    static primaryKey = 'LeadID'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/my/leads',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: ['LeadType', 'LeadLevel', 'County'],
            availableFilters: null,
            currentLead: {}
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            //LeadID: this.attr(null),
            LeadID: this.uid(),
            CODE: this.attr(''),
            Contact: this.attr(''),
            LeadType: this.attr(''),
            LeadLevel: this.attr(''),
            Street1: this.attr(''),
            City: this.attr(''),
            County: this.attr(''),
            State: this.attr(''),
            Zip: this.attr(''),
            Phone: this.attr(''),
            CellPhone: this.attr(''),
            HomePhone: this.attr(''),
            DOB: this.attr('01/01/2020'),
            Email: this.attr(''),
            Gender: this.attr(''),
            Occupation: this.attr(''),
            Diabetes: this.attr(false),
            HeartProblems: this.attr(false),
            Height: this.attr(59),
            PDFFile: this.attr(''),
            AgentID: this.attr(''),
            Funds: this.attr(0),
            RetAge: this.attr(0),
            YearsWith: this.attr(0),
            Age: this.attr(0),

            Disposition: this.attr('?'),

            Spouse: this.attr({
                SpouseID: null,
                Name: '',
                Phone: '',
                Email: '',
                Address: '',
                City: '',
                State: '',
                Zip: '',
                Age: 0,
                DOB: '01/01/2020',
                Occupation: '',
                YearsWithEmployer: 0,
                RetirementAge: 60,
            }),
            RealEstate: this.attr({
                RealEstateID: null,
                PrimaryHomeValue: null,
                PrimaryHomeLender: null,
                PrimaryHomeRate: null,
                PrimaryHomeTerm: null,
                PrimaryHomeLoanAmount: null,
                PrimaryHomeLoanBalance: null,
                PrimaryHomePayment: null,
                PrimaryHomeAccelerated: null,

                AdditionalProperty1Value: null,
                AdditionalProperty1Lender: null,
                AdditionalProperty1Rate: null,
                AdditionalProperty1Term: null,
                AdditionalProperty1LoanAmount: null,
                AdditionalProperty1LoanBalance: null,
                AdditionalProperty1Payment: null,
                AdditionalProperty1Accelerated: null,

                AdditionalProperty2Value: null,
                AdditionalProperty2Lender: null,
                AdditionalProperty2Rate: null,
                AdditionalProperty2Term: null,
                AdditionalProperty2LoanAmount: null,
                AdditionalProperty2LoanBalance: null,
                AdditionalProperty2Payment: null,
                AdditionalProperty2Accelerated: null,

                AdditionalDebt: null,
                FutureFunds: null,
                FinalExpense: null,
            }),
            Income: this.attr({
                IncomeID: null,
                AnnualIncome: null,
                Pension: null,
                InvestmentIncome: null,
                SpouseIncome: null,
                SpousePension: null,
                ReplacementYears: null
            }),
            LifeInsurance: this.attr({
                LifeInsuranceID: null,
                CashValue: null,
                SpouseCashValue: null,

                PrimaryInsuranceType: null,
                PrimaryInsuranceCarrier: null,
                PrimaryInsuranceValue: null,
                PrimaryInsurancePremium: null,
                PrimaryExpiresInYears: null,
                PrimaryInsurancePurpose: null,

                OtherInsuranceType: null,
                OtherInsuranceCarrier: null,
                OtherInsuranceValue: null,
                OtherInsurancePremium: null,
                OtherExpiresInYears: null,
                OtherInsurancePurpose: null,

                SpousePrimaryInsuranceType: null,
                SpousePrimaryInsuranceCarrier: null,
                SpousePrimaryInsuranceValue: null,
                SpousePrimaryInsurancePremium: null,
                SpousePrimaryExpiresInYears: null,
                SpousePrimaryInsurancePurpose: null,

                SpouseOtherInsuranceType: null,
                SpouseOtherInsuranceCarrier: null,
                SpouseOtherInsuranceValue: null,
                SpouseOtherInsurancePremium: null,
                SpouseOtherExpiresInYears: null,
                SpouseOtherInsurancePurpose: null,

                EmployerLifeInsurance: null,
                EmployerLifeExpiresInYears: null,
                SpouseEmployerLifeInsurance: null,
                SpouseEmployerLifeExpiresInYears: null,

            }),
            Assets: this.attr([]),
            Debts: this.attr([])
        }
    }

    get name_address() {
        return `${this.Contact}, ${this.Street1}, ${this.City}`
    }

    get full_address() {
        return `${this.Street1} ${this.City}, ${this.State}`;
    }

    get full_policy() {
        return `${this.Policy} ${this.PolicyType}, ${this.PolicySubType}`;
    }
}
