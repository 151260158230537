import AvailableLead from '@/store/Models/AvailableLead'

export default {
    getAvailableLeads(filters, options) {
        AvailableLead.commit((state) => {
            state.loading = true
        })
        //this.config().then(function(cfg) {
        var params = {
            ...options,
            ...filters
        }
        this.getRequest('/api/private/leads/available', params).then(function(json) {
            AvailableLead.create({ data: json.data })
            AvailableLead.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
        })
    },

    getAvailableLeadMap() {
        //this.config().then(function(cfg) {
        var params = {}
        return new Promise((resolve, reject) => {
            this.getRequest('/api/private/leads/available/map', params).then(function(json) {
                resolve(json);
            })
        })
    },

    getAvailableLead(lead_id, attributes) {
        AvailableLead.commit((state) => {
            state.loading = true
            state.currentLead = null
        })
        var params = {
            attr: attributes.join(",")
        }
        this.getRequest('/api/private/leads/available/' + lead_id, params).then(function(json) {
            AvailableLead.commit((state) => {
                state.loading = false
                state.currentAgent = json.data
            })
        })
    }


}
