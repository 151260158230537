<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <!--<q-my-leads-submenu></q-my-leads-submenu>//-->
        <v-progress-linear color="primary" indeterminate v-if="loading" style="position:fixed;z-index:1;"></v-progress-linear>
        <v-row class="mx-5">
            <v-col cols=4>
                <h2>Media Library
                </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" sm="3" v-if="token && !isDemoMode">
                <q-division-select label="Division*" v-model="Division" :with-all="true"></q-division-select>
            </v-col>
            <v-col cols="3" sm="3" class="pr-7 mb-0">
                <v-text-field v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="getImages"></v-text-field>
            </v-col>
            <v-col cols="12" v-if="token && !isDemoMode">
                <vue-dropzone ref="myVueDropzone" v-on:vdropzone-success="removeFile" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-sending="sendingAppendDivision"></vue-dropzone>
            </v-col>
        </v-row>
        <v-row class="mx-5">
            <v-col cols=12 v-if="recent_images.length > 0">
                <h4>Recent Images</h4>
                <v-row>
                    <v-col cols="6" sm="4" md="2" v-for="(image, key) in recent_images" :key="key">
                        <v-hover v-slot:default="{ hover }">
                            <v-card class="pa-2" :elevation="hover ? 12 : 1">
                                <v-img :aspect-ratio="1" max-height="150" max-width="250" width="100%" height="100%" :src="image.file.url" @click="useImage(image.file.url)" />
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
                <hr>
            </v-col>
            <v-col cols=12 v-if="images.data.length > 0">
                <h4>Image Library
                    <v-btn icon @click="reloadImages()" fab small>
                        <v-icon small>fas fa-sync-alt</v-icon>
                    </v-btn>
                </h4>
                <v-progress-linear color="primary" indeterminate v-if="loading && images.data.length == 0"></v-progress-linear>
                <v-row>
                    <v-col cols="6" sm="4" md="2" v-for="(image, key) in images.data" :key="key">
                        <ImageSquareCard :src="image.url" :title="image.title" @click="useImage(image.url)">
                            <template #actions>
                                <v-btn @click="deleteImage(image.id)" fab small color="red">
                                    <v-icon small>fas fa-trash</v-icon>
                                </v-btn>
                                <v-btn @click.stop="{}" v-clipboard:copy="getImageUrl(image)" v-clipboard:success="handleCopyStatus" fab small>
                                    <v-icon small>fas fa-copy</v-icon>
                                </v-btn>
                                <v-btn :disabled="isDemoMode" @click="editImage(image)" fab small>
                                    <v-icon small>fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                        </ImageSquareCard>
                    </v-col>
                </v-row>
                <div class="d-flex align-center justify-center" style="flex-wrap: wrap">
                    <div>
                        <v-pagination v-if="images.meta.total > 15" v-model="page" :length="images.meta.last_page" :total-visible="7" @next="getImages" @input="getImages" class="mt-6 mb-6" style="width: auto;" />
                    </div>
                    <div>
                        <v-combobox :value="page" :items="new Array(images.meta.last_page).fill(0).map((_, i) => i + 1)" :rules="[(value) => (value < images.meta.last_page && value > 0) || 'Incorrect page']" style="width: 80px;" @input="page = Number($event); getImages()" />
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-snackbar v-model="notice.visible" top :color="notice.color" multi-line :timeout="6000">
            {{ notice.text }}
            <v-btn text @click="notice.visible = false">
                Close
            </v-btn>
        </v-snackbar>
        <v-dialog v-model="showEditImage" width="50%">
            <v-card>
                <v-card-title>Replace Image</v-card-title>
                <v-card-text v-if="showEditImage">
                    <p>Note: We are now using an image CDN. When replacing the image, it may take a few minutes for the CDN cache to update.</p>
                    <vue-dropzone ref="myVueDropzoneUpdate" v-on:vdropzone-success="updateImage" id="dropzone" :options="dropzoneOptionsUpdate" v-on:vdropzone-sending="sendingAppendUpdateInfo"></vue-dropzone>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-spacer></v-spacer>
                    <v-btn @click="showEditImage = false" class="mb-4">Close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { getInstance } from "@/auth/index";
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDivisionSelect from '@/components/utils/QDivisionSelect.vue'
import ImageSquareCard from './components/ImageSquareCard.vue'

export default {
    data() {
        return {
            loading: true,
            currentImage: {
                id: null
            },
            images: {
                data: [],
                meta: {
                    last_page: 1
                }
            },
            dropzoneOptions: {
                url: '/api/private/images',
                headers: {},
                addRemoveLinks: true,
                acceptedFiles: 'image/*',
                'paramName': 'image',
                thumbnailWidth: 100,
                thumbnailHeight: 100,
            },
            dropzoneOptionsUpdate: {
                url: '/api/private/images',
                headers: {},
                addRemoveLinks: true,
                acceptedFiles: 'image/*',
                'paramName': 'image',
                thumbnailWidth: 100,
                thumbnailHeight: 100,
                dictDefaultMessage: "Drop image file here to replace existing image"
            },
            token: null,
            recent_images: [],
            page: 1,
            Division: null,
            search: '',
            showEditImage: false,
        }
    },
    mounted: function() {
        var g = this
        this.config().then(function(token) {
            g.token = token
            g.dropzoneOptions.headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
            g.dropzoneOptionsUpdate.headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
            g.getImages()
        })
        this.Division = 'All'; //this.divisions[0]
        //set the default division to the first in the array. 

    },
    computed: {
        notice: function() {
            return {}
        }
    },
    methods: {
        getImageUrl: function(image) {
            if (image.url.indexOf('http') !== 0)
                return `${process.env.VUE_APP_BASE_URL}${image.url}`
            return image.url
        },
        config: function() {
            return new Promise((resolve, reject) => {
                getInstance().getTokenSilently().then(function(token) {
                    resolve(token);
                });
            })
        },
        removeFile: function(file) {
            this.recent_images.push(JSON.parse(file.xhr.response));
            this.$refs.myVueDropzone.removeFile(file);
            this.page = 1;
        },
        updateImage: function(file) {
            this.$refs.myVueDropzoneUpdate.removeFile(file);
            this.showEditImage = false
            //trigger reload of updated image
            this.currentImage.url += "?rand=" + Math.random()
        },
        reloadImages: function() {
            if (this.token == null)
                return
            this.images.data = []
            this.page = 1
            this.getImages()
        },
        useImage: function(file) {
            this.$emit('usefile', file);
        },
        deleteImage: function(id) {
            var g = this
            g.loading = true
            QuilityAPI.deleteImage(id)
                .then(result => {
                    if (result.success) {
                        g.images.data = g.images.data.filter(image => image.id !== id)
                        g.loading = false
                    } else if (result?.error?.isDemoMode) {
						g.showError(result.message)
					}
                })
        },
        getImages() {
            if (this.page > this.images.meta.last_page || this.page < 1) { return }

            this.loading = true

            QuilityAPI.getImages(this.page, this.Division, this.search).then((json) => {
                this.loading = false
                this.images = json
            })
        },
        sendingAppendDivision: function(file, xhr, formData) {
            formData.append('division', this.Division)
        },
        sendingAppendUpdateInfo: function(file, xhr, formData) {
            formData.append('division', this.Division)
            formData.append('id', this.currentImage.id)
            formData.append('file', this.currentImage.file)
        },
        maybeSearch: function(event) {
            if (event.keyCode === 13) {
                this.getImages();
            }
        },
        editImage: function(doc) {
            this.$set(this, 'currentImage', doc);
            this.showEditImage = true;
        },
        handleCopyStatus(status) {
            this.showInfo("Image URL copied to clipboard!")
        },
    },
    watch: {
        'Division': function(newV) {
            this.$nextTick(this.reloadImages);
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
        QDivisionSelect,
        ImageSquareCard
    }
}
</script>